import { ERolesAndPrivileges } from 'components/HigherOrderComponent/Privileged/privileges';

export interface PageDeet {
  path: string;
  routePath: string;
  title: string;
  backlinks: AppPageName[];
  roles: string[];
  privileges: ERolesAndPrivileges[];
}

export type AppPageName =
  | 'DASHBOARD'
  | 'ADMIN_DASHBOARD'
  | 'TEAM_DASHBOARD'
  | 'CASHAPPS'
  | 'COLLECTOR_DASHBOARD'
  | 'COLLABORATOR_DASHBOARD'
  | 'ALL_CUSTOMERS'
  | 'PAYMENT_LIST'
  | 'PAYMENT_DETAIL'
  | 'AR_AGING'
  | 'CUSTOMER_DETAILS'
  | 'INVOICE_LIST'
  | 'INVOICE_DETAILS'
  | 'INBOX'
  | 'INBOX_EMAIL'
  | 'INBOX_ACTIVITIES'
  | 'COLLECTION_FOLLOWUPS'
  | 'COLLECTION_FOLLOWUP'
  | 'COLLECTION_FOLLOWUP_NEW'
  | 'COLLECTION_STRATEGIES'
  | 'COLLECTION_STRATEGY'
  | 'COLLECTION_STRATEGY_NEW'
  | 'CURRENCY'
  | 'SETTINGS'
  | 'REPORTS'
  | 'ACCOUNT_DETAILS'
  | 'ORG_DETAILS'
  | 'USER_MANAGEMENT'
  | 'ROLES_AND_PERMISSIONS'
  | 'USER_CATEGORY'
  | 'CONTACT_TYPES'
  | 'EMAIL_TEMPLATES'
  | 'EMAIL_TEMPLATE'
  | 'EMAIL_TEMPLATE_NEW'
  | 'STRATEGY_ASSIGNMENT'
  | 'OWNER_ASSIGNMENT'
  | 'RECEIVABLES'
  | 'COLLECTION_STATUS'
  | 'INTEGRATIONS'
  | 'INTEGRATION'
  | 'ERROR'
  | 'SLACK_ACCOUNT'
  | 'AR_EMAIL_VERIFICATION'
  | 'LOGIN'
  | 'FORGOT_PASSWORD'
  | 'RESET_PASSWORD'
  | 'ACTIVATE_ACCOUNT'
  | 'COLLECTION_PLANNING'
  | 'CUSTOMER_PORTAL_CONFIG'
  | 'COLLECTORS_MANAGEMENT'
  | 'DISPUTE_TYPE'
  | 'COLLECTION_ACTIVITIES'
  | 'ADVANCED_REPORTS'
  | 'ADVANCED_REPORT'
  | 'DOCUMENT_MANAGEMENT'
  | 'WORKFLOW_AUTOMATIONS'
  | 'CUSTOM_WORKFLOW'
  | 'CUSTOM_WORKFLOW_NEW'
  | 'DSO_CONFIG'
  | 'PREFERENCES'
  | 'UA_APPROVAL'
  | 'APPROVALS'
  | 'AUTOMATIONS';

const PrivilegeForPage: Record<AppPageName, ERolesAndPrivileges[]> = Object.freeze({
  DASHBOARD: [
    ERolesAndPrivileges.ADMIN_DASHBOARD_SNAPSHOT,
    ERolesAndPrivileges.ADMIN_DASHBOARD_ANALYSIS,
    ERolesAndPrivileges.TEAM_DASHBOARD_ANALYSIS,
    ERolesAndPrivileges.TEAM_DASHBOARD_SNAPSHOT,
    ERolesAndPrivileges.VIEW_COLLECTOR_DASHBOARD,
    ERolesAndPrivileges.VIEW_COLLABORATOR_DASHBOARD,
  ],
  ADMIN_DASHBOARD: [
    ERolesAndPrivileges.ADMIN_DASHBOARD_SNAPSHOT,
    ERolesAndPrivileges.ADMIN_DASHBOARD_ANALYSIS,
    ERolesAndPrivileges.TEAM_DASHBOARD_ANALYSIS,
    ERolesAndPrivileges.TEAM_DASHBOARD_SNAPSHOT,
  ],
  CASHAPPS: [ERolesAndPrivileges.DEFAULT_CASH_APP_PRIVILEGE],
  COLLECTOR_DASHBOARD: [ERolesAndPrivileges.VIEW_COLLECTOR_DASHBOARD],
  COLLABORATOR_DASHBOARD: [ERolesAndPrivileges.VIEW_COLLABORATOR_DASHBOARD],
  TEAM_DASHBOARD: [
    ERolesAndPrivileges.TEAM_DASHBOARD_ANALYSIS,
    ERolesAndPrivileges.TEAM_DASHBOARD_SNAPSHOT,
    ERolesAndPrivileges.ADMIN_DASHBOARD_SNAPSHOT,
    ERolesAndPrivileges.ADMIN_DASHBOARD_ANALYSIS,
  ],
  CUSTOMER_PORTAL_CONFIG: [ERolesAndPrivileges.PORTAL_SETTINGS],

  AR_AGING: [
    ERolesAndPrivileges.AGING_NUMBERS,
    ERolesAndPrivileges.BASIC_FILTER_FIELDS_AR_AGING,
    ERolesAndPrivileges.ADVANCED_FILTER_FIELDS_AR_AGING,
    ERolesAndPrivileges.CUSTOMER_LIST_PAGE,
    ERolesAndPrivileges.SET_COLLECTION_OWNER_AR_AGING,
    ERolesAndPrivileges.SET_COLLECTION_STRATEGY_AR_AGING,
  ],
  ALL_CUSTOMERS: [ERolesAndPrivileges.CUSTOMER_LIST],
  PAYMENT_LIST: [ERolesAndPrivileges.PAYMENT_REPORT],
  PAYMENT_DETAIL: [ERolesAndPrivileges.PAYMENT_REPORT],
  REPORTS: [ERolesAndPrivileges.COMPANY_SETTINGS],
  INVOICE_LIST: [
    ERolesAndPrivileges.VIEW_OPEN_INVOICES_INVOICE_LIST,
    ERolesAndPrivileges.ACT_OPEN_INVOICES_INVOICE_LIST,
    ERolesAndPrivileges.VIEW_CLOSED_INVOICES_INVOICE_LIST,
    ERolesAndPrivileges.ACT_CLOSED_INVOICES_INVOICE_LIST,
    ERolesAndPrivileges.BASIC_FILTER_FIELDS_INVOICE_LIST,
    ERolesAndPrivileges.ADVANCED_FILTER_FIELDS_INVOICE_LIST,
    ERolesAndPrivileges.SET_INVOICE_LIST_FLAG_INVOICE,
  ],
  CUSTOMER_DETAILS: [
    ERolesAndPrivileges.BASIC_CUSTOMER_INFORMATION,
    ERolesAndPrivileges.SET_COLLECTION_STATUS,
    ERolesAndPrivileges.SET_COLLECTION_OWNER_CUSTOMER_DETAIL,
    ERolesAndPrivileges.SET_COLLECTION_STRATEGY_CUSTOMER_DETAIL,
    ERolesAndPrivileges.VIEW_CUSTOMER_ACTIVITIES,
    ERolesAndPrivileges.VIEW_STATEMENT_INFORMATION,
    ERolesAndPrivileges.VIEW_OPEN_INVOICES_CUSTOMER_DETAIL,
    ERolesAndPrivileges.ACT_OPEN_INVOICES_CUSTOMER_DETAIL,
    ERolesAndPrivileges.VIEW_CLOSED_INVOICES_CUSTOMER_DETAIL,
    ERolesAndPrivileges.ACT_CLOSED_INVOICES_CUSTOMER_DETAIL,
    ERolesAndPrivileges.SET_CUSTOMER_DETAIL_FLAG_INVOICE,
  ],
  INVOICE_DETAILS: [
    ERolesAndPrivileges.BASIC_INVOICE_INFORMATION,
    ERolesAndPrivileges.VIEW_INVOICE_LINE_ITEMS,
    ERolesAndPrivileges.VIEW_INVOICE_ACTIVITIES,
    ERolesAndPrivileges.SET_INVOICE_DETAIL_FLAG_INVOICE,
  ],

  INBOX: [ERolesAndPrivileges.INBOX],
  INBOX_EMAIL: [ERolesAndPrivileges.INBOX],
  INBOX_ACTIVITIES: [ERolesAndPrivileges.INBOX],

  COLLECTION_FOLLOWUPS: [ERolesAndPrivileges.CREATE_MANAGE_COLLECTION_FOLLOWUP],
  COLLECTION_FOLLOWUP_NEW: [ERolesAndPrivileges.CREATE_MANAGE_COLLECTION_FOLLOWUP],
  COLLECTION_FOLLOWUP: [ERolesAndPrivileges.CREATE_MANAGE_COLLECTION_FOLLOWUP],

  COLLECTION_STRATEGIES: [
    ERolesAndPrivileges.CREATE_MANAGE_COLLECTION_FOLLOWUP,
    ERolesAndPrivileges.ASSIGN_COLLECTION_FOLLOWUP,
  ],
  COLLECTION_STRATEGY_NEW: [ERolesAndPrivileges.CREATE_MANAGE_COLLECTION_FOLLOWUP],
  COLLECTION_STRATEGY: [ERolesAndPrivileges.CREATE_MANAGE_COLLECTION_FOLLOWUP],

  SETTINGS: [
    ERolesAndPrivileges.COMPANY_SETTINGS,
    ERolesAndPrivileges.USER_ADMINISTRATION,
    ERolesAndPrivileges.COLLECTIONS_MANAGEMENT,
    ERolesAndPrivileges.DEFAULT_PRIVILEGE,
    ERolesAndPrivileges.APPS,
  ],

  EMAIL_TEMPLATES: [ERolesAndPrivileges.COLLECTIONS_MANAGEMENT],
  EMAIL_TEMPLATE: [ERolesAndPrivileges.COLLECTIONS_MANAGEMENT],
  EMAIL_TEMPLATE_NEW: [ERolesAndPrivileges.COLLECTIONS_MANAGEMENT],

  OWNER_ASSIGNMENT: [ERolesAndPrivileges.COLLECTIONS_MANAGEMENT],
  STRATEGY_ASSIGNMENT: [ERolesAndPrivileges.COLLECTIONS_MANAGEMENT],

  INTEGRATIONS: [ERolesAndPrivileges.APPS],
  INTEGRATION: [ERolesAndPrivileges.APPS],
  SLACK_ACCOUNT: [ERolesAndPrivileges.DEFAULT_PRIVILEGE],

  RECEIVABLES: [ERolesAndPrivileges.COLLECTIONS_MANAGEMENT],
  COLLECTION_PLANNING: [ERolesAndPrivileges.COLLECTIONS_MANAGEMENT],
  COLLECTION_STATUS: [ERolesAndPrivileges.COLLECTIONS_MANAGEMENT],
  DISPUTE_TYPE: [ERolesAndPrivileges.COLLECTIONS_MANAGEMENT],
  CURRENCY: [ERolesAndPrivileges.COLLECTIONS_MANAGEMENT],

  WORKFLOW_AUTOMATIONS: [
    ERolesAndPrivileges.CREATE_MANAGE_WORKFLOW,
    ERolesAndPrivileges.WORKFLOW_OWNER,
  ],
  CUSTOM_WORKFLOW: [ERolesAndPrivileges.DEFAULT_PRIVILEGE],
  CUSTOM_WORKFLOW_NEW: [ERolesAndPrivileges.DEFAULT_PRIVILEGE],
  ACCOUNT_DETAILS: [ERolesAndPrivileges.DEFAULT_PRIVILEGE],
  ORG_DETAILS: [ERolesAndPrivileges.COMPANY_SETTINGS],
  USER_MANAGEMENT: [ERolesAndPrivileges.DEFAULT_PRIVILEGE],
  ROLES_AND_PERMISSIONS: [ERolesAndPrivileges.USER_ADMINISTRATION],
  CONTACT_TYPES: [ERolesAndPrivileges.CONTACT_TYPE_MANAGEMENT],
  USER_CATEGORY: [ERolesAndPrivileges.DEFAULT_PRIVILEGE],

  ADVANCED_REPORTS: [ERolesAndPrivileges.ADVANCED_REPORTS],
  ADVANCED_REPORT: [ERolesAndPrivileges.ADVANCED_REPORTS],

  AR_EMAIL_VERIFICATION: [],
  ERROR: [],
  LOGIN: [],
  FORGOT_PASSWORD: [],
  RESET_PASSWORD: [],
  ACTIVATE_ACCOUNT: [],
  COLLECTORS_MANAGEMENT: [ERolesAndPrivileges.VIEW_COLLECTOR_INFORMATION],
  COLLECTION_ACTIVITIES: [ERolesAndPrivileges.VIEW_COLLECTION_ACTIVITIES],
  DOCUMENT_MANAGEMENT: [ERolesAndPrivileges.DOCUMENT_TYPE_SETTINGS],
  DSO_CONFIG: [ERolesAndPrivileges.COMPANY_SETTINGS],
  PREFERENCES: [ERolesAndPrivileges.DEFAULT_PRIVILEGE],
  UA_APPROVAL: [],
  APPROVALS: [
    ERolesAndPrivileges.APPROVAL_REQUEST_LIST_VIEW,
    ERolesAndPrivileges.APPROVAL_REQUEST_DETAILED_VIEW,
    ERolesAndPrivileges.APPROVAL_REQUEST_RESPOND,
  ],
  AUTOMATIONS: [
    ERolesAndPrivileges.CREATE_MANAGE_COLLECTION_FOLLOWUP,
    ERolesAndPrivileges.WORKFLOW_OWNER,
    ERolesAndPrivileges.CREATE_MANAGE_WORKFLOW,
    ERolesAndPrivileges.APPROVAL_REQUEST_LIST_VIEW,
    ERolesAndPrivileges.APPROVAL_REQUEST_DETAILED_VIEW,
    ERolesAndPrivileges.CREATE_MANAGE_COLLECTION_FOLLOWUP,
    ERolesAndPrivileges.ASSIGN_COLLECTION_FOLLOWUP,
    ERolesAndPrivileges.COLLECTIONS_MANAGEMENT,
  ],
});

export const AppPage: Record<AppPageName, PageDeet> = Object.freeze({
  /* ------------------------------- DASHBOARD -------------------------------- */
  DASHBOARD: {
    path: '/dashboard',
    routePath: 'dashboard',
    title: 'Dashboard',
    backlinks: [],
    roles: [],
    privileges: PrivilegeForPage.DASHBOARD,
  },
  ADMIN_DASHBOARD: {
    path: '/dashboard/company',
    routePath: 'company',
    title: 'Company Dashboard',
    backlinks: [],
    roles: [],
    privileges: PrivilegeForPage.ADMIN_DASHBOARD,
  },
  CASHAPPS: {
    path: '/ca/payments',
    routePath: 'payments',
    title: 'Cash Application',
    backlinks: [],
    roles: [],
    privileges: PrivilegeForPage.CASHAPPS,
  },
  TEAM_DASHBOARD: {
    path: '/dashboard/company',
    routePath: 'company',
    title: 'Company Dashboard',
    backlinks: [],
    roles: [],
    privileges: PrivilegeForPage.TEAM_DASHBOARD,
  },
  COLLECTOR_DASHBOARD: {
    path: '/dashboard/collector',
    routePath: 'collector',
    title: 'Collector Dashboard',
    backlinks: [],
    roles: [],
    privileges: PrivilegeForPage.COLLECTOR_DASHBOARD,
  },
  COLLABORATOR_DASHBOARD: {
    path: '/dashboard/collaborator',
    routePath: 'collaborator',
    title: 'Collaborator Dashboard',
    backlinks: [],
    roles: [],
    privileges: PrivilegeForPage.COLLABORATOR_DASHBOARD,
  },
  /* ------------------------------- AR-AGING INVOICE CUSTOMER -------------------------------- */
  AR_AGING: {
    path: '/aging-report',
    routePath: 'aging-report',
    title: 'AR Aging',
    backlinks: ['ADMIN_DASHBOARD'],
    roles: [],
    privileges: PrivilegeForPage.AR_AGING,
  },
  ALL_CUSTOMERS: {
    path: '/customers',
    routePath: 'customers',
    title: 'All Customers',
    backlinks: [],
    roles: [],
    privileges: PrivilegeForPage.ALL_CUSTOMERS,
  },
  CUSTOMER_DETAILS: {
    path: '/customers/:id',
    routePath: ':id',
    title: 'Customer Details',
    backlinks: ['AR_AGING', 'ALL_CUSTOMERS', 'INVOICE_DETAILS', 'INVOICE_LIST'],
    roles: [],
    privileges: PrivilegeForPage.CUSTOMER_DETAILS,
  },
  INVOICE_LIST: {
    path: '/invoices',
    routePath: 'invoices',
    title: 'Transaction -  Invoices',
    backlinks: [],
    roles: [],
    privileges: PrivilegeForPage.INVOICE_LIST,
  },
  INVOICE_DETAILS: {
    path: '/invoices/:id',
    routePath: ':id',
    title: 'Invoice Details',
    backlinks: ['ALL_CUSTOMERS', 'CUSTOMER_DETAILS', 'INVOICE_LIST'],
    roles: [],
    privileges: PrivilegeForPage.INVOICE_DETAILS,
  },
  PAYMENT_LIST: {
    path: '/payments',
    routePath: 'payments',
    title: 'Transaction - Payments',
    backlinks: [],
    roles: [],
    privileges: PrivilegeForPage.PAYMENT_LIST,
  },
  PAYMENT_DETAIL: {
    // Side Pane inside Payment List
    path: '/payments/:payment_id',
    routePath: ':payment_id',
    title: 'Transaction - Payments',
    backlinks: [],
    roles: [],
    privileges: PrivilegeForPage.PAYMENT_LIST,
  },

  /* ------------------------------- INBOX -------------------------------- */
  INBOX: {
    path: '/inbox',
    routePath: 'inbox',
    title: 'Inbox',
    backlinks: [],
    roles: [],
    privileges: PrivilegeForPage.INBOX,
  },
  INBOX_EMAIL: {
    path: '/inbox/email-conversation',
    routePath: 'email-conversation',
    title: 'Inbox',
    backlinks: [],
    roles: [],
    privileges: PrivilegeForPage.INBOX_EMAIL,
  },
  INBOX_ACTIVITIES: {
    path: '/inbox/assigned-activities',
    routePath: 'assigned-activities',
    title: 'Collection Activities',
    backlinks: [],
    roles: [],
    privileges: PrivilegeForPage.INBOX_ACTIVITIES,
  },
  /* ------------------------------- COLLECITON FOLLOWUP -------------------------------- */
  COLLECTION_FOLLOWUP_NEW: {
    path: '/collection-followups/new',
    routePath: 'new',
    title: 'New Collection Strategy',
    backlinks: ['COLLECTION_FOLLOWUPS'],
    roles: [],
    privileges: PrivilegeForPage.COLLECTION_FOLLOWUP_NEW,
  },
  COLLECTION_FOLLOWUP: {
    path: '/collection-followups/:id',
    routePath: ':id',
    title: 'Edit Collection Strategy',
    backlinks: ['COLLECTION_FOLLOWUPS'],
    roles: [],
    privileges: PrivilegeForPage.COLLECTION_FOLLOWUP,
  },
  COLLECTION_FOLLOWUPS: {
    path: '/collection-followups',
    routePath: 'collection-followups',
    title: 'Collection Strategies',
    backlinks: [],
    roles: [],
    privileges: PrivilegeForPage.COLLECTION_FOLLOWUPS,
  },
  COLLECTION_STRATEGY_NEW: {
    path: '/automations/collection-strategies/new',
    routePath: 'new',
    title: 'New Collection Strategy',
    backlinks: ['COLLECTION_STRATEGIES'],
    roles: [],
    privileges: PrivilegeForPage.COLLECTION_FOLLOWUP_NEW,
  },
  COLLECTION_STRATEGY: {
    path: '/automations/collection-strategies/:id',
    routePath: ':id',
    title: 'Edit Collection Strategy',
    backlinks: ['COLLECTION_STRATEGIES'],
    roles: [],
    privileges: PrivilegeForPage.COLLECTION_FOLLOWUP,
  },
  COLLECTION_STRATEGIES: {
    path: '/automations/collection-strategies',
    routePath: 'collection-strategies',
    title: 'Collection Strategies',
    backlinks: [],
    roles: [],
    privileges: PrivilegeForPage.COLLECTION_FOLLOWUPS,
  },
  /* ------------------------------- REPORTS -------------------------------- */
  REPORTS: {
    path: '/reports',
    routePath: 'reports',
    title: 'Reports',
    backlinks: [],
    roles: [],
    privileges: PrivilegeForPage.REPORTS,
  },
  /* ------------------------------- SETTINGS -------------------------------- */
  SETTINGS: {
    path: '/settings',
    routePath: 'settings',
    title: 'Settings',
    backlinks: [],
    roles: [],
    privileges: PrivilegeForPage.SETTINGS,
  },
  DSO_CONFIG: {
    path: '/settings/dso-config',
    routePath: 'dso-config',
    title: 'DSO Config',
    backlinks: ['SETTINGS'],
    roles: [],
    privileges: PrivilegeForPage.DSO_CONFIG,
  },
  PREFERENCES: {
    path: '/settings/preferences',
    routePath: 'preferences',
    title: 'Preferences',
    backlinks: ['SETTINGS'],
    roles: [],
    privileges: PrivilegeForPage.PREFERENCES,
  },
  //Customer Config
  CUSTOMER_PORTAL_CONFIG: {
    path: '/settings/customer-portal-config',
    routePath: 'customer-portal-config',
    title: 'Customer Portal',
    backlinks: ['SETTINGS'],
    roles: [],
    privileges: PrivilegeForPage.CUSTOMER_PORTAL_CONFIG,
  },
  // Productivity
  EMAIL_TEMPLATES: {
    path: '/settings/email-templates',
    routePath: 'email-templates',
    title: 'Email Templates',
    backlinks: ['SETTINGS'],
    roles: [],
    privileges: PrivilegeForPage.EMAIL_TEMPLATES,
  },
  EMAIL_TEMPLATE: {
    path: '/settings/email-templates/:id',
    routePath: ':id',
    title: 'Email Template',
    backlinks: ['SETTINGS', 'EMAIL_TEMPLATES'],
    roles: [],
    privileges: PrivilegeForPage.EMAIL_TEMPLATE,
  },
  EMAIL_TEMPLATE_NEW: {
    path: '/settings/email-templates/new',
    routePath: 'new',
    title: 'New Email Template',
    backlinks: ['SETTINGS', 'EMAIL_TEMPLATES'],
    roles: [],
    privileges: PrivilegeForPage.EMAIL_TEMPLATE,
  },
  // Assign Collection
  STRATEGY_ASSIGNMENT: {
    path: 'automations/assign-collection-strategy',
    routePath: 'assign-collection-strategy',
    title: 'Assign Collection Strategy',
    backlinks: [],
    roles: [],
    privileges: PrivilegeForPage.STRATEGY_ASSIGNMENT,
  },
  OWNER_ASSIGNMENT: {
    path: '/automations/assign-collection-owner',
    routePath: 'assign-collection-owner',
    title: 'Assign Collection Owner',
    backlinks: [],
    roles: [],
    privileges: PrivilegeForPage.OWNER_ASSIGNMENT,
  },
  // Integrations
  INTEGRATIONS: {
    path: '/settings/integrations',
    routePath: 'integrations',
    title: 'Integrations',
    backlinks: ['SETTINGS'],
    roles: [],
    privileges: PrivilegeForPage.INTEGRATIONS,
  },
  INTEGRATION: {
    path: '/settings/integrations/:integrationName',
    routePath: ':integrationName',
    title: 'Integration',
    backlinks: ['SETTINGS'],
    roles: [],
    privileges: PrivilegeForPage.INTEGRATION,
  },
  SLACK_ACCOUNT: {
    path: '/slack/account-bind',
    routePath: 'slack/account-bind',
    title: 'Notifications',
    backlinks: [],
    roles: [],
    privileges: PrivilegeForPage.SLACK_ACCOUNT,
  },
  // Receivables
  RECEIVABLES: {
    path: '/settings/receivables',
    routePath: 'receivables',
    title: 'Receivables',
    backlinks: ['SETTINGS'],
    roles: [],
    privileges: PrivilegeForPage.RECEIVABLES,
  },
  COLLECTION_PLANNING: {
    path: '/settings/collection-planning',
    routePath: 'collection-planning',
    title: 'Collection Planning',
    backlinks: ['SETTINGS'],
    roles: [],
    privileges: PrivilegeForPage.RECEIVABLES,
  },
  CURRENCY: {
    path: '/settings/currency',
    routePath: 'currency',
    title: 'Currency',
    backlinks: ['SETTINGS'],
    roles: [],
    privileges: PrivilegeForPage.CURRENCY,
  },
  COLLECTION_STATUS: {
    path: '/settings/receivables/collection-status',
    routePath: 'receivables/collection-status',
    title: 'Collection Status',
    backlinks: ['SETTINGS'],
    roles: [],
    privileges: PrivilegeForPage.RECEIVABLES,
  },
  // Admin Settings
  ACCOUNT_DETAILS: {
    path: '/settings/account-details',
    routePath: 'account-details',
    title: 'Account Details',
    backlinks: [],
    roles: [],
    privileges: PrivilegeForPage.ACCOUNT_DETAILS,
  },
  ORG_DETAILS: {
    path: '/settings/company',
    routePath: 'company',
    title: 'Organisation Details',
    backlinks: ['SETTINGS'],
    roles: [],
    privileges: PrivilegeForPage.ORG_DETAILS,
  },
  USER_MANAGEMENT: {
    path: '/settings/user-management',
    routePath: 'user-management',
    title: 'User Management',
    backlinks: ['SETTINGS'],
    roles: [],
    privileges: PrivilegeForPage.USER_MANAGEMENT,
  },
  USER_CATEGORY: {
    path: '/settings/user-category',
    routePath: 'user-category',
    title: 'User Category',
    backlinks: ['SETTINGS'],
    roles: [],
    privileges: PrivilegeForPage.USER_MANAGEMENT,
  },
  ROLES_AND_PERMISSIONS: {
    path: '/settings/roles-permissions',
    routePath: 'roles-permissions',
    title: 'Roles and Permissions',
    backlinks: ['SETTINGS'],
    roles: [],
    privileges: PrivilegeForPage.ROLES_AND_PERMISSIONS,
  },
  CONTACT_TYPES: {
    path: '/settings/contact-types',
    routePath: 'contact-types',
    title: 'User Administration',
    backlinks: ['SETTINGS'],
    roles: [],
    privileges: PrivilegeForPage.CONTACT_TYPES,
  },
  DISPUTE_TYPE: {
    path: '/settings/dispute-types',
    routePath: 'dispute-types',
    title: 'Dispute Types',
    backlinks: ['SETTINGS'],
    roles: [],
    privileges: PrivilegeForPage.RECEIVABLES,
  },

  ADVANCED_REPORTS: {
    path: '/advanced-reports',
    title: 'Advanced Reports',
    routePath: 'advanced-reports',
    backlinks: ['ADVANCED_REPORTS'],
    roles: [],
    privileges: PrivilegeForPage.ADVANCED_REPORTS,
  },

  ADVANCED_REPORT: {
    path: '/advanced-reports/:id',
    routePath: ':id',
    title: 'Advanced Report',
    backlinks: [],
    roles: [],
    privileges: PrivilegeForPage.ADVANCED_REPORT,
  },

  //Automations
  WORKFLOW_AUTOMATIONS: {
    path: '/automations/workflow-automations',
    routePath: 'workflow-automations',
    title: 'Workflow Automations',
    backlinks: [],
    roles: [],
    privileges: PrivilegeForPage.WORKFLOW_AUTOMATIONS,
  },
  CUSTOM_WORKFLOW: {
    path: '/automations/workflow-automations/:workflow_id',
    routePath: ':workflow_id',
    title: 'Workflow Automations',
    backlinks: ['WORKFLOW_AUTOMATIONS'],
    roles: [],
    privileges: PrivilegeForPage.WORKFLOW_AUTOMATIONS,
  },
  CUSTOM_WORKFLOW_NEW: {
    path: '/automations/workflow-automations/new',
    routePath: 'new',
    title: 'New Workflow Automations',
    backlinks: ['WORKFLOW_AUTOMATIONS'],
    roles: [],
    privileges: PrivilegeForPage.WORKFLOW_AUTOMATIONS,
  },
  AUTOMATIONS: {
    path: '/automations',
    routePath: 'automations',
    title: 'Automations',
    backlinks: ['WORKFLOW_AUTOMATIONS', 'CUSTOM_WORKFLOW', 'APPROVALS'],
    roles: [],
    privileges: PrivilegeForPage.AUTOMATIONS,
  },

  /* ------------------------------- PUBLIC -------------------------------- */
  AR_EMAIL_VERIFICATION: {
    path: '/ar-email-verification/:hash',
    routePath: 'ar-email-verification/:hash',
    title: 'Verify AR Email',
    backlinks: [],
    roles: [],
    privileges: [],
  },
  ERROR: {
    path: '/error',
    routePath: 'error',
    title: 'Error',
    backlinks: [],
    roles: [],
    privileges: [],
  },
  LOGIN: {
    path: '/login',
    routePath: 'login',
    title: 'Login',
    backlinks: [],
    roles: [],
    privileges: [],
  },
  FORGOT_PASSWORD: {
    path: '/forgot-password',
    routePath: 'forgot-password',
    title: 'Forgot Password',
    backlinks: [],
    roles: [],
    privileges: [],
  },
  RESET_PASSWORD: {
    path: '/forgot-password/:hash',
    routePath: 'reset-password/:hash',
    title: 'Reset Password',
    backlinks: [],
    roles: [],
    privileges: [],
  },
  ACTIVATE_ACCOUNT: {
    path: '/activate-account/:hash',
    routePath: 'activate-account/:hash',
    title: 'Activate Account',
    backlinks: [],
    roles: [],
    privileges: [],
  },
  COLLECTORS_MANAGEMENT: {
    path: '/collector-management',
    routePath: 'collector-management',
    title: 'Collector Management',
    backlinks: [],
    roles: [],
    privileges: PrivilegeForPage.COLLECTORS_MANAGEMENT,
  },
  COLLECTION_ACTIVITIES: {
    path: '/collection-activities',
    routePath: 'collection-activities',
    title: 'Disputes',
    backlinks: [],
    roles: [],
    privileges: PrivilegeForPage.COLLECTION_ACTIVITIES,
  },
  DOCUMENT_MANAGEMENT: {
    path: '/settings/document-management',
    routePath: 'document-management',
    title: 'Document Management',
    backlinks: [],
    roles: [],
    privileges: PrivilegeForPage.SETTINGS,
  },
  UA_APPROVAL: {
    path: '/ua/approvals/:hash',
    routePath: 'approvals/:hash',
    title: 'Approvals',
    backlinks: [],
    roles: [],
    privileges: PrivilegeForPage.UA_APPROVAL,
  },
  APPROVALS: {
    path: '/automations/approvals',
    routePath: 'approvals',
    title: 'Approvals',
    backlinks: [],
    roles: [],
    privileges: PrivilegeForPage.APPROVALS,
  },
});

export function pageInfo(page: AppPageName) {
  return AppPage[page];
}

export function pagePrivileges(page: AppPageName) {
  return AppPage[page].privileges;
}

export function pageBacklinks(page: AppPageName) {
  return AppPage[page].backlinks;
}

export function pageName(page: AppPageName) {
  return AppPage[page].title;
}

/**
 * @param page App Page Name
 * @param pathParams Key-Val pairs of path param name & it's value
 * @returns A valid URL
 * @description
 * -  It will replace path variables for an app page path
 *    with supplied values in `pathParams`
 * -  It will ignore the pathParam when constructing URL
 *    if no matching param is supplied in `pathParams` arg
 */
export function pagePath(page: AppPageName, pathParams: Record<string, string | number> = {}) {
  const { path } = AppPage[page];
  const pathItems = path.split(/\//).filter((p) => p);

  // can use generatePath util fn from react-router here :/

  return pathItems.reduce((str, pathItem) => {
    if (pathItem.startsWith(':')) {
      const param = pathItem.substring(1);
      return pathParams[param] ? `${str}/${pathParams[param]}` : str;
    }
    return `${str}/${pathItem}`;
  }, '');
}

export function routePath(page: AppPageName) {
  return AppPage[page].routePath;
}
