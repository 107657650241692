import { GrowDate } from '@sinecycle/growcomponents';
import { useSelector } from 'react-redux';
import { dateFormatSelector } from 'store/authentication/authentication';
import { CustomOptional } from 'types/utils/utils';

type DateComponentProps = React.ComponentProps<typeof GrowDate>;

function DateText(props: CustomOptional<DateComponentProps, 'format'>) {
  const format = useSelector(dateFormatSelector);
  return <GrowDate {...props} format={props.format ? props.format : format} />;
}

export { DateText };
