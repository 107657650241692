export enum CustomDetailsType {
  CUSTOM_FIELD = 'CUSTOM_FIELD',
  PRIMARY_CONTACT = 'PRIMARY_CONTACT',
  SECONDARY_CONTACT = 'SECONDARY_CONTACT',
}

export interface CustomDetail<T> {
  display_name: string;
  type: CustomDetailsType;
  value: T;
  id?: number;
}

export interface PasswordDetailsConfig {
  password_filter: string[];
  link_expiration_config: {
    FORGOT_PASSWORD: {
      duration: number;
      chrono_unit: string;
    };
  };
}

export interface CustomDetailConfig {
  field_id?: number;
  field_type: CustomDetailsType;
}

export interface ParentChildConfigProps {
  view_child: boolean;
  edit_child: boolean;
}
