import { Flex } from 'components/BaseComponents/Layout/Flex';
import { GrowText } from 'components/BaseComponents/Typography';
import {
  CreatedBlock,
  CustomerBlock,
  LabelBlock,
  TextBlock,
} from 'components/CollectionActivities/Common/Details/BodyBlocks';
import { InfoContent } from 'components/CollectionActivities/Common/Details/Content';
import { SourceAppComp } from 'components/Common/SourceApp/SourceApp';
import HideWrapper from 'components/Common/Util/HideWrapper';
import { handleCreateCustomEventCallBack } from 'components/Inbox/CollectionActivitiesV2/hooks/useActivityUpdation';
import { ActivityDetailsBase, ActivityType } from 'types/activities/activity-types';
import { PTPType, PromiseToPay } from 'types/activities/promise-to-pay';
import { PerformedActionType } from 'types/api/inbox/activity';
import { BaseCustomer } from 'types/entities/customer';
import { PTPStatusQuickSelect } from './Details/Common/PTPStatus';

interface PTPDetailsInfoProps extends ActivityDetailsBase {
  data?: PromiseToPay;
  loading: boolean;
}

const t = {
  expected_pay_date: 'Expected Pay Date',
  customer_pay_date: 'Customer Pay Date',
};
function PTPDetailsInfo(props: PTPDetailsInfoProps) {
  const { data, loading, customer, activitiesMode } = props;
  const Status =
    !loading && data ? (
      <PTPStatusQuickSelect
        ptpActivityId={data.id}
        status={data.status}
        onChangeCallBack={(status) => {
          handleCreateCustomEventCallBack('updating_query', {
            id: data.id,
            activity: ActivityType.PROMISE_TO_PAY,
            value: { status, action: PerformedActionType.STATUS_CHANGED },
          });
        }}
        activitiesMode={activitiesMode}
      />
    ) : null;

  const Type =
    !loading && data ? (
      <TextBlock
        value={data.type === PTPType.EXPECTED ? t.expected_pay_date : t.customer_pay_date}
      />
    ) : null;
  const Source =
    data && !loading ? (
      <HideWrapper hide={data?.source_app !== 'CUSTOMER_PORTAL'}>
        <Flex align="center" gap="var(--space-4)">
          <GrowText color="var(--gray-8)">via</GrowText>
          <SourceAppComp customer_details={customer as BaseCustomer} source_app="CUSTOMER_PORTAL" />
        </Flex>
      </HideWrapper>
    ) : null;

  const Created =
    data && !loading ? (
      <Flex gap={'var(--space-4)'} direction={'column'}>
        <CreatedBlock createdBy={data.created_by} createdDate={data.created_date} />
        {Source}
      </Flex>
    ) : null;

  const Customer =
    customer && !loading ? (
      <CustomerBlock id={customer.id} name={customer.name} customerNumber={customer.customer_no} />
    ) : null;

  return (
    <Flex direction="column" gap="var(--space-16)">
      <InfoContent content={Status} label={<LabelBlock label="Status" color="var(--gray-7)" />} />
      <InfoContent content={Type} label={<LabelBlock label="PTP Type" color="var(--gray-7)" />} />
      <InfoContent content={Created} label={<LabelBlock label="Created" color="var(--gray-7)" />} />
      <InfoContent
        content={Customer}
        label={<LabelBlock label="Customer" color="var(--gray-7)" />}
      />
    </Flex>
  );
}

export { PTPDetailsInfo };
