import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popover, Tooltip } from 'antd';
import HideWrapper from 'components/Common/Util/HideWrapper';
import { CSSProperties } from 'react';
import useOpen from 'util/hooks/useOpen';
import { Flex, FlexBoxChild } from '../Layout/Flex';
import { HoverBox, HoverWrapper } from './style';
import { EditOnHoverProps, HoverWrapperVariants } from './type';

export function EditOnHover(props: EditOnHoverProps) {
  const {
    popoverOverrides,
    EditPopover,
    tooltipOnEdit,
    tooltipOnRemove,
    showClear,
    onClear,
    iconSize,
    extra,
    hoverMode = 'HOVER_BOX',
  } = props;

  const { open: popoverVisible, handleOpenChange } = useOpen({ open: false });
  function handleClick(e: React.MouseEvent<SVGSVGElement, MouseEvent>) {
    props.onEdit?.(e);
  }
  function onClearCallback() {
    onClear?.();
  }

  const EditIcon = (
    <Tooltip title={tooltipOnEdit ?? 'Edit'}>
      <FontAwesomeIcon
        size={iconSize ? iconSize : hoverMode === 'HOVER_BOX' ? 'sm' : undefined}
        onClick={handleClick}
        className="modify-icon edit-icon cursor-pointer"
        icon={['far', 'pen']}
        color="var(--purple-7)"
        {...props.iconProps}
      />
    </Tooltip>
  );

  const ClearIcon = (
    <Tooltip title={tooltipOnRemove ?? 'Reset'}>
      <FontAwesomeIcon
        size={iconSize ? iconSize : hoverMode === 'HOVER_BOX' ? 'sm' : undefined}
        onClick={onClearCallback}
        className="modify-icon remove-icon cursor-pointer"
        icon={['far', 'undo']}
        color="var(--purple-7)"
        {...props.iconProps}
      />
    </Tooltip>
  );

  const EditElement = EditPopover ? (
    <Popover
      content={EditPopover}
      trigger="click"
      placement="rightBottom"
      onOpenChange={handleOpenChange}
      {...popoverOverrides}
    >
      {EditIcon}
    </Popover>
  ) : (
    <>{EditIcon}</>
  );

  const ClearElement = showClear ? ClearIcon : null;

  const Mode1 = (
    <HoverBox
      className={`edit-on-hover-box ${props.className ? props.className : ''} ${
        popoverVisible ? 'popover-visible' : ''
      }`}
      style={
        {
          '--hover-bg': props.hoverBg,
          width: 'fit-content',
          maxWidth: '100%',
          ...props.style,
        } as CSSProperties
      }
    >
      <FlexBoxChild style={{ ...props.overrideStyles?.children }}>{props.children}</FlexBoxChild>

      <HideWrapper hide={Boolean(props.hideEdit)}>
        <FlexBoxChild style={{ marginLeft: 'auto', ...props.overrideStyles?.editElement }}>
          {EditElement}
        </FlexBoxChild>
      </HideWrapper>
      <HideWrapper hide={!showClear}>
        <FlexBoxChild style={{ ...props.overrideStyles?.clearElement }}>
          {ClearElement}
        </FlexBoxChild>
      </HideWrapper>
    </HoverBox>
  );

  const Mode2 = (
    <div
      onMouseEnter={() => {
        props?.onHover?.(true);
      }}
      onMouseLeave={() => {
        props?.onHover?.(false);
      }}
      style={{ width: hoverMode === 'BOX' ? 'fit-content' : '100%' }}
    >
      <HoverWrapper
        align="center"
        gap="var(--space-8)"
        justify={hoverMode === 'NORMAL' ? 'space-between' : 'flex-start'}
        mode={hoverMode ? (hoverMode as HoverWrapperVariants) : undefined}
      >
        <>{props.children}</>

        <Flex align="center" gap="var(--space-12)">
          <HideWrapper hide={!showClear}>
            <>{ClearElement}</>
          </HideWrapper>
          <HideWrapper hide={Boolean(props.hideEdit)}>{EditElement}</HideWrapper>
          {extra ? extra : null}
        </Flex>
      </HoverWrapper>
    </div>
  );

  if (props.hide) return null;
  return <>{hoverMode === 'HOVER_BOX' ? Mode1 : Mode2}</>;
}
