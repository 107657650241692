import { LayoutSider } from 'components/BaseComponents/Layout/AntLayout';
import Menu from 'components/BaseComponents/Menu';
import styled from 'styled-components';

export const StyledSider = styled(LayoutSider)`
  background-color: var(--primary-10);

  li {
    &.AUTOMATIONS {
      .ant-menu-item {
        margin-bottom: 4px !important;
      }

      .ant-menu-item-group-title {
        padding-inline-start: 28px !important;
        color: var(--gray-8);
      }
      .ant-menu-item-group {
        ul {
          padding-inline-start: 24px !important;
          padding-inline-end: 4px !important;
        }
      }
    }
  }
  .ant-layout-sider-children {
    overflow: hidden;
  }
  .ant-layout-sider-has-trigger {
    padding: 0;
  }

  .ant-layout-sider-trigger {
    line-height: var(--space-16);
    width: var(--space-24) !important;
    height: var(--space-24);
    top: 40px;
    right: -12px;
    position: absolute;
    background-color: var(--gray-1);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: var(--space-12);
    border: 1px solid var(--gray-5);
    z-index: 600;
  }
  .help-text {
    svg {
      path {
        fill: var(--gray-1);
      }
    }
  }
`;
export const StyledMenuSideBar = styled(Menu)`
  padding-inline: var(--space-8) !important;
  margin-top: var(--space-16);
  overflow-y: auto;
  height: 80vh;
  &.ant-menu-item,
  .ant-menu-submenu-title {
    padding-left: var(--space-12) !important;
  }

  li:not(.ant-menu-item-selected):not(.ant-menu-submenu-selected):not(.ant-menu-item) {
    svg {
      color: var(--gray-7);
      fill: var(--gray-7);
      path {
        fill: var(--gray-7);
      }
    }
  }

  .ant-menu-submenu,
  .ant-menu-submenu-inline {
    padding-bottom: 0;
    margin-bottom: var(--space-12) !important;
  }

  .ant-menu-item {
    margin: 0 !important;
    margin-bottom: var(--space-12) !important;
    padding-left: var(--space-24) !important;
    width: 100%;
    padding-left: 18px !important;
  }

  /* &.ant-menu.ant-menu-dark,
  .ant-menu-dark .ant-menu-sub,
  .ant-menu.ant-menu-dark .ant-menu-sub {
    background-color: var(--primary-10);
  } */

  &.ant-menu-dark .ant-menu-inline.ant-menu-sub {
    background: transparent;
  }

  &.ant-menu-item .ant-menu-item-icon,
  .ant-menu-submenu-title .ant-menu-item-icon,
  .ant-menu-item .anticon,
  .ant-menu-submenu-title .anticon {
    font-size: var(--fs-20);
    width: var(--space-24);
  }

  /* &.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: var(--primary-8);
  } */
  .ant-menu-item .ant-menu-item-icon + span,
  .ant-menu-submenu-title .ant-menu-item-icon + span,
  .ant-menu-item .anticon + span,
  .ant-menu-submenu-title .anticon + span {
    margin-left: var(--space-8);
  }
  &.ant-menu-vertical > .ant-menu-item,
  .ant-menu-vertical-left > .ant-menu-item,
  .ant-menu-vertical-right > .ant-menu-item,
  .ant-menu-inline > .ant-menu-item,
  .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
  .ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
  .ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
  .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    line-height: var(--space-16);
    padding-left: 32px !important;
  }

  .ant-menu-title-content {
    color: var(--gray-1);
  }
  .INBOX {
    .ant-menu-title-content {
      margin-left: var(--space-12);
    }
    &.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
      padding-left: 26px !important;
    }
  }
  .SETTINGS {
    .ant-menu-item-icon {
      font-size: 20px !important;
    }
  }
  .ant-menu-sub.ant-menu-inline > .ant-menu-item,
  .ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    height: 32px !important;
    margin: 0 !important;
  }
  .ant-menu-submenu-title {
    .ant-menu-title-content {
      color: var(--gray-1) !important;
    }
    svg {
      color: var(--gray-7);
      fill: var(--gray-7);
      path {
        fill: var(--gray-7);
      }
    }
  }

  li:not(.ant-menu-item-selected):not(.ant-menu-submenu-inline) {
    &:hover {
      background-color: var(--primary-9);
    }
  }

  .INBOX:not(.ant-menu-item-selected),
  .SETTINGS:not(.ant-menu-item-selected) {
    svg {
      path {
        color: var(--gray-7) !important;
        fill: var(--gray-7) !important;
      }
      font-size: 20px !important;
    }
  }

  .ant-menu-submenu-arrow {
    color: var(--gray-1);
  }
  &.ant-menu-item-group:hover {
    background-color: transparent !important;
  }
  li:not(.ant-menu-item-selected):not(.ant-menu-submenu-inline):hover {
    background-color: transparent !important;
  }
`;
