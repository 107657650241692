import { ToggleParamProps } from 'queries/workflow';
import { CustomField, CustomFieldEntityType } from 'types/entities/custom-field';
import { WorkFlowFiltersDTO, WorkFlowListDTO, WorkflowMetaDto } from 'types/entities/workflow';
import ky from './ky';

const baseUrl = 'workflow';

export async function getWorkFlowList() {
  return await ky.get(baseUrl).json<WorkFlowListDTO[]>();
}

export async function submitWorkflowMeta(data: Partial<WorkflowMetaDto>) {
  return await ky.post(baseUrl, { json: data }).json<WorkflowMetaDto>();
}

export async function modifyWorkflowMeta(data: Partial<WorkflowMetaDto>) {
  return await ky.put(baseUrl, { json: data }).json<WorkflowMetaDto>();
}

export async function getWorkFlowMeta(id: string) {
  return await ky.get(`${baseUrl}/${id}`).json<WorkflowMetaDto>();
}

export async function deleteWorkFlowList(id: number) {
  return await ky.delete(`${baseUrl}/?id=${id}`);
}

export async function filterWorkFlowList(filterParam: Partial<WorkFlowFiltersDTO>) {
  return await ky.post(`${baseUrl}/filter-view`, { json: filterParam }).json<WorkFlowListDTO[]>();
}

export async function toggleWorkFlowFollowup(params: Partial<ToggleParamProps>) {
  return await ky.post(`${baseUrl}/${params.id}/${params.toggleState}`, {
    json: params.scheduleData,
  });
}

export async function workflowCustomField(type: CustomFieldEntityType) {
  return await ky.get(`custom-fields/account/${type}`).json<CustomField[]>();
}
