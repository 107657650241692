import { useQuery } from '@tanstack/react-query';
import { getCustomFieldValues } from 'services/custom-field';
import { workflowCustomField } from 'services/workflows';
import { CustomFieldDataResponse, CustomFieldEntityType } from 'types/entities/custom-field';
import { HALF_DAY } from './admin-dashboard';

export function useCustomFieldsQuery({ entityId, baseUrl }: { entityId: number; baseUrl: string }) {
  return useQuery({
    queryKey: ['custom-field-values', entityId],
    queryFn: () => getCustomFieldValues(entityId, baseUrl),
    staleTime: 0,
    cacheTime: 0,
  });
}

export function useAccountLevelCustomFieldsQuery(
  type: CustomFieldEntityType,
  handleSuccessCallback?: (data: CustomFieldDataResponse[]) => void,
  handleErrorCallBack?: () => void
) {
  return useQuery({
    queryKey: ['workflow-custom-field', type],
    queryFn: () => workflowCustomField(type),
    retry: false,
    cacheTime: 0,
    staleTime: 0,
    onSuccess(data) {
      handleSuccessCallback?.(data);
    },
    onError(err) {
      handleErrorCallBack?.();
    },
  });
}

export function useAccountLevelCustomFieldsQueryWorkflow(type: CustomFieldEntityType) {
  return useQuery({
    queryKey: ['workflow-custom-field-all', type],
    queryFn: () => workflowCustomField(type),
    retry: false,
    cacheTime: HALF_DAY,
    staleTime: HALF_DAY,
  });
}
