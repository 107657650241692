import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GrowFormFooter, GrowFormHeader, GrowModal } from '@sinecycle/growcomponents';
import { useQueryClient } from '@tanstack/react-query';
import { Col, Row, Typography } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { notify } from 'components/BaseComponents/Notifications';
import { GrowText } from 'components/BaseComponents/Typography';
import InvoiceSelect from 'components/Common/ActivitiesForm/FormElements/InvoiceSelect';
import { EllipsisList } from 'components/Common/EllipsisList';
import { openInvoiceStatus } from 'components/CustomerDetails/Body/Statements/OpenInvoicesStatements';
import { invoiceTransformSchema } from 'components/HigherOrderComponent/KeyActivitesContainer/ActivitySchema';
import TextArea from 'components/HigherOrderComponent/Mentions';
import { COMMON_EVENT } from 'events/common';
import produce from 'immer';
import { dispatchAppEvent } from 'lib/pub-sub';
import { map, union } from 'lodash';
import { useFlagInvoiceBulkAction, useFlagInvoices } from 'queries/invoices';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getInvoicesSuggestions, getRecentInvoicesOfCustomer } from 'services/invoice';
import { IInvoices, StatementsResponse } from 'store/customer/types';
import {
  invoiceFlagCountSelector,
  invoiceFlagListSelector,
  updateInvoiceFlagCount,
  updateInvoiceFlagList,
} from 'store/invoice-flag/invoice-flag';
import {
  IInvoiceFlagList,
  IInvoiceIdAndFlag,
  ITotalInvoiceFlagCount,
} from 'store/invoice-flag/types';
import { InvoiceDropdownInterfaceProps } from 'store/invoice/type';
import { IActionsModal } from '../ActionsModal/types';
import { EActions } from '../types';

const t = {
  title: 'Flag Invoices',
  okButton: 'Flag',
  comment: 'Comments',
  msg: {
    success: 'Invoice flagged',
    error: 'Unable to flag invoices',
  },
};

const FlagInvoices = (props: IActionsModal) => {
  const { customerId } = props;
  const [invoiceListToFlag, setInvoiceListToFlag] = useState<InvoiceDropdownInterfaceProps[]>();
  const [invoiceIdList, setInvoiceIdList] = useState<number[] | undefined>();
  const [comment, setComment] = useState<string>();
  const dispatch = useDispatch();
  const flagStatus = useSelector(invoiceFlagListSelector);
  const currentFlaggedInvoiceCount = useSelector(invoiceFlagCountSelector);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [invoiceSelected, setInvoiceSelected] = useState(false);
  const { mutateAsync: flagInvoices, isLoading: flagInvoiceLoading } = useFlagInvoices();
  const { mutateAsync: flagInvoicesBulk, isLoading: flagInvoiceBulkLoading } =
    useFlagInvoiceBulkAction();

  const queryClient = useQueryClient();

  function handleUpdatedValues() {
    const currentData = queryClient.getQueriesData<StatementsResponse<IInvoices, any>>(
      props.queryKey ?? []
    );

    if (!currentData.length) return;
    const [, previousData] = currentData[0];
    if (!previousData?.list) return;

    const updatedData = produce(previousData?.list, (draft) => {
      draft.map((invoice) => {
        if (invoiceIdList?.includes(invoice.id)) {
          invoice.invoice_flag = {
            ...invoice.invoice_flag,
            id: invoice.id,
            comment: comment ?? '',
          };
          return invoice;
        }
        return invoice;
      });
    });

    queryClient.setQueriesData<StatementsResponse<IInvoices, any>>(
      [...(props.queryKey ?? [])],
      (old) => {
        if (!old) return;
        return {
          ...old,
          list: [...updatedData],
        };
      }
    );
  }
  useEffect(() => {
    const list = [] as InvoiceDropdownInterfaceProps[];
    props.invoiceList?.forEach((inv) => {
      if (!flagStatus.find((status) => status.id === inv.id)?.flag) {
        list.push(inv);
      }
    });
    const selectedInvoicesId = map(list, (item) => item.id);
    setInvoiceIdList(selectedInvoicesId);
    setInvoiceListToFlag(list);
  }, [flagStatus, props.invoiceList]);

  useEffect(() => {
    if ((invoiceIdList && invoiceIdList.length > 0) || props.selectAll) {
      setInvoiceSelected(true);
    } else {
      setInvoiceSelected(false);
    }
  }, [invoiceIdList, invoiceListToFlag, props.selectAll]);

  const handleOk = () => {
    let param;

    if (!invoiceSelected) return;
    setConfirmLoading(true);

    if (props.isFromInvoiceList) {
      param = {
        ...props.currentFilterView,
        status: props.currentFilterView?.status ?? openInvoiceStatus,
        bulk_action: 'FLAG',
        flag: {
          invoice_list: invoiceIdList,
        },
      };

      handleRequestInvoiceList(param, 'invoices');
    } else {
      param = {
        bulk_action: 'FLAG',
        flag: {
          invoice_list: invoiceIdList,
        },
        filter: props.currentFilterView,
      };
      handleRequestCustomerDetails(param, `customers/${customerId}/invoices`);
    }
  };

  const handleRequestInvoiceList = (param: any, baseUrl: string) => {
    if (props.selectAll) {
      flagInvoicesBulk({ params: param, baseUrl: baseUrl })
        .then(() => {
          handleFlagInvoices();
        })
        .catch((e) => handleError(e));
    } else {
      flagInvoices({
        invoiceList: invoiceIdList,
        comment: comment,
      })
        .then(() => {
          handleFlagInvoices();
        })
        .catch((e) => handleError(e));
    }
  };

  const handleRequestCustomerDetails = (param: any, baseUrl: string) => {
    if (props.selectAll) {
      flagInvoicesBulk({ params: param, baseUrl })
        .then(() => {
          handleFlagInvoices();
        })
        .catch((e) => handleError(e));
    } else {
      flagInvoices({
        invoiceList: invoiceIdList,
        comment: comment,
      })
        .then(() => {
          handleFlagInvoices();
        })
        .catch((e) => handleError(e));
    }
  };

  const handleCancel = () => {
    props.resetToIntialState && props.resetToIntialState();
  };

  const handleFlagInvoices = () => {
    const flagList = {} as IInvoiceFlagList;
    flagList.list = [] as IInvoiceIdAndFlag[];
    invoiceIdList?.forEach((inv) => {
      flagList.list.push({
        id: inv,
        flag: true,
      } as IInvoiceIdAndFlag);
    });
    dispatch(updateInvoiceFlagList(flagList));
    dispatch(
      updateInvoiceFlagCount({
        count: currentFlaggedInvoiceCount + flagList.list.length,
      } as ITotalInvoiceFlagCount)
    );
    props.onSuccessCallback && props.onSuccessCallback();
    props.resetToIntialState && props.resetToIntialState();
    setConfirmLoading(false);
    dispatchAppEvent({
      type: COMMON_EVENT.COLLECTION_ACTION,
      payload: {
        actionName: 'Flag Invoices',
        actionType: 'Save',
      },
    });
    notify.success(t.msg.success);

    handleUpdatedValues();
  };

  const handleError = (e: any) => {
    setConfirmLoading(false);
    console.error('Unable to flag invoices', e);
    notify.error(t.msg.error);
  };
  useEffect(() => {
    if (props.selectAll) {
      const id = props.invoiceList?.map((invoice) => invoice.id);
      setInvoiceIdList(id);
    }
  }, [invoiceListToFlag, props.invoiceList, props.selectAll]);
  const Title = (
    <Row className="text-20" align={'middle'}>
      <Col span={1}>
        <FontAwesomeIcon icon={['far', 'flag']} color="var(--volcano-5)" size="sm" />
      </Col>
      <Col span={23} style={{ paddingLeft: 'var(--space-12)' }}>
        <Typography style={{ color: 'var(--gray-9)' }}>{t.title}</Typography>
      </Col>
    </Row>
  );

  const Header = <GrowFormHeader mode={'modal'} title={Title} onClose={handleCancel} />;

  const selectedValues = useMemo(() => {
    return props.invoiceList?.map((invoice) => invoice.invoice_no);
  }, [props.invoiceList]);

  const EllipsisListComp = (
    <EllipsisList
      list={selectedValues}
      label="Added Invoices"
      totalRecords={props.totalRecords}
      showScrollablePopover
    />
  );

  const InvoiceSelectComp = (
    <Flex direction="column">
      <GrowText>Add Invoices</GrowText>
      <InvoiceSelect
        selectedInvoicesId={union(invoiceIdList).filter(Boolean) as number[]}
        selectedInvoices={invoiceListToFlag}
        onChangeInvoice={(invoiceList) => {
          setInvoiceIdList(invoiceList);
        }}
        queryFn={() => getRecentInvoicesOfCustomer(customerId)}
        queryKey={['recent-customer-invoices', customerId]}
        searchQueryFn={(searchTerm: string) => getInvoicesSuggestions(searchTerm, customerId)}
        transformSchema={invoiceTransformSchema}
        key={invoiceIdList?.length}
      />
    </Flex>
  );

  const footer = (
    <GrowFormFooter
      cancelButtonProps={{ onClick: handleCancel }}
      saveButtonProps={{
        onClick: handleOk,
        disabled: !invoiceSelected,
        loading: flagInvoiceLoading || flagInvoiceBulkLoading,
      }}
    />
  );

  return (
    <GrowModal
      title={Header}
      type="activities"
      footer={footer}
      open={props.quickAction === EActions.FLAG_INVOICE}
      onCancel={handleCancel}
      okText={t.okButton}
      confirmLoading={confirmLoading}
      closable={false}
    >
      <Row style={{ marginBottom: 'var(--space-24)' }}>
        <Col span={24}>{props.selectAll ? EllipsisListComp : InvoiceSelectComp}</Col>
      </Row>
      <Row>
        <Col span={24}>
          <span className="sub-title">{t.comment}</span>
          <TextArea
            onChange={(comment) => {
              setComment(comment);
            }}
          ></TextArea>
        </Col>
      </Row>
    </GrowModal>
  );
};

export default FlagInvoices;
