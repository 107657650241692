import { GrowTypography } from '@sinecycle/growcomponents';
import { HyerLinkDetailsProps } from 'components/ARAging/ReceivablesByAgingBucket/AgingCustomersTable/Columns/Customer/CellRenderer/ParentCellRender';
import { CSSProperties } from 'react';
import { useAuthorisedCustomerName } from './useGetAuthorisedName';

interface TextWithHyperLinkProps {
  style?: CSSProperties;
  hyperLinkInfo?: HyerLinkDetailsProps;
}
export default function TextWithHyperLink(props: TextWithHyperLinkProps) {
  const { getCustomerName } = useAuthorisedCustomerName();
  const { formattedName, reactNode: DisplayAuthorisedName } = getCustomerName({
    name: props.hyperLinkInfo?.name,
    customerNumber: props.hyperLinkInfo?.customer_no,
    id: props.hyperLinkInfo?.id,
    authorised: props.hyperLinkInfo?.authorised,
  });
  return (
    <div style={{ ...props.style, overflow: 'hidden' }}>
      <GrowTypography.Text ellipsis={{ tooltip: formattedName }} style={{ maxWidth: '300px' }}>
        {DisplayAuthorisedName}
      </GrowTypography.Text>
    </div>
  );
}
