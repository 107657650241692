import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { SortOrder, StatusCellConfig } from '@sinecycle/growcomponents';
import { ApprovalType } from 'components/Settings/WorkFlowAutomation/CustomAutomations/Action/types';
import { EVENT_TRIGGER_CONDITION_KEYS } from 'components/Settings/WorkFlowAutomation/WorkflowCardList/type';
import { AttachmentDTO } from 'types/common/attachments';
import { RequestConfig } from 'types/common/common';
import { EventContext } from 'types/common/events';
import { AccountDetails } from 'types/entities/account';
import { User } from 'types/entities/user';

const approvalStatus = ['APPROVED', 'REJECTED', 'PENDING'] as const;
export type UserApprovalStatus = (typeof approvalStatus)[number];
const snapshotattachmenttypes = ['DOCUMENT', 'INVOICE_PDF'] as const;

export type ApprovalRequestAttachmentType = (typeof snapshotattachmenttypes)[number];
export const userApprovalStatusConfig: Record<
  UserApprovalStatus,
  { icon: IconProp; statusConfig: StatusCellConfig }
> = {
  APPROVED: {
    icon: ['far', 'thumbs-up'],
    statusConfig: {
      label: 'Approved',
      style: {
        backgroundColor: 'var(--green-1)',
        textColor: 'var(--green-8)',
      },
    },
  },
  PENDING: {
    icon: ['far', 'exclamation-circle'],
    statusConfig: {
      label: 'Pending',
      style: {
        backgroundColor: 'var(--orange-1)',
        textColor: 'var(--orange-8)',
      },
    },
  },
  REJECTED: {
    icon: ['far', 'thumbs-down'],
    statusConfig: {
      label: 'Rejected',
      style: {
        backgroundColor: 'var(--red-1)',
        textColor: 'var(--red-7)',
      },
    },
  },
};

export function getUserApprovalStatusConfig(status?: UserApprovalStatus) {
  if (!status) return;
  return userApprovalStatusConfig[status];
}
export interface ApprovalRequestFilterDTO {
  user_approval_status_list: Array<UserApprovalStatus>;
  assignee_list: Array<number>;
  created_by_wokflow_id: Array<number>;
  sort_col: string;
  order: SortOrder;
}
export interface ApprovalRequestLVDTO {
  id: number;
  account_id: number;
  type: ApprovalType;
  entity_id: number;
  created_at?: string;
  updated_at?: string;
  entity_type: EVENT_TRIGGER_CONDITION_KEYS;
  created_by_workflow?: WorkflowBasicDetailDTO;
}

export interface ApprovalRequestReportDTO {
  list: Array<UserApprovalRequestMapLVDTO>;
  current_page: number;
  total_pages: number;
  total_records: number;
}

export interface UserApprovalRequestMapLVDTO {
  id: number;
  account_id: number;
  user_id: number;
  status: UserApprovalStatus;
  approval_request: ApprovalRequestLVDTO;
  created_at?: string;
  updated_at?: string;
  comment?: string;
  approved_by_user_id?: number;
}

export interface ApprovalRequestConfig extends RequestConfig<ApprovalRequestFilterDTO> {}

export interface WorkflowBasicDetailDTO {
  id: number;
  title: string;
  enabled: boolean;
  owner_id: number;
}

export interface UserApprovalRequestMapDVDTO {
  id: number;
  account_id: number;
  comment?: string;
  user_id: number;
  status: UserApprovalStatus;
  created_at?: string;
  updated_at?: string;
  approval_request: ApprovalRequestDVDTO;
  approved_by_user_id?: number;
  can_respond?: boolean;
}
export type ApprovalAttachments<T extends ApprovalRequestAttachmentType> = {
  [key in T]: Array<AttachmentDTO>;
};
export interface ApprovalRequestDVDTO {
  id: number;
  account_id: number;
  type: ApprovalType;
  entity_type: EVENT_TRIGGER_CONDITION_KEYS;
  entity_id: number;
  created_at?: string;
  updated_at?: string;
  status: UserApprovalStatus;
  entity_snapshot?: SnapShotEventDTO;
  created_by_workflow?: WorkflowBasicDetailDTO;
  attachments?: ApprovalAttachments<ApprovalRequestAttachmentType>;
}

export type SnapShotEventBase<T extends EVENT_TRIGGER_CONDITION_KEYS> = {
  entity_type: T;
  event_date: string;
  id: string;
  context: EventContext;
};
export type SnapShotEventDTO = SnapShotEventBase<'INVOICE'> | SnapShotEventBase<'CUSTOMER'>;

export interface UserApprovalRequestMapAnonDTO {
  id: number;
  created_at?: string;
  updated_at?: string;
  approval_request: ApprovalRequestDVDTO;
  status: UserApprovalStatus;
  account: AccountDetails;
  user: User;
  current_user: User;
}

export interface UserApprovalRequestUpdateDTO {
  comment?: string;
  status: UserApprovalStatus;
}
