import { App as AntdApp, ConfigProvider } from 'antd';
import useTheme from 'hooks/use-theme';
import { Suspense } from 'react';
import { PageSuspenseFallback } from 'router/PageContainer';
import { UnauthAppRoutes } from 'router/Router';

function UnauthApp() {
  const { config } = useTheme();
  return (
    <ConfigProvider theme={config}>
      <Suspense fallback={<PageSuspenseFallback />}>
        <AntdApp>
          <UnauthAppRoutes />
        </AntdApp>
      </Suspense>
    </ConfigProvider>
  );
}

export default UnauthApp;
