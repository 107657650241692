import { Dropdown } from 'antd';
import { useIsApprover } from 'components/Approvals/hooks';
import { UserAvatar } from 'components/HigherOrderComponent/UserAvatar';

import { logoutThunk } from 'controllers/authentication';
import { COMMON_EVENT } from 'events/common';
import { Sentry } from 'lib/error-monitor/sentry';
import HeapWrapper from 'lib/heap/heap-wrapper';
import { dispatchAppEvent } from 'lib/pub-sub';
import { usePageNavigate } from 'lib/router';
import { useDispatch } from 'react-redux';
import { User } from 'types/entities/user';

const t = {
  userDetails: 'User Details',
  signOut: 'Sign out',
};

interface AccountMenuProps {
  user: User;
}
export function AccountMenu(props: AccountMenuProps) {
  const navigate = usePageNavigate();
  const dispatch = useDispatch();

  const { isApprover } = useIsApprover();
  const { user } = props;

  function logout() {
    sessionStorage.clear();
    dispatch(logoutThunk());
    dispatchAppEvent({ type: COMMON_EVENT.LOG_OUT });
    window.FreshworksWidget('logout');
    HeapWrapper.clearEventProperties();
    Sentry.logout();
  }

  const items = [
    {
      key: 1,
      label: t.userDetails,
      onClick: () => {
        navigate({ appPage: 'ACCOUNT_DETAILS' });
      },
    },
    {
      key: 2,
      label: t.signOut,
      onClick: logout,
    },
  ];

  return (
    <Dropdown
      menu={{
        items: isApprover
          ? [
              {
                key: 2,
                label: t.signOut,
                onClick: logout,
              },
            ]
          : items,
      }}
      className="cursor-pointer dropdown-trigger rounded "
    >
      <UserAvatar
        name={[user.first_name, user.last_name ?? '']}
        shape="square"
        style={{ marginTop: 0 }}
      />
    </Dropdown>
  );
}
