import { Flex } from 'components/BaseComponents/Layout/Flex';
import {
  CreatedBlock,
  CustomerBlock,
  LabelBlock,
} from 'components/CollectionActivities/Common/Details/BodyBlocks';
import { InfoContent } from 'components/CollectionActivities/Common/Details/Content';
import { ActivityDetailsBase } from 'types/activities/activity-types';
import { WriteOff } from 'types/activities/write-off';

interface WriteOffDetailsInfoProps extends ActivityDetailsBase {
  data?: WriteOff;
  loading: boolean;
}

function WriteOffDetailsInfo(props: WriteOffDetailsInfoProps) {
  const { data, loading, customer } = props;

  const Created =
    data && !loading ? (
      <CreatedBlock createdBy={data.created_by} createdDate={data.created_date} />
    ) : null;
  const Customer =
    customer && !loading ? (
      <CustomerBlock id={customer.id} name={customer?.name} customerNumber={customer.customer_no} />
    ) : null;

  return (
    <Flex direction="column" gap="var(--space-16)">
      <InfoContent content={Created} label={<LabelBlock label="Created" color="var(--gray-7)" />} />
      <InfoContent
        content={Customer}
        label={<LabelBlock label="Customer" color="var(--gray-7)" />}
      />
    </Flex>
  );
}

export { WriteOffDetailsInfo };
