import { Flex } from 'components/BaseComponents/Layout/Flex';
import {
  CreatedBlock,
  CustomerBlock,
  LabelBlock,
} from 'components/CollectionActivities/Common/Details/BodyBlocks';
import { InfoContent } from 'components/CollectionActivities/Common/Details/Content';
import { ActivityDetailsBase } from 'types/activities/activity-types';
import { Note } from 'types/activities/note';

interface NoteDetailsInfoProps extends ActivityDetailsBase {
  data?: Note;
  loading: boolean;
}

function NoteDetailsInfo(props: NoteDetailsInfoProps) {
  const { data, loading } = props;

  const Created =
    data && !loading ? (
      <CreatedBlock createdBy={data.created_by} createdDate={data.created_date} />
    ) : null;
  const Customer =
    props.customer && !loading ? (
      <CustomerBlock
        id={props.customer.id}
        name={props.customer.name}
        customerNumber={props.customer.customer_no}
      />
    ) : null;

  return (
    <Flex direction="column" gap="var(--space-16)">
      <InfoContent content={Created} label={<LabelBlock label="Created" color="var(--gray-7)" />} />
      <InfoContent
        content={Customer}
        label={<LabelBlock label="Customer" color="var(--gray-7)" />}
      />
    </Flex>
  );
}

export { NoteDetailsInfo };
