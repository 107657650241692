import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useIsApprover } from 'components/Approvals/hooks';
import { useConfig } from 'components/HigherOrderComponent/Config/config';
import { usePrivilegeStatus } from 'components/HigherOrderComponent/Privileged';
import { useSelector } from 'react-redux';
import { pagePath, pagePrivileges } from 'router/constants/page-info';
import { strategyV2EnabledSelector } from 'store/authentication/authentication';
import { AccountConfigKey } from 'types/entities/account';
import { AppSubRoute } from '../sidebar.components';
import { PageListItems } from './usePageList';

function useAutomations() {
  const strategyV2Enabled = useSelector(strategyV2EnabledSelector);
  const { isApprover, hasOtherPrivileges } = useIsApprover();
  const hasWorkFlowAccess = usePrivilegeStatus(pagePrivileges('WORKFLOW_AUTOMATIONS'), {
    shallowCheck: true,
  });
  const hasStartegyAssignement = usePrivilegeStatus(pagePrivileges('STRATEGY_ASSIGNMENT'));
  const enabled = useConfig(AccountConfigKey.WORKFLOW_ENABLED);
  const hasApprovalAccess = usePrivilegeStatus(pagePrivileges('APPROVALS'));
  const haAutomationsAccess = usePrivilegeStatus(pagePrivileges('AUTOMATIONS'), {
    shallowCheck: true,
  });
  const assignOwnerAccess = usePrivilegeStatus(pagePrivileges('OWNER_ASSIGNMENT'));
  const hasStrategy = usePrivilegeStatus(
    strategyV2Enabled
      ? pagePrivileges('COLLECTION_STRATEGIES')
      : pagePrivileges('COLLECTION_FOLLOWUPS'),
    {
      shallowCheck: true,
    }
  );
  function handleCollectionsSubLink() {
    const subLinks: AppSubRoute[] = [];

    if (hasWorkFlowAccess && enabled) {
      subLinks.push({
        label: 'Workflows',
        url: pagePath('WORKFLOW_AUTOMATIONS'),
      });
    }
    if (hasApprovalAccess) {
      subLinks.push({
        label: 'Approvals',
        url: pagePath('APPROVALS'),
      });
    }

    return subLinks;
  }
  function handleGenrealSublinks() {
    const subLinks: AppSubRoute[] = [];
    if (hasStrategy) {
      subLinks.push({
        label: 'Collection Strategy',
        url: strategyV2Enabled
          ? pagePath('COLLECTION_STRATEGIES')
          : pagePath('COLLECTION_FOLLOWUPS'),
      });
    }
    if (hasStartegyAssignement) {
      subLinks.push({
        label: 'Assign Collection Strategy',
        url: pagePath('STRATEGY_ASSIGNMENT'),
      });
    }

    if (assignOwnerAccess) {
      subLinks.push({
        label: 'Assign Collection Owner',
        url: pagePath('OWNER_ASSIGNMENT'),
      });
    }
    return subLinks;
  }

  const automationsSubLinks: AppSubRoute[] = handleCollectionsSubLink();
  const generalSublinks: AppSubRoute[] = handleGenrealSublinks();

  //commentingout for future use
  // const groups = [
  //   { key: 'Genreal', label: 'General', type: 'Group', sublinks: generalSublinks },
  //   {
  //     key: 'WORKLOW',
  //     label: 'Workflow Automations',
  //     type: 'Group',
  //     sublinks: automationsSubLinks,
  //   },
  // ];

  // const nonlinks = !generalSublinks.length && !automationsSubLinks.length;
  // function getGroupLinks() {
  //   if (!generalSublinks.length || !automationsSubLinks.length) {
  //     const excludeKey = generalSublinks.length ? 'WORKLOW' : 'Genreal';
  //     return groups.filter((group) => group.key !== excludeKey);
  //   }
  //   return groups;
  // }

  // const grouplinks = getGroupLinks();
  // const automationPage: Array<PageListItems> = nonlinks
  //   ? []
  //   : [
  //       {
  //         key: 'AUTOMATIONS',
  //         subLinks: [...generalSublinks, ...automationsSubLinks],
  //         label: isApprover ? (hasOtherPrivileges ? 'Automations' : 'Approvals') : 'Automations',
  //         access: haAutomationsAccess,
  //         icon: <FontAwesomeIcon icon={['far', 'diagram-project']} fontSize={20} />,
  //       },
  //     ];

  const automationPage: Array<PageListItems> = [
    {
      key: 'AUTOMATIONS',
      subLinks: [...generalSublinks, ...automationsSubLinks],
      label: isApprover ? (hasOtherPrivileges ? 'Automations' : 'Approvals') : 'Automations',
      access: haAutomationsAccess,
      icon: <FontAwesomeIcon icon={['far', 'diagram-project']} fontSize={20} />,
    },
  ];

  return { automationPage, automationsSubLink2: generalSublinks };
}

export default useAutomations;
