import { ParticipantRelationKeys } from 'store/contacts/contacts';
import { ParentContactType, PocUserType } from '../contact';

export enum BaseRecepientsType {
  USER = 'USER',
  POC = 'POC',
  CONTACT_TYPE = 'CONTACT_TYPE',
  AR_EMAIL = 'AR_EMAIL',
  APPROVER_CF = 'APPROVER_CF',
  APPROVER_CONSTANT = 'APPROVER_CONSTANT',
}

interface BaseContact {
  type: BaseRecepientsType;
  association_level?: 'INVOICE' | 'CUSTOMER';
}
export interface UserContact extends BaseContact {
  type: BaseRecepientsType.USER;
  id: number;
}
export interface ApproverCFContact extends BaseContact {
  type: BaseRecepientsType.APPROVER_CF;
  id: number;
}
export interface ApproverContactContact extends BaseContact {
  type: BaseRecepientsType.APPROVER_CONSTANT;
  email: string;
}
export interface PocContact extends BaseContact {
  type: BaseRecepientsType.POC;
  value: PocUserType;
}
export interface CustomerContact extends BaseContact {
  type: BaseRecepientsType.CONTACT_TYPE;
  value: string;
  participant_relation?: ParticipantRelationKeys.SELF;
  contact_type_id?: number;
  contact_type_identifier?: 'INVOICE' | 'CUSTOMER';
}

export interface CustomerParentContact extends BaseContact {
  type: BaseRecepientsType.CONTACT_TYPE;
  value: ParentContactType;
  participant_relation?: ParticipantRelationKeys.PARENT;
  contact_type_id?: number;
  contact_type_identifier?: 'INVOICE' | 'CUSTOMER';
}
export interface ArEmailContact extends Omit<UserContact, 'type'> {
  type: BaseRecepientsType.AR_EMAIL;
}

export type RelativeContact =
  | UserContact
  | PocContact
  | CustomerContact
  | CustomerParentContact
  | ArEmailContact;
