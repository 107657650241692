import { ColGroupProps, ColProps, GridColumnProps } from '@sinecycle/growcomponents';
import { CustomerSearch } from 'components/BaseComponents/Filters/CustomerSearch';
import { getPersistedColumnGroupPredicate } from 'components/BaseComponents/GrowTable/utils';
import { ClearRegular, SearchIconRegular } from 'components/Common/Icons/Icons';
import { useConfig } from 'components/HigherOrderComponent/Config/config';
import { useDispatch, useSelector } from 'react-redux';
import { dateFormatSelector } from 'store/authentication/authentication';
import { IInvoices } from 'store/customer/types';
import { updateOpenInvoiceCurrentFilters } from 'store/invoice-v2/invoice';
import { OpenInvoiceListSummary } from 'types/api/invoice';
import { AccountConfigKey } from 'types/entities/account';
import { StatementViews } from 'types/entities/invoice';
import { OpenInvoiceFilterParams } from 'types/filter-view/open-invoice';
import { getCurrencySymbol } from 'util/number-formatter';
import CollectionPeriods from '../../InvoiceStatements/Columns/CollectionPeriod';
import EmailDeliveryStatus from '../../InvoiceStatements/Columns/EmailDeliveryStatus';
import {
  CustomerColumn,
  DueDate,
  DueDays,
  FollowUpActionColumn,
  InvoiceStatusColumn,
  InvoiceSubsidiaryColumn,
  InvoicesColumn,
  IssueDateColumn,
  LastActivityColumn,
} from '../../InvoiceStatements/Columns/invoceStatementColumns';
import AmountCell, {
  AmountCellCustomerCurrency,
  AmountTotalCell,
  AmountTotalCustomerCurrency,
} from './Amount/AmountCell';
import { AmountHeader } from './Amount/AmountHeader';

function useColumn(
  summary?: OpenInvoiceListSummary,
  isFromInvoiceList?: boolean,
  bulkRecords?: number,
  page?: number,
  filter?: OpenInvoiceFilterParams
) {
  const format = useSelector(dateFormatSelector);
  const isMulticurrencyEnabled = useConfig(AccountConfigKey.MULTI_CURRENCY_ENABLED);
  const isSubsidiaryEnabled = useConfig(AccountConfigKey.SUBSIDIARY_ENABLED);
  const isWorkflowEnabled = useConfig(AccountConfigKey.WORKFLOW_ENABLED);
  const bulkRecordsAction = bulkRecords && bulkRecords <= 200;
  const dispatch = useDispatch();

  const isParentChildEnabled = useConfig(AccountConfigKey.PARENT_CHILD_ENABLED);
  const childCustomerColumnHideCondition = !isParentChildEnabled || isFromInvoiceList;
  const columns: GridColumnProps<IInvoices> = [
    {
      editable: false,
      cellRenderer: 'cellSelection',
      pinned: 'left',
      lockPosition: true,
      colId: 'CELL_SELECTION',
      resizable: false,
      sortable: false,
      width: 50,
      headerComponent: 'headerCheckBox',
      headerComponentParams: {
        bulkRecords,
        limitBulkAction: true,
      },
      cellStyle: {
        marginLeft: 'var(--space-16)',
        padding: 0,
      },
      lockVisible: true,
      minWidth: 50,
      headerClass: bulkRecordsAction
        ? 'aggrid-bulk-header-cell-selected'
        : 'aggrid-header-cell-selected',
    },
    {
      ...InvoicesColumn,
      pinned: 'left',
      headerComponentParams: {
        ...InvoicesColumn.headerComponentParams,
        bulkRecords,
      },
      cellRendererParams: {
        filters: filter,
        invoiceType: 'open',
        currentPage: page,
        isFromInvoiceList,
      },
    },
    {
      colId: 'CHILD_CUSTOMER',
      headerName: 'Child Customer',
      cellRenderer: 'textWithLinkCell',
      sortable: false,
      cellClass: 'ag-center-aligned-cell',
      headerClass: 'ag-center-aligned-header',
      initialHide: childCustomerColumnHideCondition,
      lockVisible: childCustomerColumnHideCondition,
      valueGetter: (param) => {
        return {
          name: param.data?.customer_name,
          id: param.data?.customer_id,
          authorised: true,
          customer_no: param.data?.customer_no,
        };
      },
    },
    {
      ...CustomerColumn,
      cellRendererParams: {
        currentView: StatementViews.OPEN_INVOICES,
        columnCustom: {
          type: 'DEFAULT',
        },
      },
      headerComponent: 'quickFilterHeader',
      headerComponentParams: {
        quickFilter: {
          filterIcon: <SearchIconRegular size="sm" width={12} />,
          clearIcon: <ClearRegular size="sm" width={12} />,
          filterComponent: (
            <CustomerSearch
              value={filter?.customer_ids}
              onSubmit={(value) => {
                dispatch(
                  updateOpenInvoiceCurrentFilters({
                    ...filter,
                    customer_ids: value,
                  } as OpenInvoiceFilterParams)
                );
              }}
            />
          ),
        },
      },
      initialHide: !isFromInvoiceList,
      lockVisible: !isFromInvoiceList,
    },
    {
      ...IssueDateColumn,
      cellRendererParams: {
        ...IssueDateColumn.cellRendererParams,
        dateCellProps: {
          format,
        },
      },
    },
    {
      headerName: 'Transaction Currency',
      colId: 'DOCUMENT_KEY',
      field: 'document_currency',
      cellRenderer: 'textCell',
      sortable: false,
      valueGetter: (params) => {
        return `${params.data?.currency}(${getCurrencySymbol(params.data?.currency)})`;
      },
    },
    {
      headerName: 'Invoice Amount',
      groupId: 'BALANCE_DUE_TOTAL_AMOUNT',
      headerClass: isMulticurrencyEnabled ? 'gf-group-header-center' : 'gf-group-header-right',
      children: [
        {
          headerName: 'Transaction Currency',
          sortable: false,
          cellRenderer: AmountTotalCustomerCurrency,
          cellClass: 'ag-right-aligned-cell',
          headerClass: 'ag-right-aligned-header',
          initialHide: !isMulticurrencyEnabled,
          colId: 'BALANCE_DUE_TOTAL_AMOUNT_CURRENCY_CUSTOMER',
        },
        {
          headerName: 'Base Currency',
          sortable: false,
          cellRenderer: AmountTotalCell,
          cellClass: 'ag-right-aligned-cell',
          headerClass: 'ag-right-aligned-header',
          colId: 'BALANCE_DUE_TOTAL_AMOUNT_CURRENCY_BASE',
        },
      ],
      cellRendererParams: {
        columnCustom: {
          type: 'DEFAULT',
        },
      },
    },
    {
      headerName: 'Invoice Due Amount',
      groupId: 'BALANCE_DUE_AMOUNT',
      headerGroupComponent: AmountHeader,
      headerGroupComponentParams: {
        summary,
      },
      children: [
        {
          colId: 'DOCUMENT_CURRENCY',
          headerName: 'Transaction Currency',
          sortable: false,
          cellRenderer: AmountCellCustomerCurrency,
          cellClass: 'ag-right-aligned-cell',
          headerClass: 'ag-right-aligned-header',
          initialHide: !isMulticurrencyEnabled,
        },
        {
          colId: 'BALANCE_DUE_AMOUNT_CURRENCY',
          headerName: 'Base Currency',
          cellRenderer: AmountCell,
          cellClass: 'ag-right-aligned-cell',
          headerClass: 'ag-right-aligned-header',
          sortable: true,
          initialWidth: isMulticurrencyEnabled ? undefined : 250,
        },
      ],
      initialHide: !isMulticurrencyEnabled,
      cellRendererParams: {
        columnCustom: {
          type: 'DEFAULT',
        },
      },
    },

    { ...DueDate },
    { ...DueDays },
    {
      headerName: 'Predicted Pay Date',
      colId: 'PREDICTED_PAY_DATE',
      field: 'predicted_pay_date',
      cellRenderer: 'dateCell',
      cellRendererParams: {
        dateCellProps: {
          format,
        },
      },
    },

    { ...FollowUpActionColumn },
    { ...InvoiceStatusColumn },
    {
      ...InvoiceSubsidiaryColumn,
      initialHide: !isSubsidiaryEnabled,
      lockVisible: !isSubsidiaryEnabled,
    },

    {
      headerName: 'PTP Date',
      field: 'promise_to_pay_date',
      cellRenderer: 'dateCell',
      sortable: false,
      colId: 'PROMISE_TO_PAY_DATE',
      cellRendererParams: {
        dateCellProps: {
          format,
        },
      },
    },
    {
      sortable: false,
      headerName: 'Collection Period',
      field: 'collection_periods',
      cellRenderer: CollectionPeriods,
      width: 250,
      colId: 'COLLECTION_PERIODS',
    },
    {
      ...LastActivityColumn,
    },
    {
      headerName: 'Invoice Delivery Status',
      sortable: false,
      field: 'email_delivery_status',
      cellRenderer: EmailDeliveryStatus,
      initialHide: !isWorkflowEnabled,
      colId: 'EMAIL_DELIVERY_STATUS',
      lockVisible: !isWorkflowEnabled,
    },
  ];

  const column = columns.map((col) => {
    return getPersistedColumnGroupPredicate<IInvoices>(
      col as ColProps<IInvoices> & ColGroupProps<IInvoices>,
      filter?.sort_col,
      filter?.sort_by,
      filter?.sort_by_custom_field
    );
  });
  return { column };
}

export { useColumn };
