import { Col, Row } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { GrowText } from 'components/BaseComponents/Typography';
import ActivityCardActions from 'components/CollectionActivities/Common/Card/ActivityCardActions';
import ActivityCardHeader from 'components/CollectionActivities/Common/Card/ActivityCardHeader';
import { LatestActivityFooter } from 'components/CollectionActivities/Common/LatestActivity';
import { InboxActivityCardProps } from 'components/CollectionActivities/types';
import { getActivityUnitItems } from 'components/CollectionActivities/utils';
import { Amount } from 'components/Common/MultiCurrency';
import { getPaymentMode } from 'components/Common/Util/paymentUtil';
import { CustomerAndInvoice } from 'components/Inbox/CollectionActivitiesV2/Activities/CustomerAndInvoice';
import { ToggleActivityReadButton } from 'components/Inbox/CollectionActivitiesV2/Activities/ToggleActivityReadButton';
import { ActivityType } from 'types/activities/activity-types';

interface PaymentCardProps extends InboxActivityCardProps<ActivityType.PAYMENT> {}

const { cardLabel } = getActivityUnitItems(ActivityType.PAYMENT);

function CardHeader({ payment, viewMode, activitiesMode, activityBg }: PaymentCardProps) {
  const MarkAsRead = (
    <ToggleActivityReadButton
      activity={payment}
      viewMode={viewMode}
      hideDot={activitiesMode}
      css={activityBg}
    />
  );

  const Title = (
    <GrowText color="var(--gray-8)" strong={!payment.read}>
      {cardLabel}
    </GrowText>
  );
  const Heading = (
    <Flex gap="var(--space-4)" align="center">
      {MarkAsRead}
      {Title}
    </Flex>
  );
  return (
    <ActivityCardHeader activitiesMode={activitiesMode}>
      {Heading}
      <ActivityCardActions activity={payment} />
    </ActivityCardHeader>
  );
}

function CardBody({ payment, activitiesMode }: PaymentCardProps) {
  const Details = (
    <CustomerAndInvoice
      customer={payment.customer}
      invoices={payment.invoices}
      activitiesMode={activitiesMode}
    />
  );

  const PaymentAmount = (
    <Amount amount={payment.entity_detail.amount} currency={payment.entity_detail.currency} />
  );

  const Body = (
    <GrowText ellipsis strong={!payment.read} size="var(--fs-16)">
      Payment {getPaymentMode({ mode: payment.entity_detail.mode, addViaText: true })} -{' '}
      {PaymentAmount}
    </GrowText>
  );

  return (
    <Row gutter={[0, 6]}>
      <Col span={24}> {Body}</Col>
      <Col span={24}> {Details}</Col>
    </Row>
  );
}

function CardFooter({ payment }: PaymentCardProps) {
  return <LatestActivityFooter latestActivity={payment.latest_activity} />;
}

const PaymentCard = () => <></>;

PaymentCard.Header = CardHeader;
PaymentCard.Body = CardBody;
PaymentCard.Footer = CardFooter;

export { PaymentCard };
