import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GrowTypography } from '@sinecycle/growcomponents';
import { ActivityIconButton } from 'components/Inbox/CollectionActivitiesV2/Activities/style';

const Anchorlink = (
  <FontAwesomeIcon icon={['far', 'link']} color={'var(--primary-7)'} fontSize={12} />
);
interface ApprovalTitleProps {
  title: string;
}

const CopyableData = {
  icon: Anchorlink,
  tooltips: 'Copy link',
};
function ApprovalTitle(props: ApprovalTitleProps) {
  const { title } = props;
  return (
    <div className="tw-flex tw-items-center tw-gap-8">
      <ActivityIconButton
        icon={<FontAwesomeIcon icon={['far', 'thumbs-up']} color="var(--gray-7)" />}
        size="small"
        css={{ '--activityBg': 'var(--gray-2)' }}
        viewMode
        hideBackground
      />
      <GrowTypography.Text fs="20" strong>
        {title}
      </GrowTypography.Text>
      <GrowTypography.Text copyable={{ ...CopyableData, text: window.location.href }} />
    </div>
  );
}

export { ApprovalTitle };
