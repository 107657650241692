import { themeConfig } from '@sinecycle/growcomponents';
import { ThemeConfig } from 'antd';
import { useLayoutEffect } from 'react';
import { IS_DEMO_ZUROA } from 'util/http-utils';

function useTheme(): { config: ThemeConfig } {
  useLayoutEffect(() => {
    if (!IS_DEMO_ZUROA) return;

    const styles = {
      '--primary-1': '#ebf2ff',
      '--primary-2': '#bacff5',
      '--primary-3': '#8ba9e8',
      '--primary-4': '#6083db',
      '--primary-5': '#3a5fcf',
      '--primary-6': '#0b259c',
      '--primary-7': '#173DC2',
      '--primary-8': '#3a5fcf',
      '--primary-9': '#173dc2',
      '--primary-10': '#000000',
      '--primary1': '#ebf2ff',
      '--primary2': '#bacff5',
      '--primary3': '#8ba9e8',
      '--primary4': '#6083db',
      '--primary5': '#3a5fcf',
      '--primary6': '#0b259c',
      '--primary7': '#173DC2',
      '--primary8': '#3a5fcf',
      '--primary9': '#173dc2',
      '--primary10': '#000000',
      '--purple-7': '#173DC2',
      '--purple-8': '#173DC2',
      '--side-bar-active': '#00D2B9',
      '--side-bar-active-icon': '#00D2B9',
      '--geekblue-1': '#f1f5f8',
      '--volcano-6': '#ff4635',
    };

    Object.entries(styles).forEach(([property, value]) => {
      document.documentElement.style.setProperty(property, value);
    });
  }, []);
  const defaultTheme: ThemeConfig = {
    ...themeConfig,
    components: {
      ...themeConfig.components,
      Menu: {
        activeBarHeight: 32,
        itemHeight: 32,
        itemBorderRadius: 4,
        darkItemBg: '#120338',
        darkItemSelectedBg: '#391085',
      },
      Descriptions: {
        itemPaddingBottom: 4,
        titleColor: '#8c8c8c',
      },
      Select: {},
    },
  };
  const zuoraTheme: ThemeConfig = {
    ...themeConfig,
    token: {
      ...defaultTheme.token,
      colorPrimary: '#173DC2',
      colorLink: '#173DC2',
    },
    components: {
      ...defaultTheme.components,
      Button: {
        ...defaultTheme.components?.Button,
        colorText: '#1D4CBC',
      },
      Layout: {
        siderBg: '#103426',
      },
      Menu: {
        ...defaultTheme.components?.Menu,
        darkItemBg: '#103426',
        darkItemSelectedBg: '#00D2B9',
        itemSelectedBg: '#ebf2ff',
        itemActiveBg: '#ebf2ff',
        itemHoverBg: '#ebf2ff',
      },
      Segmented: {
        itemActiveBg: '#bacff5',
        itemHoverBg: '#ebf2ff',
        itemSelectedBg: '#bacff5',
        itemSelectedColor: '#173DC2',
      },
      Typography: {
        ...defaultTheme.components?.Typography,
        colorLink: '#173DC2',
        colorLinkHover: '#173DC2',
        colorText: '#000000',
      },
      Select: {
        optionSelectedBg: '#ebf2ff',
      },
      Dropdown: {
        controlItemBgActive: '#ebf2ff',
      },
      Tree: {
        nodeSelectedBg: '#ebf2ff',
      },
    },
  };
  const config = IS_DEMO_ZUROA ? zuoraTheme : defaultTheme;
  return { config };
}

export default useTheme;
