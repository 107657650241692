import { Col, Row } from 'antd';
import { FilePreview } from 'components/BaseComponents/UploadFiles/FilePreview';
import { get, isEmpty, map, mapValues } from 'lodash';
import { ApprovalRequestDVDTO } from 'pages/Approvals/type';
import { InvoicePDF } from './InvoicePDF';

interface ApprovalDocumentsProps {
  attachments?: ApprovalRequestDVDTO['attachments'];
  isAnonymous?: boolean;
  hash?: string;
}

function ApprovalDocuments(props: ApprovalDocumentsProps) {
  const { attachments, isAnonymous, hash } = props;

  if (!attachments || isEmpty(attachments)) return <div>-</div>;

  const transformedData = mapValues(attachments, (attachments) => ({
    attachments,
  }));

  const invoicePDfs = get(transformedData, 'INVOICE_PDF')?.attachments;
  const documents = get(transformedData, 'DOCUMENT')?.attachments;
  const invoices = map(invoicePDfs, (invoice) => {
    return {
      name: invoice.file_name,
      id: invoice.id,
      version: invoice.version_id,
    };
  });
  return (
    <Row gutter={[8, 8]} wrap>
      <Col>
        <InvoicePDF
          invoices={invoices}
          text="Invoices"
          isAnonymousymous={isAnonymous}
          hash={hash}
        />
      </Col>

      <>
        {documents?.map((document) => {
          return (
            <Col>
              <FilePreview
                fileId={document.id}
                fileName={document.file_name}
                fileType={document.file_type}
                isAnonymous={isAnonymous}
                showMessage
                hash={hash}
                version={document?.version_id}
              />
            </Col>
          );
        })}
      </>
    </Row>
  );
}

export { ApprovalDocuments };
