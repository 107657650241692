import { GrowFlex } from '@sinecycle/growcomponents';
import { Alert, Col, Row } from 'antd';
import { growfinFullLogo, signupBanner } from 'assets/image-urls';
import { useIsApprover } from 'components/Approvals/hooks';
import { Spacer } from 'components/BaseComponents/Layout/Spacer';
import { Outlet, PageNavigate, useLocation } from 'lib/router';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AppLoadder } from 'router/AppLoader';
import { pagePath } from 'router/constants/page-info';
import { appInitializationStateSelector } from 'store/authentication/authentication';
import { BannerCol, BannerText, LoginContainer, PoweredByText, StAlert } from './style';

type LoginLocationState = {
  error?: string;
};

const publicLayout: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  width: '70vw',
  alignItems: 'center',
  overflow: 'auto',
  height: '100vh',
  justifyContent: 'center',
};
const t = {
  warning_info: 'Please login to verify AR Email',
};

export default function PublicRouteLayout() {
  const [error, setError] = useState('');
  const location = useLocation();
  const { authentication, loading } = useSelector(appInitializationStateSelector);
  const { isApprover } = useIsApprover();
  const isVerify = window.location.search.includes('verify_hash');

  useEffect(() => {
    const errMsg = (location.state as LoginLocationState)?.error;
    errMsg && setError(errMsg);
  }, [location.state]);

  if (authentication === 'authenticated') {
    return <PageNavigate to={isApprover ? pagePath('APPROVALS') : pagePath('DASHBOARD')} />;
  }

  if (loading || authentication === 'authenticating') {
    return <AppLoadder />;
  }

  return (
    <LoginContainer style={{ minHeight: '100vh' }}>
      {error && (
        <StAlert message={error} type="warning" showIcon closable style={{ zIndex: '2' }} />
      )}
      <Row align="middle">
        <BannerCol>
          <BannerText level={2}>Integrated Accounts Receivable Management Solution</BannerText>
          <PoweredByText>Powered by</PoweredByText>
          <img
            src={growfinFullLogo}
            alt="Powered by Growfin"
            width={100}
            height="auto"
            style={{ margin: '0 4rem' }}
          />
          <img src={signupBanner} alt="Integrated Accounts Receivable Management Solution" />
        </BannerCol>

        <Col style={publicLayout}>
          <GrowFlex vertical space="24">
            {isVerify ? <Alert type="warning" message={t.warning_info} closable banner /> : null}
            <div style={{ width: 'min-content' }}>
              <img height="auto" width="120px" src={growfinFullLogo} alt="Growfin" />
              <Spacer axis="vertical" size="48" />
              <div style={{ width: '50%', minWidth: 420 }}>
                <Outlet />
              </div>
            </div>
          </GrowFlex>
        </Col>
      </Row>
    </LoginContainer>
  );
}
