import { Avatar } from 'antd';
import { AvatarSize } from 'antd/lib/avatar/AvatarContext';
import { Flex, FlexBoxChild } from 'components/BaseComponents/Layout/Flex';
import { GrowText } from 'components/BaseComponents/Typography/GrowText';
import { startCase } from 'lodash';
import React from 'react';
import { getInitials } from '../../../util/name-formatter';
import { UserAvatar } from '../UserAvatar';

const avatarStyle = {
  color: 'var(--primary-6)',
  backgroundColor: 'var(--primary-1)',
  fontSize: 'var(--fs-12)',
  marginRight: 'var(--space-4)',
};

interface LocalUserAvatarProps {
  customAvatarStyle?: React.CSSProperties;
  size?: AvatarSize;
  name?: string[];
}

function LocalUserAvatar({ customAvatarStyle, size, name }: LocalUserAvatarProps) {
  return (
    <Avatar style={{ ...avatarStyle, ...customAvatarStyle }} size={size as AvatarSize}>
      {getInitials(name?.filter((name) => name) ?? ['U', 'S'])}
    </Avatar>
  );
}

interface UserInfoAvatarProps {
  size?: string;
  title?: string | React.ReactChild;
  extra?: string | React.ReactChild | React.ReactNode;
  footNote?: React.ReactNode;
  dateTime?: React.ReactNode;
  name?: string | [string, string];
  hideImgAvatar?: boolean;
  hideUserInfo?: boolean;
  align?: 'center' | 'flex-start' | 'flex-end';
  style?: {
    userName?: React.CSSProperties;
    userNameTruncateWidth?: string;
    userTitle?: React.CSSProperties;
    avatarStyle?: React.CSSProperties;
    gapConfig?: {
      avatar?: string | undefined;
      userInfo?: string | undefined;
    };
  };
  className?: string;
  onClick?: () => void;
}

function UserInfoAvatar(props: UserInfoAvatarProps) {
  const [firstName, lastName] = Array.isArray(props.name) ? props.name : [props.name, ''];
  const name = startCase(`${firstName} ${lastName}`);
  const FirstName = firstName && (
    <GrowText
      ellipsis={{ tooltip: !!props?.style?.userNameTruncateWidth }}
      style={{
        ...props.style?.userName,
        maxWidth: props?.style?.userNameTruncateWidth
          ? props?.style?.userNameTruncateWidth
          : 'none',
      }}
      color={props.style?.userName?.color || 'var(--primary-7)'}
      size={'var(--fs-12)'}
    >
      {name}
    </GrowText>
  );

  const Title = props.title && (
    <GrowText
      style={props.style?.userTitle}
      color={'var(--gray-6)'}
      size="var(--fs-12)"
      className="user-title"
    >
      {`(${props.title})`}
    </GrowText>
  );

  const UserInfo = !props.hideUserInfo && (
    <Flex className="customer-name-title" gap="--space-4" align="center" style={{ flexGrow: 1 }}>
      {FirstName}
      {Title && <>&nbsp;{Title}</>}
      {props.extra && <>&nbsp;{props.extra}</>}
    </Flex>
  );

  const FootNote = props.footNote ?? null;

  return (
    <Flex
      style={{ height: '100%' }}
      className={props.className}
      align={props.align ?? 'center'}
      gap={props?.style?.gapConfig?.avatar ?? '--space-12'}
      onClick={props.onClick}
    >
      {!props.hideImgAvatar && (
        <FlexBoxChild>
          <UserAvatar
            name={[firstName ?? '', lastName ?? '']}
            style={props.style?.avatarStyle}
            size={props.size as AvatarSize}
          />
        </FlexBoxChild>
      )}
      <Flex
        direction="column"
        gap={props?.style?.gapConfig?.userInfo ?? '--space-8'}
        style={{ overflow: 'hidden' }}
      >
        <Flex direction="column">
          {UserInfo}
          {props.dateTime}
        </Flex>
        {FootNote}
      </Flex>
    </Flex>
  );
}

export default UserInfoAvatar;
export { LocalUserAvatar as UserAvatar };
