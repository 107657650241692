import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GrowTypography, StatusTag } from '@sinecycle/growcomponents';
import { Popover } from 'antd';
import { getUserApprovalStatusConfig, UserApprovalStatus } from 'pages/Approvals/type';
import { User } from 'types/entities/user';
import { OnBehalf } from './OnBehalf';

interface ApprovalStatusProps {
  status?: UserApprovalStatus;
  approvedUser?: User;
  comment?: string;
  actionTake?: boolean;
  assignedUser?: User;
}

export default function ApprovalStatus(props: ApprovalStatusProps) {
  const { status, comment, actionTake, approvedUser, assignedUser } = props;
  const config = getUserApprovalStatusConfig(status);

  const Text = (
    <>
      {config ? (
        <div className="tw-flex tw-items-center tw-gap-4">
          <FontAwesomeIcon icon={config?.icon} color={config?.statusConfig.style.textColor} />
          {config?.statusConfig.label}
        </div>
      ) : null}
    </>
  );
  const Title = (
    <div className="tw-flex tw-items-center tw-gap-4">
      <div>
        {config ? (
          <FontAwesomeIcon icon={config?.icon} color={config?.statusConfig.style.textColor} />
        ) : null}
      </div>
      <GrowTypography.Text fs="12">{`${approvedUser?.first_name ?? ''} ${
        approvedUser?.last_name ?? ''
      }`}</GrowTypography.Text>
    </div>
  );
  const Content = <GrowTypography.Text fs="12">{comment}</GrowTypography.Text>;

  const Onbehalf = <OnBehalf assignedUser={assignedUser} />;

  return (
    <Popover
      title={comment ? Title : undefined}
      placement="left"
      content={
        comment ? (
          <div>
            {Content}
            {actionTake ? Onbehalf : null}
          </div>
        ) : undefined
      }
    >
      <div>
        {config ? (
          <StatusTag
            text={Text}
            style={{
              borderColor: '',
              backgroundColor: config?.statusConfig.style.backgroundColor,
              textColor: config?.statusConfig.style.textColor,
            }}
          />
        ) : (
          '-'
        )}
      </div>
    </Popover>
  );
}
