import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GrowTypography } from '@sinecycle/growcomponents';
import { ApprovalAction } from 'components/Approvals/ApprovalAction';
import ApprovalStatus from 'components/Approvals/ApprovalStatus';
import { AssignedTo } from 'components/Approvals/AssignedTo';
import { DateBlock, LabelBlock } from 'components/CollectionActivities/Common/Details/BodyBlocks';
import { InfoContent } from 'components/CollectionActivities/Common/Details/Content';
import { UserApprovalRequestMapAnonDTO, UserApprovalRequestUpdateDTO } from 'pages/Approvals/type';
import { useUpdateAnonymousApprovalStatus } from 'queries/Approvals/approval';

interface ApprovalAnonInfoDetails {
  data?: UserApprovalRequestMapAnonDTO;
}

function ApprovalAnonInfo(props: ApprovalAnonInfoDetails) {
  const { data } = props;
  const { mutateAsync: update } = useUpdateAnonymousApprovalStatus();
  const currentUser = data?.current_user.id;
  const assignedId = data?.user.id;
  const isCurrentAssigneed = currentUser === assignedId;

  function handleSave(value: UserApprovalRequestUpdateDTO) {
    update(value);
  }
  const Action = (
    <>
      {data?.status === 'PENDING' ? (
        <div className="tw-flex tw-flex-col tw-gap-8 tw-w-full">
          <ApprovalAction status="APPROVED" onSubmit={handleSave} />
          <ApprovalAction status="REJECTED" onSubmit={handleSave} />
        </div>
      ) : null}
    </>
  );
  const Id = <GrowTypography.Text>{`AP${data?.id}`}</GrowTypography.Text>;
  const date = (
    <DateBlock
      dateString={data?.approval_request?.created_at ?? ''}
      format={data?.account.date_format}
    />
  );
  const Assigned = data?.user ? <AssignedTo user={data.user} /> : '-';
  const OnBehalf = (
    <div className="tw-p-8 tw-bg-geekblue-1 tw-rounded">
      <FontAwesomeIcon icon={['far', 'info-circle']} color="var(--blue-6)" />
      <div>
        <GrowTypography.Text>You can also act on behalf of</GrowTypography.Text>&nbsp;
        <GrowTypography.Text
          strong
        >{`${data?.user?.first_name} ${data?.user?.last_name}`}</GrowTypography.Text>
      </div>
    </div>
  );
  return (
    <div className="tw-flex tw-flex-col tw-gap-16 tw-w-full">
      {isCurrentAssigneed ? null : data?.status === 'PENDING' ? OnBehalf : null}
      <InfoContent content={Action} />
      <InfoContent
        label={<LabelBlock label="Approval Status" />}
        content={<ApprovalStatus status={data?.status} />}
      />
      <InfoContent label={<LabelBlock label="Assigned to" />} content={Assigned} />
      <InfoContent label={<LabelBlock label="Approval ID" />} content={Id} />
      <InfoContent label={<LabelBlock label="Created Date" />} content={date} />
    </div>
  );
}

export { ApprovalAnonInfo };
