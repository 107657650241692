import { ColGroupProps, ColProps, GridColumnProps, SortOrder } from '@sinecycle/growcomponents';
import { CustomerSearch } from 'components/BaseComponents/Filters/CustomerSearch';
import { getPersistedColumnPredicate } from 'components/BaseComponents/GrowTable/utils';
import { ClearRegular, SearchIconRegular } from 'components/Common/Icons/Icons';
import { useConfig } from 'components/HigherOrderComponent/Config/config';
import { useDispatch, useSelector } from 'react-redux';
import { dateFormatSelector } from 'store/authentication/authentication';
import { IInvoices } from 'store/customer/types';
import { updateClosedInvoiceCurrentFilters } from 'store/invoice-v2/invoice';
import { AccountConfigKey } from 'types/entities/account';
import { StatementViews } from 'types/entities/invoice';
import { ClosedInvoiceFilterParams } from 'types/filter-view/closed-invoice';
import {
  CustomerColumn,
  InvoiceStatusColumn,
  InvoiceSubsidiaryColumn,
  InvoicesColumn,
  IssueDateColumn,
} from '../../InvoiceStatements/Columns/invoceStatementColumns';
import ClosedInvoiceAmount from './Amount';

function useColumn(
  isFromInvoiceList?: boolean,
  bulkRecords?: number,
  page?: number,
  filter?: ClosedInvoiceFilterParams
) {
  const format = useSelector(dateFormatSelector);
  const isSubsidiaryEnabled = useConfig(AccountConfigKey.SUBSIDIARY_ENABLED);
  const isParentChildEnabled = useConfig(AccountConfigKey.PARENT_CHILD_ENABLED);
  const childCustomerColumnHideCondition = !isParentChildEnabled || isFromInvoiceList;
  const dispatch = useDispatch();
  const columns: GridColumnProps<IInvoices> = [
    {
      ...InvoicesColumn,
      pinned: 'left',
      headerComponentParams: {},
      headerComponent: undefined,
      cellRendererParams: {
        hideSelection: true,
        filters: filter,
        invoiceType: 'closed',
        currentPage: page,
        isFromInvoiceList,
      },
    },
    {
      colId: 'CHILD_CUSTOMER',
      headerName: 'Child Customer',
      cellRenderer: 'textWithLinkCell',
      sortable: false,
      initialHide: childCustomerColumnHideCondition,
      lockVisible: childCustomerColumnHideCondition,
      cellClass: 'ag-center-aligned-cell',
      headerClass: 'ag-center-aligned-header',
      valueGetter: (param) => {
        return {
          name: param.data?.customer_name,
          id: param.data?.customer_id,
          authorised: true,
          customer_no: param.data?.customer_no,
        };
      },
    },
    {
      ...CustomerColumn,
      cellRendererParams: {
        currentView: StatementViews.CLOSED_INVOICES,
        columnCustom: {
          type: 'DEFAULT',
        },
      },
      headerComponent: 'quickFilterHeader',
      headerComponentParams: {
        quickFilter: {
          filterIcon: <SearchIconRegular size="sm" width={12} />,
          clearIcon: <ClearRegular size="sm" width={12} />,
          filterComponent: (
            <CustomerSearch
              value={filter?.customer_ids}
              onSubmit={(value) => {
                dispatch(
                  updateClosedInvoiceCurrentFilters({
                    ...filter,
                    customer_ids: value,
                  } as ClosedInvoiceFilterParams)
                );
              }}
            />
          ),
        },
      },
      initialHide: !isFromInvoiceList,
      lockVisible: !isFromInvoiceList,
    },
    {
      ...IssueDateColumn,
      sortable: true,
      cellRendererParams: {
        ...IssueDateColumn.cellRendererParams,
        dateCellProps: {
          format,
        },
      },
    },
    {
      colId: 'AMOUNT',
      headerName: 'InvoiceAmount',
      cellRenderer: ClosedInvoiceAmount,
      cellClass: 'ag-right-aligned-cell',
      headerClass: 'ag-right-aligned-header',
      cellRendererParams: {
        columnCustom: {
          type: 'DEFAULT',
        },
      },
    },
    { ...InvoiceStatusColumn },
    {
      ...InvoiceSubsidiaryColumn,
      initialHide: !isSubsidiaryEnabled,
      lockVisible: !isSubsidiaryEnabled,
    },
    {
      colId: 'CLOSED_DATE',
      field: 'closed_date',
      headerName: 'Closed Date',
      cellRenderer: 'dateCell',
      cellRendererParams: {
        dateCellProps: {
          format,
        },
      },
    },
    {
      colId: 'CLOSED_BY',
      headerName: 'Closed by',
      field: 'closed_by',
      cellRenderer: 'textCell',
      sortable: false,
      valueGetter: (params) => {
        return params.data?.closed_by?.id
          ? `${params.data?.closed_by.first_name} ${params.data?.closed_by.last_name ?? '-'}`
          : '-';
      },
    },
  ];

  const column = columns.map((col) => {
    return getPersistedColumnPredicate<IInvoices>(
      col as ColProps<IInvoices> & ColGroupProps<IInvoices>,
      filter?.sort_col,
      filter?.sort_by as SortOrder
    );
  });
  return { column };
}

export { useColumn };
