import { ApprovalRequestDVDTO, SnapShotEventDTO } from 'pages/Approvals/type';
import { match } from 'ts-pattern';
import { InvoiceSnapshot } from './Invoice/InvoiceSnapshot';

interface ApprovalSnapShotProps {
  snapshot?: SnapShotEventDTO;
  baseCurrency?: string;
  locale?: string;
  dateFormat?: string;
  isAnonymous?: boolean;
  attachments?: ApprovalRequestDVDTO['attachments'];
  hash?: string;
}
function ApprovalSnapShot(props: ApprovalSnapShotProps) {
  const { snapshot, baseCurrency, locale, dateFormat, isAnonymous, attachments, hash } = props;

  return match(snapshot)
    .with({ entity_type: 'INVOICE' }, (props) => {
      return (
        <InvoiceSnapshot
          value={props.context}
          dateFormat={dateFormat}
          locale={locale}
          baseCurrency={baseCurrency}
          isAnonymous={isAnonymous}
          attachments={attachments}
          hash={hash}
        />
      );
    })
    .with({ entity_type: 'CUSTOMER' }, (props) => {
      return null;
    })
    .otherwise(() => {
      return null;
    });
}

export { ApprovalSnapShot };
