import { uniqBy } from 'lodash';
import {
  useGetCustomerDropdownList,
  useGetCustomerDropdownListSearch,
  useGetSelectedCustomerDropdownList,
} from 'queries/Customers/customers';
import { useMemo } from 'react';
import { CustomerDropDownDTO } from 'types/entities/customer';
import { useGetCustomerNameFormatter } from './useGetCustomerNameFormatter';

interface useCustomerListProps {
  selectedIds?: number[];
  unscoped?: boolean;
}

function useCustomerList(props: useCustomerListProps) {
  const { data: defaultList } = useGetCustomerDropdownList(props.unscoped);
  const { data: selectedValueList } = useGetSelectedCustomerDropdownList(props.selectedIds);
  const customerSearch = useGetCustomerDropdownListSearch(props.unscoped);
  const { getCustomerNameFormatter } = useGetCustomerNameFormatter();

  function getCustomerList() {
    if (!selectedValueList?.length) {
      return defaultList?.length
        ? defaultList?.map((option) => {
            const customerName = getCustomerNameFormatter({
              customerName: option.name,
              customerNumber: option.customer_no,
            });
            return { label: customerName, value: option.id };
          })
        : [];
    }
    const list = [...selectedValueList, ...(defaultList ?? [])];
    const modifiedList = list?.map((option) => {
      const customerName = getCustomerNameFormatter({
        customerName: option.name,
        customerNumber: option.customer_no,
      });
      return { label: customerName, value: option.id };
    });

    return uniqBy(modifiedList, 'value');
  }

  const customersList = useMemo(getCustomerList, [
    defaultList,
    selectedValueList,
    getCustomerNameFormatter,
  ]);
  function computedSearchedCustomerNameOptions(customerInfo?: CustomerDropDownDTO[]) {
    if (customerInfo && Boolean(customerInfo.length)) {
      return customerInfo?.map((option) => {
        const customerName = getCustomerNameFormatter({
          customerName: option.name,
          customerNumber: option.customer_no,
        });
        return { label: customerName, value: option.id };
      });
    }
    return [];
  }
  return { customerSearch, customersList, computedSearchedCustomerNameOptions };
}

export default useCustomerList;
