import { CellRenderProps } from '@sinecycle/growcomponents';
import TextWithHyperLink from 'components/Common/ParentChildAssociation/ParentText';
import { useParams } from 'lib/router';
import { CSSProperties } from 'react';
import { AgingData } from 'types/entities/aging';
export interface HyerLinkDetailsProps {
  name?: string;
  id?: number;
  authorised?: boolean;
  customer_no?: string;
}

const ParentStyle: CSSProperties = { display: 'flex', alignItems: 'center' };
export default function TextWithLinkCell(props: CellRenderProps<AgingData, HyerLinkDetailsProps>) {
  const params = useParams();
  const isSameCustomer = Number(params.id) === props?.value?.id;
  const Parent = <TextWithHyperLink hyperLinkInfo={props?.value} style={ParentStyle} />;

  return <>{isSameCustomer ? <>-</> : Parent}</>;
}
