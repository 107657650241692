import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popconfirm, Tooltip } from 'antd';
import { PopConfirmContent } from 'components/BaseComponents/ButtonWithConfirmation/PopconfirmContent';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { StyledIconButton } from 'components/Common/Styles/Styles';
import { removeEmailTemplateThunk } from 'controllers/email-template';
import { usePageNavigate } from 'lib/router';
import { find } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { folderIdSelector } from 'store/email-template/email-template';
import { folderSelector } from 'store/insert';
import { EmailTemplates, FolderType } from 'types/entities/email-templates';
import useOpen from 'util/hooks/useOpen';

interface ActionsProps {
  template: EmailTemplates;
}
const t = {
  warning_title: 'Confirm Delete',
  warning_desc: 'Do you want to delete this Email Template?',
  okText: 'Yes',
  cancelText: 'No',
};
function Actions(props: ActionsProps) {
  const navigate = usePageNavigate();
  const dispatch = useDispatch();
  const folders = useSelector(folderSelector);
  const folderId = useSelector(folderIdSelector);
  const currentFolder = find(folders, { id: folderId })?.email_template_type;
  const { open, handleOpenChange } = useOpen({ open: false });
  function handleCopy() {
    const searchQueryParam = {
      copy: true,
    };
    const parsedSearchParam = new URLSearchParams(
      searchQueryParam as unknown as Record<string, string>
    );
    navigate({
      appPage: 'EMAIL_TEMPLATE',
      pathParams: { id: props.template.id },
      searchParams: parsedSearchParam,
    });
  }

  function handleDelete() {
    dispatch(removeEmailTemplateThunk(props.template.id, props.template.template_folder_id));
  }

  const PopConfirmTitleJSX = (
    <PopConfirmContent
      title={t.warning_title}
      desc={t.warning_desc}
      descStyles={{ maxWidth: '250px' }}
      titleStyles={{ color: 'var(--gold-6)', fontWeight: 'var(--fs-semibold)' }}
    />
  );

  const ActionButtons = (
    <Flex align="center" gap="var(--space-8)" className={open ? '' : 'hide-actions'}>
      <Tooltip title="Make a Copy">
        <StyledIconButton
          size="small"
          icon={<FontAwesomeIcon icon={['far', 'copy']} color="var(--purple-7)" />}
          onClick={(e) => {
            e.stopPropagation();
            handleCopy();
          }}
        />
      </Tooltip>

      <Popconfirm
        title={PopConfirmTitleJSX}
        onConfirm={(e) => {
          e?.stopPropagation();
          handleDelete();
        }}
        onCancel={(e) => {
          e?.stopPropagation();
        }}
        icon={false}
        okText={t.okText}
        cancelText={t.cancelText}
        overlayClassName="ant-popover-title-no-padding"
        placement="bottom"
        trigger={['click']}
        onOpenChange={handleOpenChange}
      >
        <Tooltip title="Delete">
          <StyledIconButton
            size="small"
            icon={<FontAwesomeIcon icon={['far', 'trash-alt']} color="var(--purple-7)" />}
            onClick={(e) => {
              e.stopPropagation();
            }}
          />
        </Tooltip>
      </Popconfirm>
    </Flex>
  );
  return currentFolder === FolderType.APPROVAL_REQUEST ? null : ActionButtons;
}

export default Actions;
