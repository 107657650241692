import { Result } from 'antd';
import { ApprovalsCard } from 'components/Settings/WorkFlowAutomation/Approvals/ApprovalsCard';
import { PageNavigate } from 'lib/router';
import { AdvanceReportPage, AdvanceReportsPage } from 'pages/AdvancedReports';
import { Dashboard, DefaultDashboard } from 'pages/Dashboard';
import CompanyDashboard from 'pages/Dashboard/AdminDashboard';
import { DefaultPage } from 'pages/Default/DefaultPage';
import ErrorPage from 'pages/Error';
import InvoiceDetailsPageFallBack from 'pages/InvoiceDetails/InvoiceDetailsPageFallBack';
import { CurrencyPage } from 'pages/Settings/Currency';
import { DocumentsSettings } from 'pages/Settings/DocumentTypes/DocumentTypes';
import { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import { pagePath, routePath } from './constants/page-info';
import ProtectedLayout from './layout/Protected';
import PublicRouteLayout from './layout/Public';
import { UnauthenticatedLayout } from './layout/Unauthenticated/UnauthenticatedLayout';

const ApprovalListPage = lazy(() => import('pages/Approvals/ApprovalList'));
const InboxActivities = lazy(() => import('components/Inbox/CollectionActivitiesV2/InboxActivities'));
const EmailConversations = lazy(() => import('components/Inbox/EmailConversations/EmailConversations'));
const SlackAccountBind = lazy(() => import('components/Settings/Integrations/Slack/SlackAccountBind'));
const ARAging = lazy(() => import('pages/ARAging'));
const CollectionActivities = lazy(() => import('pages/CollectionActivities'));
const CollectionStrategies = lazy(() => import('pages/CollectionStrategies'));
const CollectionStrategy = lazy(() => import('pages/CollectionStrategy'));
const CollectorsManagement = lazy(() => import('pages/CollectorsManagement'));
const CustomerDetailsPage = lazy(() => import('pages/CustomerDetails/index'));
const AllCustomersPage = lazy(() => import('pages/AllCustomers/index'));
const CollaboratorDashboard = lazy(() => import('pages/Dashboard/CollaboratorDashboard'));
const CollectorsDashboard = lazy(() => import('pages/Dashboard/CollectorDashboard'));
const EmailTemplate = lazy(() => import('pages/Settings/EmailTemplate'));
const EmailTemplateList = lazy(() => import('pages/Settings/EmailTemplatesListing'));
const Integrations = lazy(() => import('pages/Settings/Integrations'));
const ReceivableSettings = lazy(() => import('pages/Settings/ReceivableSettings'));
const RolesAndPermissionsSettings = lazy(() => import('pages/Settings/RolesAndPermissions'));
const Settings = lazy(() => import('pages/Settings/SettingsPage'));
const UserManagementSettings = lazy(() => import('pages/Settings/UserManagementSettings'));
const ActivateAccount = lazy(() => import('pages/public/ActivateAccount/ActivateAccountWrapper'));
const ForgotPassword = lazy(() => import('pages/public/ForgotPassword'));
const Login = lazy(() => import('pages/public/Login'));
const ResetPassword = lazy(() => import('pages/public/ResetPassword'));
const UserCategorySettingPage = lazy(() => import('pages/Settings/UserCategoryPage'));
const WorkFlowAutomations = lazy(() => import('pages/Settings/WorkFlowAutomationPage/index'));
const NewCustomWorkflow = lazy(() => import('pages/Settings/WorkflowAutomation/NewCustomWorkflow'));
const CustomWorkflow = lazy(() => import('pages/Settings/WorkflowAutomation/CustomWorkflow'));

const AREmailVerification = lazy(() => import('pages/AREmailVerification'));
const CustomerPortalConfig = lazy(() => import('pages/Settings/CustomerPortalConfig'));
const DisputeTypesPage = lazy(() => import('pages/Settings/DipsuteTypes'));
const Inbox = lazy(() => import('pages/Inbox'));
const InvoiceDetailsPage = lazy(() => import('pages/InvoiceDetails/index'));
const InvoiceListPageV2 = lazy(() => import('pages/InvoiceListV2'));
const MockCustomerPortalPage = lazy(() => import('pages/Mock/CustomerPortal'));
const PaymentDetail = lazy(() => import('pages/PaymentDetail'));
const PaymentListPage = lazy(() => import('pages/PaymentList/index'));
const ReportsPage = lazy(() => import('pages/Reports/index'));
const SettingsListing = lazy(() => import('pages/Settings'));
const AccountDetailsSettings = lazy(() => import('pages/Settings/AccountDetailsSettings'));
const AssignCollectionSettings = lazy(() => import('pages/Settings/AssignCollectionSettings'));
const CollectionPlanning = lazy(() => import('pages/Settings/CollectionPlanning'));
const CompanySettings = lazy(() => import('pages/Settings/CompanySettings'));
const ContactTypes = lazy(() => import('pages/Settings/ContactsTypes'));
const DSOConfig = lazy(() => import('pages/Settings/DSOConfig'));
const CashappPaymentsPage = lazy(() => import('pages/Cashapps/Payments'));
const CashappSettingsPage = lazy(() => import('pages/Cashapps/Settings'));
const Prefernces = lazy(() => import('pages/Settings/Preferences'));

export function AppRoutes() {
  return (
    <Routes>
      {/* AUTHENTICATED ROUTES */}
      <Route path="/" element={<ProtectedLayout />}>
        <Route index element={<DefaultPage />} />
        {/* --------------------------- DASHBOARD ------------------------------------------------ */}
        <Route path={routePath('DASHBOARD')} element={<Dashboard />}>
          <Route index element={<DefaultDashboard />} />
          <Route path={routePath('ADMIN_DASHBOARD')} element={<CompanyDashboard />} />
          <Route path={routePath('TEAM_DASHBOARD')} element={<CompanyDashboard />} />
          <Route path={routePath('COLLECTOR_DASHBOARD')} element={<CollectorsDashboard />} />
          <Route path={routePath('COLLABORATOR_DASHBOARD')} element={<CollaboratorDashboard />} />
        </Route>
        {/* --------------------------- AGING ----------------------------------- */}
        <Route path={routePath('AR_AGING')} element={<ARAging />} />
        {/* --------------------------- CUSTOMERS ----------------------------------- */}
        <Route path={routePath('ALL_CUSTOMERS')}>
          <Route index element={<AllCustomersPage />} />
          <Route path={routePath('CUSTOMER_DETAILS')} element={<CustomerDetailsPage />} />
        </Route>
        {/* --------------------------- INVOICES ----------------------------------- */}
        <Route path={routePath('INVOICE_LIST')}>
          <Route index element={<InvoiceListPageV2 />} />
          <Route path={routePath('INVOICE_DETAILS')} element={<InvoiceDetailsPage />} />
        </Route>
        {/* --------------------------- PAYMENTS ----------------------------------- */}
        <Route path={routePath('PAYMENT_LIST')} element={<PaymentListPage />}>
          <Route path={routePath('PAYMENT_DETAIL')} element={<PaymentDetail />} />
          <Route index element={null} />
        </Route>
        <Route path={routePath('INVOICE_LIST')} element={<InvoiceListPageV2 />} />
        <Route path={routePath('CUSTOMER_DETAILS')} element={<CustomerDetailsPage />} />
        <Route path={routePath('INVOICE_DETAILS')} element={<InvoiceDetailsPage />} />
        <Route path={routePath('COLLECTORS_MANAGEMENT')} element={<CollectorsManagement />} />
        <Route path={routePath('COLLECTION_ACTIVITIES')} element={<CollectionActivities />} />

        {/* --------------------------- INBOX ---------------------------------------------------- */}
        <Route path={routePath('INBOX')} element={<Inbox />}>
          <Route index element={<PageNavigate to={routePath('INBOX_EMAIL')} replace />} />
          <Route path={routePath('INBOX_EMAIL')} element={<EmailConversations />} />
          <Route path={routePath('INBOX_ACTIVITIES')} element={<InboxActivities />} />
        </Route>
        {/* --------------------------- WORKFLOWS ---------------------------------- */}

        <Route path={routePath('AUTOMATIONS')}>
          <Route path={routePath('WORKFLOW_AUTOMATIONS')}>
            <Route index element={<WorkFlowAutomations />} />
            <Route path={routePath('CUSTOM_WORKFLOW')} element={<CustomWorkflow />} />
            <Route path={routePath('CUSTOM_WORKFLOW_NEW')} element={<NewCustomWorkflow />} />
          </Route>
          <Route path={routePath('COLLECTION_STRATEGIES')}>
            <Route index element={<CollectionStrategies />} />
            <Route path={routePath('COLLECTION_STRATEGY_NEW')} element={<CollectionStrategy />} />
            <Route path={routePath('COLLECTION_STRATEGY')} element={<CollectionStrategy />} />
          </Route>
          <Route path={routePath('OWNER_ASSIGNMENT')} element={<AssignCollectionSettings isCustomerSegment={false} />} />
          <Route path={routePath('STRATEGY_ASSIGNMENT')} element={<AssignCollectionSettings isCustomerSegment={true} />} />
          <Route path={routePath('APPROVALS')} element={<ApprovalListPage />} />
        </Route>

        {/* --------------------------- NEW COLLECTION STRATEGY ---------------------------------- */}

        <Route path={routePath('REPORTS')} element={<ReportsPage />} />
        {/*
          <Route path="collection-strategies" element={<Page title="Collection Strategies" />} />
          <Route path="collection-strategies/:id" element={<Page title="Edit Collection Strategy" />} />
          <Route path="collection-strategies/new" element={<Page title="New Collection Strategy" />} />
        */}
        {/* --------------------------- SETTINGS ------------------------------------------------- */}
        <Route path={routePath('SETTINGS')} element={<Settings />}>
          <Route index element={<SettingsListing />} />
          <Route path="ca/*" element={<CashappSettingsPage />} />

          <Route path={routePath('EMAIL_TEMPLATES')}>
            <Route index element={<EmailTemplateList />} />
            <Route path={routePath('EMAIL_TEMPLATE')} element={<EmailTemplate />} />
            <Route path={routePath('EMAIL_TEMPLATE_NEW')} element={<EmailTemplate />} />
          </Route>

          <Route path={routePath('INTEGRATIONS')}>
            <Route index element={<PageNavigate to={routePath('SETTINGS')} />} />
            <Route path={routePath('INTEGRATION')} element={<Integrations />} />
          </Route>

          <Route path={routePath('RECEIVABLES')} element={<ReceivableSettings />} />
          <Route path={routePath('COLLECTION_STATUS')} element={<ReceivableSettings />} />
          <Route path={routePath('COLLECTION_PLANNING')} element={<CollectionPlanning />} />
          <Route path={routePath('CURRENCY')} element={<CurrencyPage />} />
          <Route path={routePath('CUSTOMER_PORTAL_CONFIG')} element={<CustomerPortalConfig />} />
          <Route path={routePath('DOCUMENT_MANAGEMENT')} element={<DocumentsSettings />} />

          <Route path={routePath('ACCOUNT_DETAILS')} element={<AccountDetailsSettings />} />
          <Route path={routePath('ORG_DETAILS')} element={<CompanySettings />} />
          <Route path={routePath('USER_MANAGEMENT')} element={<UserManagementSettings />} />
          <Route path={routePath('ROLES_AND_PERMISSIONS')} element={<RolesAndPermissionsSettings />} />
          <Route path={routePath('CONTACT_TYPES')} element={<ContactTypes />} />
          <Route path={routePath('DISPUTE_TYPE')} element={<DisputeTypesPage />} />
          <Route path={routePath('USER_CATEGORY')} element={<UserCategorySettingPage />} />
          <Route path={routePath('DSO_CONFIG')} element={<DSOConfig />} />
          <Route path={routePath('PREFERENCES')} element={<Prefernces />} />
        </Route>

        {/* --------------------------- FALLBACKS ---------------------------------------------------- */}
        <Route path="all-customers" element={<PageNavigate to={pagePath('ALL_CUSTOMERS')} replace />} />
        <Route path="customer-details/:id" element={<CustomerDetailsPage />} />
        <Route path="invoice-list" element={<PageNavigate to={pagePath('INVOICE_LIST')} replace />} />
        <Route path="invoice-details/:id" element={<InvoiceDetailsPageFallBack />} />
        {/* --------------------------- CASHAPP ------------------------------------------------- */}
        <Route path="ca/*" element={<CashappPaymentsPage />} />
        {/* --------------------------- MISC ---------------------------------------------------- */}
        <Route path={routePath('SLACK_ACCOUNT')} element={<SlackAccountBind />} />
        <Route path={routePath('AR_EMAIL_VERIFICATION')} element={<AREmailVerification />} />
        <Route path={routePath('ERROR')} element={<ErrorPage />} />
        <Route path="*" element={<Result status="404" />} />
        <Route path="m">
          <Route path="cportal/:mockId" element={<MockCustomerPortalPage />} />
        </Route>
        <Route path={routePath('SLACK_ACCOUNT')} element={<SlackAccountBind />} />
        <Route path={routePath('ADVANCED_REPORTS')}>
          <Route index element={<AdvanceReportsPage />} />
          <Route path={routePath('ADVANCED_REPORT')} element={<AdvanceReportPage />} />
        </Route>
      </Route>

      {/* UNAUTHENTICATED ROUTES */}
      <Route path="/" element={<PublicRouteLayout />}>
        <Route path={routePath('LOGIN')} element={<Login />} />
        <Route path={routePath('FORGOT_PASSWORD')} element={<ForgotPassword />} />
        <Route path={routePath('RESET_PASSWORD')} element={<ResetPassword />} />
        <Route path={routePath('ACTIVATE_ACCOUNT')} element={<ActivateAccount />} />
      </Route>

      {/* FALLBACK  */}
      <Route path="*" element={<Result status="404" />} />
    </Routes>
  );
}

export function UnauthAppRoutes() {
  return (
    <Routes>
      <Route path="ua/*" element={<UnauthenticatedLayout />}>
        <Route path={routePath('UA_APPROVAL')} element={<ApprovalsCard />} />
        <Route path="*" element={<Result status="404" />} />
      </Route>
    </Routes>
  );
}
