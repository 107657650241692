import { useGetCustomerNameFormatter } from 'hooks/useGetCustomerNameFormatter';
import { PageLink } from 'lib/router';

interface DisplayAuthorisedNameProps {
  name?: string;
  id?: number;
  authorised?: boolean;
  customerNumber?: string;
}

export function useAuthorisedCustomerName() {
  const { getCustomerNameFormatter } = useGetCustomerNameFormatter();
  function getCustomerName({ name, id, authorised, customerNumber }: DisplayAuthorisedNameProps) {
    const formattedName = getCustomerNameFormatter({
      customerName: name,
      customerNumber,
    });

    const CustomerName = <>{formattedName}</>;
    const linkedJSX = id ? (
      <PageLink appPage={'CUSTOMER_DETAILS'} pathParams={{ id }} target="_blank">
        {CustomerName}
      </PageLink>
    ) : (
      CustomerName
    );
    return { formattedName, reactNode: authorised ? linkedJSX : CustomerName };
  }

  return { getCustomerName };
}
