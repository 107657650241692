import { CustomerNameCustomerIdFormatterProps } from '@sinecycle/growcomponents';
import { useGetCustomerNameFormatter } from 'hooks/useGetCustomerNameFormatter';
import { useState } from 'react';
import { InvoiceDropdownInterfaceProps } from 'store/invoice/type';
import { BaseCustomer } from 'types/entities/customer';
import { InvoiceBasicDetails } from 'types/entities/invoice';
import { ActionableEntity } from '../Email';
import { groupInvoicesByCustomer, isSingleCustomer } from './utils';

interface Props {
  emailFor: ActionableEntity;
  customerList?: BaseCustomer[];
  invoiceList?: InvoiceDropdownInterfaceProps[] | InvoiceBasicDetails[];
}
interface InitialCustomerAndInvoicesProps extends Props {
  getCustomerNameFormatter: (options: CustomerNameCustomerIdFormatterProps) => string;
}
function getInitialCustomerAndInvoices({
  customerList = [],
  invoiceList = [],
  emailFor,
  getCustomerNameFormatter,
}: InitialCustomerAndInvoicesProps) {
  if (emailFor === ActionableEntity.CUSTOMER) {
    return { customerId: customerList[0].id, invoiceIds: [] };
  }

  if (!invoiceList.length) {
    return { customerId: customerList[0].id, invoiceIds: [] };
  }

  if (isSingleCustomer(invoiceList)) {
    return {
      customerId: invoiceList[0].customer_id,
      invoiceIds: invoiceList.map((invoice) => invoice.id),
    };
  }

  const invoicesGroupedByCustomer = groupInvoicesByCustomer(invoiceList, getCustomerNameFormatter);

  const invoiceIds = invoicesGroupedByCustomer[0].invoices.map((invoice) => invoice.invoice_id);
  return { customerId: invoicesGroupedByCustomer[0].id, invoiceIds };
}

export function useEmailPreview(props: Props) {
  const { emailFor, customerList = [], invoiceList = [] } = props;
  const { getCustomerNameFormatter } = useGetCustomerNameFormatter();
  const [customer, setCustomer] = useState<number | undefined>(() => {
    const { customerId } = getInitialCustomerAndInvoices({
      emailFor,
      invoiceList,
      customerList,
      getCustomerNameFormatter,
    });
    return customerId;
  });
  const [invoices, setInvoices] = useState(() => {
    const { invoiceIds } = getInitialCustomerAndInvoices({
      emailFor,
      invoiceList,
      customerList,
      getCustomerNameFormatter,
    });
    return invoiceIds;
  });

  return { customer, invoices, setCustomer, setInvoices };
}
