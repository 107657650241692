import { useSelector } from 'react-redux';
import { currentLoggedInUserSelector } from 'store/authentication/authentication';
import { UserRole } from 'types/entities/user';

import { ERolesAndPrivileges } from 'components/HigherOrderComponent/Privileged/privileges';
import { difference, intersection, isEmpty, isEqual, sortBy } from 'lodash';
import { pagePrivileges } from 'router/constants/page-info';

export function useIsApprover() {
  const currentUser = useSelector(currentLoggedInUserSelector);
  const roles = currentUser?.roles ?? [];
  const privileges = currentUser?.privileges ?? [];
  const approvalPrivileges = [
    ...pagePrivileges('APPROVALS'),
    ERolesAndPrivileges.DEFAULT_PRIVILEGE,
  ];
  const approverRoles = [UserRole.APPROVER, UserRole.APPROVER_MANAGER];
  const isApprover = !isEmpty(intersection(roles, approverRoles));
  const hasOnlyApprovalPrivileges = isEqual(sortBy(privileges), sortBy(approvalPrivileges));
  const hasOtherPrivileges = !isEmpty(difference(privileges, approvalPrivileges));
  return { isApprover, hasOnlyApprovalPrivileges, hasOtherPrivileges };
}
