import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Spin, Tooltip } from 'antd';
import { growfinLogo, ZoroLogo } from 'assets/image-urls';
import { TypographyText } from 'components/BaseComponents/AntTypography/AntTypography';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import HideWrapper from 'components/Common/Util/HideWrapper';
import { useHelpWidget } from 'lib/help-widget';
import { DefaultPageLink } from 'lib/router';
import { useDispatch } from 'react-redux';
import { updateSideNavState } from 'store/UI';
import { sessionStore } from 'util/browser-storage';
import useOpen from 'util/hooks/useOpen';
import { IS_DEMO_ZUROA, IS_DISABLED, IS_PROD } from 'util/http-utils';
import { usePageList } from './hooks/usePageList';
import { GroupedRouteLink, PrimitiveRouteLink } from './sidebar.components';
import { navStyle } from './sidebar.styles';
import { StyledMenuSideBar, StyledSider } from './style';
const t = {
  help: 'Help & Support',
};
function AppLogoLink() {
  return (
    <DefaultPageLink>
      <img
        src={IS_DEMO_ZUROA ? ZoroLogo : growfinLogo}
        width={IS_DEMO_ZUROA ? '50' : '64'}
        height={IS_DEMO_ZUROA ? '50' : '64'}
        className="header-logo"
        alt="AR app"
        style={{ padding: 'var(--space-12)' }}
      />
    </DefaultPageLink>
  );
}

export function AppSidebar() {
  const { getItem, setItem } = sessionStore;
  const { open, handleOpenChange } = useOpen({ open: getItem('side_nav') ?? false });
  const { open: hideIcon, handleOpenChange: handlePopoverOpenChange } = useOpen({ open: false });
  const { validPageList, activeKey, sideBarRoutes } = usePageList();
  const { openWidget } = useHelpWidget();

  const Links = validPageList.map((page, index) => {
    return page.subLinks === 'primitive' ? (
      <PrimitiveRouteLink
        key={page.key}
        url={page.url as string}
        icon={page.icon}
        label={page.label}
        onOpenChange={handlePopoverOpenChange}
        index={index}
      />
    ) : (
      <GroupedRouteLink
        subLinks={page.subLinks}
        icon={page.icon}
        label={page.label}
        key={page.key}
        onOpenChange={handlePopoverOpenChange}
        index={index}
        groupSub={page?.groupSub}
      />
    );
  });

  const NonDetailSideBar = <nav style={navStyle}>{Links}</nav>;

  const DetailsSideBar = (
    <StyledMenuSideBar
      items={sideBarRoutes}
      theme="dark"
      mode="inline"
      selectedKeys={activeKey.map((f) => f?.active) as string[]}
      defaultOpenKeys={activeKey.map((f) => f?.defaultOpen) as string[]}
    />
  );

  const CollapseIcon = (
    <Tooltip title={open ? 'Expand' : 'Collapse'} placement="right">
      <FontAwesomeIcon
        icon={open ? ['far', 'chevron-right'] : ['far', 'chevron-left']}
        color="var(--primary-7)"
      />
    </Tooltip>
  );

  const Footer = (
    <Flex
      direction="column"
      style={{ height: open ? '100%' : 'auto', paddingLeft: open ? '0' : '20px' }}
    >
      <Flex
        direction="column"
        style={{ height: '100%' }}
        justify="center"
        align={open ? 'center' : 'flex-start'}
      >
        <HideWrapper hide={!IS_PROD || IS_DISABLED}>
          <Flex
            gap="var(--space-8)"
            align="center"
            style={{ position: 'fixed', bottom: 'var(--space-24)' }}
          >
            <Tooltip title={open ? 'Help?' : undefined} color="var(--purple-6)" placement="right">
              <HelpWidget />
            </Tooltip>
            <TypographyText
              style={{
                fontSize: 'var(--fs-14)',
                color: 'var(--gray-1)',
                display: open ? 'none' : 'inline',
                cursor: 'pointer',
              }}
              onClick={openWidget}
            >
              {t.help}
            </TypographyText>
          </Flex>
        </HideWrapper>
      </Flex>
    </Flex>
  );
  const dispatch = useDispatch();
  return (
    <StyledSider
      collapsed={open}
      collapsible={!hideIcon}
      onCollapse={(collapsed) => {
        handleOpenChange(collapsed);
        setItem('side_nav', collapsed);
        dispatch(updateSideNavState(collapsed));
      }}
      trigger={CollapseIcon}
      reverseArrow
      theme="dark"
      defaultCollapsed={false}
      collapsedWidth={72}
      width={220}
    >
      <Flex align="center" justify={open ? 'center' : 'flex-start'}>
        <AppLogoLink />
      </Flex>
      <Flex justify="center">{open ? NonDetailSideBar : DetailsSideBar}</Flex>
      {Footer}
    </StyledSider>
  );
}

function HelpWidget() {
  const { openWidget, isLoading } = useHelpWidget();
  return (
    <Flex
      justify="center"
      direction="column"
      align="center"
      style={{ cursor: 'pointer' }}
      onClick={openWidget}
      className="help-text"
    >
      {isLoading ? (
        <Spin spinning />
      ) : (
        <FontAwesomeIcon icon={['far', 'circle-question']} color="var(--gray-1)" fontSize={24} />
      )}
    </Flex>
  );
}
