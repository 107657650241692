import { GrowTypography } from '@sinecycle/growcomponents';

interface OnBehalfProps {
  assignedUser?: {
    first_name?: string;
    last_name?: string;
  };
}

function OnBehalf({ assignedUser }: OnBehalfProps) {
  return (
    <div className="tw-flex tw-flex-col tw-gap-4 tw-px-8 tw-py-4 tw-bg-geekblue-1">
      <GrowTypography.Text fs="12" className="tw-text-gray-8">
        Action taken on behalf of
      </GrowTypography.Text>
      <GrowTypography.Text fs="12">{`${assignedUser?.first_name ?? ''} ${
        assignedUser?.last_name ?? ''
      }`}</GrowTypography.Text>
    </div>
  );
}
export { OnBehalf };
