import { Select } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { GrowText } from 'components/BaseComponents/Typography';
import styled from 'styled-components';
import { ActionTypeIconContainerProps, IconContainerProps } from './types';

export const IconContainer = styled.div<IconContainerProps>`
  background-color: ${(props) => props.backgroundColor};
  padding: 0 var(--space-4) 0 var(--space-4);
  width: 24px;
  height: 24px;
  border-radius: 4px;
  border: 1px solid var(--gray-4);
`;

export const ActionTypeTitleContainer = styled(Flex)`
  border-radius: var(--space-4);
  padding: var(--space-4) var(--space-8);
  border: 1px solid var(--gray-4);
  cursor: pointer;

  &:hover {
    box-shadow: var(--shadow-2);
    border: 1px solid var(--primary-4);
  }
  &.selected {
    box-shadow: var(--shadow-2);
    border: 1px solid var(--primary-4);
  }
`;

export const ActionTypeIconContainer = styled(Flex)<ActionTypeIconContainerProps>`
  align-items: center;
  justify-content: center;
  border-radius: var(--space-4);
  border: 1px solid var(--gray-4);
  background-color: ${(props) => props.backgroundColor};
  width: 24px;
  height: 24px;
`;

export const ActionTypeTitle = styled(GrowText)`
  color: var(--primary-7);
`;

export const StyledSelectAction = styled(Select)`
  .ant-select-item-option-grouped {
    padding-left: var(--space-20);
  }
`;
