import { Flex } from 'components/BaseComponents/Layout/Flex';
import {
  CreatedBlock,
  CustomerBlock,
  DateBlock,
  LabelBlock,
  TextBlock,
} from 'components/CollectionActivities/Common/Details/BodyBlocks';
import { InfoContent } from 'components/CollectionActivities/Common/Details/Content';
import PaymentStatusTag from 'components/PaymentList/PaymentStatusTag';
import { ActivityDetailsBase } from 'types/activities/activity-types';
import { Payment, PaymentStatus } from 'types/activities/payment';

interface PaymentInfoProps extends ActivityDetailsBase {
  data?: Payment;
  loading: boolean;
}

function PaymentDetailsInfo(props: PaymentInfoProps) {
  const { data, loading } = props;

  const Status =
    data && !loading ? <PaymentStatusTag value={data.status as PaymentStatus} /> : null;

  const Reconciled =
    data && !loading ? (
      <CreatedBlock createdBy={data.reconciled_by} createdDate={data.created_at} />
    ) : null;

  const PaymentDate = data && !loading ? <DateBlock dateString={data.payment_date} /> : null;
  const PaymentMode = data && !loading ? <TextBlock value={data.mode} /> : null;
  const Ref = data && !loading ? <TextBlock value={data.reference_no} /> : null;
  const ERPid = data && !loading ? <TextBlock value={data.source_payment_id} /> : null;

  const Customer =
    props.customer && !loading ? (
      <CustomerBlock
        id={props.customer.id}
        name={props.customer.name}
        customerNumber={props.customer.customer_no}
      />
    ) : null;

  return (
    <Flex direction="column" gap="var(--space-16)">
      <InfoContent
        content={Status}
        label={<LabelBlock label="Payment Status" color="var(--gray-7)" />}
      />
      <InfoContent
        content={PaymentDate}
        label={<LabelBlock label="Payment Date" color="var(--gray-7)" />}
      />
      <InfoContent
        content={PaymentMode}
        label={<LabelBlock label="Payment mode" color="var(--gray-7)" />}
      />
      <InfoContent
        content={Ref}
        label={<LabelBlock label="Reference number" color="var(--gray-7)" />}
      />
      <InfoContent content={ERPid} label={<LabelBlock label="ERP ID" color="var(--gray-7)" />} />
      <InfoContent
        content={Reconciled}
        label={<LabelBlock label="Reconciled" color="var(--gray-7)" />}
      />
      <InfoContent
        content={Customer}
        label={<LabelBlock label="Customer" color="var(--gray-7)" />}
      />
    </Flex>
  );
}

export { PaymentDetailsInfo };
