import { InputRef } from 'antd';
import { COMMON_EVENT } from 'events/common';
import { useGetCustomerNameFormatter } from 'hooks/useGetCustomerNameFormatter';
import { dispatchAppEvent } from 'lib/pub-sub';
import { matchSorter } from 'match-sorter';
import { useCallback, useEffect, useRef, useState } from 'react';
import { fromCustomer, fromInvoice, fromRoute } from './adapters';
import { useRouteSearch, useSearch } from './useSearch';
const K_KEY = 'k';
export function useSpotlight() {
  // Basic variables to control the Spotlight
  const [query, setQuery] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const { getCustomerNameFormatter } = useGetCustomerNameFormatter();

  // Search Hooks
  const { data: searchResults, isFetching: isLoading } = useSearch(query ?? '');
  const routeResults = useRouteSearch(query ?? '');
  const customerResults =
    searchResults?.customers.map((customer) => {
      const customerName = getCustomerNameFormatter({
        customerName: customer.content?.name,
        customerNumber: customer.content?.customer_no,
      });
      return fromCustomer(customer, customerName);
    }) ?? [];

  let results = [
    ...customerResults,
    ...(searchResults?.invoices.map(fromInvoice) ?? []),
    ...(routeResults.map(fromRoute) ?? []),
  ];
  results = matchSorter(results, query, { keys: ['title'] });

  // Shortcut Handlers
  const handleShortCut = useCallback((e) => {
    if (e.key === K_KEY && (e.metaKey || e.ctrlKey)) {
      e.preventDefault();
      setIsOpen(true);
      setQuery('');

      inputRef?.current?.focus();

      dispatchAppEvent({ type: COMMON_EVENT.GLOABL_SEARCH_SHORTCUT_PRESSED });
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', handleShortCut);
    return () => {
      document.removeEventListener('keydown', handleShortCut);
    };
  }, [handleShortCut]);

  // Helpers
  const closeSpotlight = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  return {
    searchText: query,
    setSearchtext: setQuery,
    isOpen,
    closeSpotlight,
    inputRef,
    results,
    isLoading,
  };
}
