import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GrowButton, GrowTypography } from '@sinecycle/growcomponents';
import { useIsMutating } from '@tanstack/react-query';
import { Image, Layout, Spin } from 'antd';
import { growfinLogo } from 'assets/image-urls';
import ActivitiesModalLoader from 'components/CollectionActivities/Common/ActivitiesModalLoader';
import { DetailsLayout } from 'components/CollectionActivities/Common/Details/DetailsLayout';
import Helmet from 'lib/helmet';
import { PageLink } from 'lib/router';
import { useApprovalAnonymousReport } from 'queries/Approvals/approval';
import { ApprovalAnonymousDetailsContent } from './Details/Content';
import { ApprovalAnonInfo } from './Details/Info';

function ApprovalsCard() {
  const { data, isLoading } = useApprovalAnonymousReport();
  const Icon = <FontAwesomeIcon icon={['far', 'arrow-up-right-from-square']} />;
  const updateLoading = useIsMutating(['anon-update-approval']);
  const Login = (
    <PageLink
      target="_blank"
      appPage="APPROVALS"
      searchParams={
        {
          approval_id: data?.id ?? '',
        } as unknown as URLSearchParams
      }
    >
      <GrowButton icon={Icon} iconPosition="end">
        Login to Growfin
      </GrowButton>
    </PageLink>
  );

  if (isLoading && !data) {
    return <Spin spinning fullscreen />;
  }
  return (
    <Layout className="tw-h-[100vh]">
      <Helmet>
        <meta name="viewport" content="width=1920" />
        <title>Growfin - Modern AR Automation Software</title>
      </Helmet>
      <Layout.Header className="tw-flex tw-justify-between tw-items-center tw-h-[64px]">
        <div className="tw-flex tw-items-center tw-gap-24">
          <Image src={growfinLogo} preview={false} width={64} height={64} className="tw-flex" />
          <div className="tw-flex tw-flex-col">
            <GrowTypography.Text fs="12">{data?.account.name ?? 'Growfin'}</GrowTypography.Text>
            <GrowTypography.Text fs="20">Approvals</GrowTypography.Text>
          </div>
        </div>
        {Login}
      </Layout.Header>
      <Layout.Content className="tw-bg-background-1 tw-h-[calc(100vh - 64px)]">
        <div className=" tw-h-full tw-p-[48px] tw-overflow-x-hidden tw-overflow-y-auto">
          {updateLoading ? (
            <div style={{ height: '650px' }}>
              <ActivitiesModalLoader />
            </div>
          ) : (
            <DetailsLayout
              content={
                <ApprovalAnonymousDetailsContent
                  data={data?.approval_request}
                  accountDetails={data?.account}
                  id={data?.id}
                />
              }
              info={<ApprovalAnonInfo data={data} />}
              mode="inbox"
            />
          )}
        </div>
      </Layout.Content>
    </Layout>
  );
}

export { ApprovalsCard };
