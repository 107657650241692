import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GrowButton } from '@sinecycle/growcomponents';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { DisputeReport } from 'components/CollectionActivites/Disputes/types';
import { getActivityDetails } from 'components/CollectionActivities/activityCardUtils';
import {
  ActivityGroupProps,
  EActivityQueryParams,
} from 'components/HigherOrderComponent/KeyActivitesContainer/type';
import { PaymentListData } from 'store/payments/type';
import { ActivityDetailsBaseProps, ActivityType } from 'types/activities/activity-types';
import { getObjectValues } from 'util/getObjectValues';
import { useEventBus, useEventListener } from 'util/hooks/useCustomEventEmitter';
import HideWrapper from '../Util/HideWrapper';
import useActivityUtils from '../hooks/useActivityUtils';
import { useEntitySearchParams } from '../hooks/useEntitySearchParams';
import useGetActivityLoading from '../hooks/useGetActivityLoading';
import { useOnDeleteActivity } from '../hooks/useOnDeleteActivity';
import useUpdateActivityUrlParams from '../hooks/useUpdateActivityQueryParams';
import { IconButton, StyledActivitiesModal } from './style';
import { ActivitiesPayload, ActivityIdsState, ActivityKeysPayload } from './type';
import { convertIdsToNumber } from './utils';

const CloseIcon = <FontAwesomeIcon icon={['far', 'times']} color="var(--gray-9)" />;
const rightArrow = <FontAwesomeIcon icon={['far', 'arrow-right']} color="var(--gray-9)" />;
const leftArrow = <FontAwesomeIcon icon={['far', 'arrow-left']} color="var(--gray-9)" />;

export default function ActivitiesModal() {
  const { emitEvent } = useEventBus();
  const { deleteActivityListData, isLoading } = useOnDeleteActivity();
  const {
    nextId,
    previousId,
    isLastActivity,
    isFirstActivity,
    isFetchNextActivity,
    setActivityKeysData,
    updateActivityUrlToNextActivity,
    updateActivityUrlParamsToNextPage,
    updateActivityUrlToPreviousActivity,
  } = useUpdateActivityUrlParams();
  const { entitySearchParams, setEntitySearchParams } = useEntitySearchParams();
  const { nextPageLoading, previousPageLoading, isPageLoaded } = useGetActivityLoading(
    previousId,
    nextId
  );
  const { removeActivityQueryParams, openModal } = useActivityUtils(isPageLoaded);
  const totalRecords = entitySearchParams[EActivityQueryParams.TOTAL_RECORDS];

  useEventListener<ActivitiesPayload>('activityKeyData', (data) => {
    const dataValues = getObjectValues<
      ActivityKeysPayload,
      ActivityIdsState,
      ActivityGroupProps[] | PaymentListData | DisputeReport
    >(data.activityData, data.activityKeys);
    const activityKeys = convertIdsToNumber(dataValues);
    setActivityKeysData(activityKeys);
    updateActivityUrlParamsToNextPage(activityKeys);
  });

  function getDetailsProps(): ActivityDetailsBaseProps {
    return {
      id: entitySearchParams[EActivityQueryParams.ACTIVITY_ID],
      type: entitySearchParams[EActivityQueryParams.ACTIVITY_TYPE] as Exclude<
        ActivityType,
        'DOCUMENT'
      >,
      onDelete: deleteActivityListData,
      isListFetched: true,
      customer: {
        id: entitySearchParams[EActivityQueryParams.CUSTOMER_ID],
        name: entitySearchParams[EActivityQueryParams.CUSTOMER],
        customer_no: entitySearchParams[EActivityQueryParams.CUSTOMER_NO],
      },
      deleteLoading: isLoading,
      activitiesMode: true,
      mode: 'inbox',
      queryKey: `feed-activity-${entitySearchParams[EActivityQueryParams.ACTIVITY_TYPE]}`,
    };
  }

  const detailsProps = getDetailsProps();
  const Component = getActivityDetails(detailsProps);

  function onClose() {
    removeActivityQueryParams();
  }

  function onNextPage() {
    if (isFetchNextActivity) {
      emitEvent({ event: 'nextPage', data: { enabled: true } });
      setEntitySearchParams({
        [EActivityQueryParams.ACTIVITY_PAGE]:
          entitySearchParams[EActivityQueryParams.ACTIVITY_PAGE] + 1,
      });
    } else {
      updateActivityUrlToNextActivity();
    }
  }

  function onPreviousPage() {
    updateActivityUrlToPreviousActivity();
  }

  const navigationButtons = (
    <>
      <IconButton
        icon={leftArrow}
        style={{ backgroundColor: 'var(--gray-1)' }}
        onClick={onPreviousPage}
        disabled={!isFirstActivity}
        loading={previousPageLoading}
      />
      <IconButton
        icon={rightArrow}
        style={{ backgroundColor: 'var(--gray-1)' }}
        onClick={onNextPage}
        disabled={isLastActivity}
        loading={nextPageLoading}
      />
    </>
  );

  const activityType = entitySearchParams[EActivityQueryParams.ACTIVITY_TYPE];

  return (
    <StyledActivitiesModal
      open={openModal}
      footer={null}
      width={1180}
      onCancel={onClose}
      centered
      destroyOnClose
      closable={false}
      style={{ maxHeight: '750px' }}
    >
      <Flex gap={'var(--space-4)'}>
        <div
          style={{
            overflow: 'hidden auto',
            height: '650px',
            width: '1150px',
            borderRadius: 'var(--br-3)',
          }}
        >
          {Component}
        </div>
        <Flex style={{ width: '32px' }} gap={'var(--space-4)'} direction="column">
          <GrowButton
            icon={CloseIcon}
            style={{ backgroundColor: 'var(--gray-1)' }}
            onClick={onClose}
          />
          <HideWrapper hide={!(totalRecords && Number(totalRecords > 1))}>
            {navigationButtons}
          </HideWrapper>
        </Flex>
      </Flex>
    </StyledActivitiesModal>
  );
}
