import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CellRenderProps, GrowTypography, defaultCellComponents } from '@sinecycle/growcomponents';
import { DateText } from 'components/BaseComponents/Date/DateText';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { getInvoiceStatusConfig } from 'components/Common/InvoiceStatus/predicate';
import { EActivityQueryParams } from 'components/HigherOrderComponent/KeyActivitesContainer/type';
import { useGetCustomerNameFormatter } from 'hooks/useGetCustomerNameFormatter';
import { PageLink } from 'lib/router';
import { AdminFlaggedInvoice } from 'types/api/admin-dashboard';
import { StatementViews } from 'types/entities/invoice';
import CustomerNameTag from '../Common/CustomerNameTag';
import { StyledFlaggedInvoiceItem } from './style';

const t = {
  by: 'by',
  popover: {
    body: {
      info1: 'Do you want to unflag these',
      info2: ' flagged invoice(s)?',
    },
  },
  unflagInvoice: 'Unflag Invoice',
};

export const FlaggedInvoiceItem = ({ flaggedInvoice }: { flaggedInvoice: AdminFlaggedInvoice }) => {
  const { getCustomerNameFormatter } = useGetCustomerNameFormatter();
  const customerName = getCustomerNameFormatter({
    isUnnamedCustomer: true,
    customerName: flaggedInvoice.customer_name,
    customerNumber: flaggedInvoice.customer_no,
  });
  const Title = (
    <Flex justify="space-between" style={{ width: '100%' }}>
      <PageLink
        appPage="INVOICE_DETAILS"
        pathParams={{ id: flaggedInvoice.invoice_id }}
        style={{ display: 'flex' }}
        searchParams={
          {
            [EActivityQueryParams.CURRENT_SECTION]: StatementViews.ACTIVITIES,
            [EActivityQueryParams.CURRENT_ACTIVITY_TAB]: StatementViews.ALL,
          } as unknown as URLSearchParams
        }
      >
        <GrowTypography.Text style={{ color: 'var(--primary-7)' }} fs="16" strong>
          {flaggedInvoice.invoice_no}
        </GrowTypography.Text>
      </PageLink>
      <Flex.Child>
        {defaultCellComponents.statusCell({
          statusCellProps: {
            getter: getInvoiceStatusConfig,
          },
          value: flaggedInvoice.invoice_status,
        } as CellRenderProps<unknown>)}
      </Flex.Child>
    </Flex>
  );
  const CustomerName = <CustomerNameTag customerNameText={customerName} width="160px" />;
  const CustomerNameSection = (
    <PageLink
      appPage="CUSTOMER_DETAILS"
      pathParams={{ id: flaggedInvoice.customer_id }}
      style={{ display: 'flex' }}
      searchParams={
        {
          [EActivityQueryParams.CURRENT_SECTION]: StatementViews.INVOICES,
          [EActivityQueryParams.CURRENT_VIEW]: StatementViews.OPEN_INVOICES,
        } as unknown as URLSearchParams
      }
    >
      {CustomerName}
    </PageLink>
  );
  const NameSection = (
    <Flex align="center" gap="var(--space-4)">
      <GrowTypography.Text>{t.by}</GrowTypography.Text>
      <GrowTypography.Text
        style={{ maxWidth: '160px' }}
        ellipsis={{ tooltip: flaggedInvoice.flagged_by.first_name }}
        strong
      >
        {flaggedInvoice.flagged_by.first_name}
      </GrowTypography.Text>
    </Flex>
  );
  const FlaggedInvoiceDetail = (
    <Flex direction="column" gap="var(--space-4)" style={{ width: '100%' }}>
      {Title}
      <Flex gap="var(--space-8)" align="baseline">
        {CustomerNameSection}
        {NameSection}
        <DateText
          value={flaggedInvoice.flagged_at}
          textProps={{ style: { color: 'var(--gray-7)' } }}
        />
      </Flex>

      {Boolean(flaggedInvoice.flag_comment?.length) && (
        <GrowTypography.Paragraph
          ellipsis={{ tooltip: flaggedInvoice.flag_comment }}
          style={{ margin: 'var(--space-12) 0 0 0', maxWidth: '480px' }}
        >
          {flaggedInvoice.flag_comment}
        </GrowTypography.Paragraph>
      )}
    </Flex>
  );

  return (
    <StyledFlaggedInvoiceItem gap="var(--space-8)">
      <FontAwesomeIcon
        icon={['far', 'flag']}
        color="var(--volcano-6)"
        style={{ margin: 'var(--space-4) 0 0 0' }}
      />
      {FlaggedInvoiceDetail}
    </StyledFlaggedInvoiceItem>
  );
};
