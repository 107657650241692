import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { GrowText } from 'components/BaseComponents/Typography';
import { InvoicesColumn, InvoicesColumns } from 'components/Common/Invoices/InvoicesColumn';
import { mapInvoicesColumnPredicate } from 'components/Common/Invoices/predicate';
import HideWrapper from 'components/Common/Util/HideWrapper';
import { EActivityQueryParams } from 'components/HigherOrderComponent/KeyActivitesContainer/type';
import { EmailLabels } from 'components/Inbox/Components/EmailLabels';
import { useGetCustomerNameFormatter } from 'hooks/useGetCustomerNameFormatter';
import { PageLink } from 'lib/router';
import { EmailConversation } from 'types/activities/email';
import { StatementViews } from 'types/entities/invoice';

interface HeadingProps {
  latestConversation: EmailConversation;
  hideCustomerDetails?: boolean;
  actions?: React.ReactNode;
}
const CustomerIcon = <FontAwesomeIcon icon={['far', 'briefcase']} color="var(--gray-7)" />;
const InvoicesIcon = <FontAwesomeIcon icon={['far', 'file-invoice']} color="var(--gray-7)" />;
function Heading(props: HeadingProps) {
  const { latestConversation, hideCustomerDetails, actions } = props;
  const { getCustomerNameFormatter } = useGetCustomerNameFormatter();
  function getInvoices(): InvoicesColumns[] {
    if (!latestConversation.invoices?.length) return [];
    return latestConversation.invoices.map(mapInvoicesColumnPredicate);
  }
  const customerNameText = getCustomerNameFormatter({
    customerName: latestConversation.customer.name,
    customerNumber: latestConversation.customer.customer_no,
  });
  const invoices = getInvoices();
  const Title = (
    <GrowText
      ellipsis={{ onEllipsis(ellipsis) {}, tooltip: latestConversation.subject }}
      strong
      style={{ maxWidth: '40vw', margin: 0 }}
      size="var(--fs-20)"
    >
      {latestConversation.subject}
    </GrowText>
  );

  const Customer = (
    <HideWrapper hide={Boolean(hideCustomerDetails)}>
      <Flex gap="--space-4" align="center">
        {CustomerIcon}
        <PageLink
          pathParams={{ id: latestConversation.customer.id ?? '' }}
          appPage="CUSTOMER_DETAILS"
          style={{ display: 'flex', cursor: 'pointer' }}
          searchParams={
            {
              [EActivityQueryParams.CURRENT_SECTION]: StatementViews.INVOICES,
              [EActivityQueryParams.CURRENT_VIEW]: StatementViews.OPEN_INVOICES,
            } as unknown as URLSearchParams
          }
        >
          <GrowText
            ellipsis={{ tooltip: customerNameText }}
            style={{ color: 'var(--primary-7)', maxWidth: '30vw' }}
          >
            {customerNameText}
          </GrowText>
        </PageLink>
      </Flex>
    </HideWrapper>
  );

  const Invoices = latestConversation.invoices?.length ? (
    <Flex gap="--space-4" align="center" style={{ width: '100%' }}>
      {InvoicesIcon}
      <InvoicesColumn
        invoices={invoices}
        style={{ flexDirection: 'row', justifyContent: 'flex-start' }}
      />
    </Flex>
  ) : null;

  return (
    <Row style={{ width: '100%' }} gutter={[8, 8]}>
      <Col span={24}>
        <Row justify="space-between" align="middle" wrap={false}>
          <Col>{Title}</Col>
          <Col>{actions}</Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row justify="space-between" align="middle" wrap={false}>
          <Col>
            <Row gutter={[8, 8]}>
              <Col>{Customer}</Col>
              <Col>{Invoices}</Col>
            </Row>
          </Col>
          <Col>
            <EmailLabels labels={latestConversation.label ? [latestConversation.label] : []} />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export { Heading };
