import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row, Typography } from 'antd';
import { EActivityQueryParams } from 'components/HigherOrderComponent/KeyActivitesContainer/type';
import { useGetCustomerNameFormatter } from 'hooks/useGetCustomerNameFormatter';
import { usePageNavigate } from 'lib/router';
import { useBreadCrumbs } from 'router/layout/Protected/LayoutContext';
import { StatementViews } from 'types/entities/invoice';
import { Amount } from '../../Common/MultiCurrency';
import { AutocompleteCard, SecondaryText } from './style';
import { InvoiceResultsProps } from './types';

const { Text, Link } = Typography;

function InvoiceResults(props: InvoiceResultsProps) {
  const navigate = usePageNavigate();
  const { resetBreadCrumb } = useBreadCrumbs();
  const { getCustomerNameFormatter } = useGetCustomerNameFormatter();

  return (
    <>
      <Row className="my-2">
        <Col span={24}>
          <SecondaryText className="me-2">INVOICES</SecondaryText>
          <Text>{props.invoices.length}</Text>
        </Col>
      </Row>
      {props.invoices.map((invoice, index) => {
        const invoiceNo =
          invoice.highlight.invoice_no && invoice.highlight.invoice_no.length > 0
            ? invoice.highlight.invoice_no[0]
            : invoice.content.invoice_no;
        const invoiceTitle =
          invoice.highlight.invoice_title && invoice.highlight.invoice_title.length > 0
            ? invoice.highlight.invoice_title[0]
            : invoice.content.invoice_title;
        const customerName = getCustomerNameFormatter({
          customerName: invoice.content.customer_name,
          customerNumber: invoice.content?.customer_no,
        });
        return (
          <AutocompleteCard
            className="shadow-sm cursor-pointer my-2"
            key={index}
            onClick={() => {
              resetBreadCrumb();
              navigate({
                appPage: 'INVOICE_DETAILS',
                pathParams: { id: invoice?.content?.id },
                replace: true,
                state: {
                  replaceCurrentPath: true,
                },
                searchParams: {
                  [EActivityQueryParams.CURRENT_SECTION]: StatementViews.ACTIVITIES,
                  [EActivityQueryParams.CURRENT_ACTIVITY_TAB]: StatementViews.ALL,
                } as unknown as URLSearchParams,
              });
            }}
          >
            <Row>
              <Col span={18}>
                <SecondaryText>
                  <span dangerouslySetInnerHTML={{ __html: invoiceNo }} />
                </SecondaryText>
                <br />
                <Link>
                  <span dangerouslySetInnerHTML={{ __html: invoiceTitle }} />
                </Link>
                <br />
                <Text>
                  <Amount
                    amount={invoice.content.amount}
                    noTags
                    currency={invoice.content.currency}
                  />
                </Text>
                <br />
                <Text>
                  Customer
                  <Link className="ms-2">{customerName}</Link>
                </Text>
              </Col>
              <Col span={6} className="text-end">
                {invoice.content.status && (
                  <>
                    <FontAwesomeIcon
                      icon={['fas', 'circle']}
                      color="var(--orange-6)"
                      className="me-2"
                    />
                    <Text className="me-2">{invoice.content.status}</Text>
                  </>
                )}
                <FontAwesomeIcon icon={['far', 'file-invoice']} color="var(--cyan-3)" />
              </Col>
            </Row>
          </AutocompleteCard>
        );
      })}
    </>
  );
}

export default InvoiceResults;
