import { useQueryClient } from '@tanstack/react-query';
import { Empty } from 'components/BaseComponents/Empty/Empty';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import {
  CreatedBlock,
  CustomerBlock,
  LabelBlock,
  UserInfoBlock,
} from 'components/CollectionActivities/Common/Details/BodyBlocks';
import { InfoContent } from 'components/CollectionActivities/Common/Details/Content';
import { handleCreateCustomEventCallBack } from 'components/Inbox/CollectionActivitiesV2/hooks/useActivityUpdation';
import { ActivityDetailsBase, ActivityType } from 'types/activities/activity-types';
import { Escalation } from 'types/activities/escalation';
import { PerformedActionType } from 'types/api/inbox/activity';
import { EscalationStatusQuickSelect } from '../Common/EscalationStatus';

interface EscalationDetailsInfoProps extends ActivityDetailsBase {
  data?: Escalation;
  loading: boolean;
}

function EscalationDetailsInfo(props: EscalationDetailsInfoProps) {
  const { data, loading, queryKey, activitiesMode } = props;
  const queryClient = useQueryClient();

  const Status =
    data && !loading ? (
      <EscalationStatusQuickSelect
        status={data.status}
        escalationActivityId={data.id}
        onChangeCallBack={(value) => {
          queryClient.refetchQueries([queryKey]);

          handleCreateCustomEventCallBack('updating_query', {
            id: String(data.id),
            activity: ActivityType.ESCALATION,
            value: { status: value, action: PerformedActionType.STATUS_CHANGED },
          });
        }}
        activitiesMode={activitiesMode}
      />
    ) : null;

  const EscalatedTo =
    data && !loading ? (
      <Empty isEmpty={!data?.assigned_to_list.length}>
        <Flex direction="column" gap="var(--space-4)">
          {data?.assigned_to_list?.map((value) => {
            return <UserInfoBlock user={value} key={value.id} />;
          })}
        </Flex>
      </Empty>
    ) : null;
  const Created =
    data && !loading ? (
      <CreatedBlock createdBy={data.created_by} createdDate={data.created_date} />
    ) : null;

  const Customer =
    props.customer && !loading ? (
      <CustomerBlock
        id={props.customer.id}
        name={props.customer.name}
        customerNumber={props.customer.customer_no}
      />
    ) : null;

  return (
    <Flex direction="column" gap="var(--space-16)">
      <InfoContent content={Status} label={<LabelBlock label="Status" color="var(--gray-7)" />} />
      <InfoContent
        content={EscalatedTo}
        label={<LabelBlock label="Escalation owners" color="var(--gray-7)" />}
      />
      <InfoContent content={Created} label={<LabelBlock label="Created" color="var(--gray-7)" />} />
      <InfoContent
        content={Customer}
        label={<LabelBlock label="Customer" color="var(--gray-7)" />}
      />
    </Flex>
  );
}

export { EscalationDetailsInfo };
