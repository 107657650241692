import { assign, castArray, filter, get, head, isArray, isObject, uniq } from 'lodash';
import {
  getInvoiceLineItems,
  getInvoicesSuggestions,
  getRecentInvoicesOfCustomer,
} from 'services/invoice';
import { ActivityFeedCollection, IConversationParticipant } from 'store/activity-feed/type';
import { PerformedActionType, SubscribedEntityType } from 'types/api/inbox/activity';
import { User } from 'types/entities/user';
import transformData from 'util/transformData';
import { getActivitySchemaMapping } from './ActivitiesList/Predicate';
import { LatestActivityData, customerDetailsProps } from './type';

export function tranformActivitySchema(
  activity?: ActivityFeedCollection
): SubscribedEntityType | undefined {
  const hasChild = activity?.child;

  const data = activity
    ? transformData<ActivityFeedCollection, SubscribedEntityType>({
        originalData: activity,
        schemaMapping: getActivitySchemaMapping(activity.parent_type, Boolean(hasChild)),
      })
    : null;

  const getLatestActivity = (activity: ActivityFeedCollection | undefined): LatestActivityData => {
    if (get(activity, 'child_type') === 'COMMENT') {
      return {
        type: PerformedActionType.COMMENT_ADDED,
        actor: get(activity, 'child.created_by')!,
        createdDate: get(activity, 'child.created_date') ?? '',
        showAvatar: true,
      };
    } else if (
      get(activity, 'child_type') === 'EMAIL' ||
      get(activity, 'parent_type') === 'EMAIL'
    ) {
      const hasChild = activity?.child;

      const createdBy = hasChild
        ? get(activity.child, 'conversation_participants')
        : get(activity?.parent, 'conversation_participants');
      const createdDate = hasChild
        ? (get(activity.child, 'sent_at') as unknown as string)
        : (get(activity?.parent, 'sent_at') as unknown as string);
      function getCreatedBy() {
        if (createdBy) {
          const participants = head(
            filter(createdBy, (created) => {
              return created.type === 'FROM';
            })
          ) as IConversationParticipant;
          return transformData<IConversationParticipant, User>({
            originalData: participants,
            schemaMapping: {
              first_name: 'first_name',
              last_name: 'last_name',
              email: 'first_name',
            },
          });
        }
      }

      return {
        type: hasChild ? PerformedActionType.REPLY : PerformedActionType.SENT,
        actor: getCreatedBy()!,
        createdDate: createdDate,
        showAvatar: true,
      };
    } else {
      return {
        type: PerformedActionType.CREATED,
        actor: get(activity, 'parent.created_by')!,
        createdDate: get(activity, 'parent.created_date') ?? '',
        showAvatar: true,
      };
    }
  };

  return isObject(data)
    ? assign({}, data, {
        latest_activity: getLatestActivity(activity),
      })
    : undefined;
}

export function lineItemsFn(customerId?: number | number[]) {
  return {
    lineItemsQueryFn: (invoiceIdsList?: number[]) => getInvoiceLineItems(invoiceIdsList),
    lineItemsQueryKey: ['invoiceLineItems', customerId],
  };
}

export function invoiceItemsFn<T>(customerId?: number | number[], viewChild?: boolean) {
  const customerDetails = isArray(customerId) ? customerId : castArray(customerId);
  const customerIdData = head(uniq(customerDetails as unknown as number[]));

  return {
    invoiceQueryFn: () => getRecentInvoicesOfCustomer<T>(customerIdData, viewChild),
    invoiceSearchFn: (searchTerm: string) =>
      getInvoicesSuggestions<T>(searchTerm, customerIdData, viewChild),
    invoiceQueryKey: ['recent-customer-invoices', customerIdData, viewChild],
  };
}

export function destructureWithCustomerInfo(
  activity: ActivityFeedCollection,
  customerDetails?: customerDetailsProps
): ActivityFeedCollection | undefined {
  if (activity.customer_info) {
    return assign({}, activity, {
      parent: assign({}, activity.parent, {
        customer_info: activity.customer_info,
      }),
    });
  }

  return assign({}, activity, {
    parent: assign({}, activity.parent, {
      customer_info: {
        customer_id: customerDetails?.id,
        customer_name: customerDetails?.name,
        customer_no: customerDetails?.customerNumber,
      },
    }),
  });
}
