import { GrowTypography } from '@sinecycle/growcomponents';
import { Texto } from 'components/BaseComponents/Typography/Texto';
import { EllipsisList } from 'components/Common/EllipsisList';
import { useGetCustomerNameFormatter } from 'hooks/useGetCustomerNameFormatter';
import { head } from 'lodash';
import { InvoiceDropdownInterfaceProps } from 'store/invoice/type';
import { BaseCustomer } from 'types/entities/customer';
import { InvoiceBasicDetails } from 'types/entities/invoice';
import { ActionableEntity, InvoiceFollowupAction } from '../Email';
import { CustomerSelect, GroupedInvoicesSelect } from './SelectComponents';
import { isSingleCustomer } from './utils';

interface PreviewHeaderProps {
  mode: ActionableEntity;
  customerList?: BaseCustomer[];
  invoiceList?: InvoiceDropdownInterfaceProps[] | InvoiceBasicDetails[];
  onCustomerSelect: (customerId: number) => void;
  onInvoicesSelect: (customerId: number, invoiceIds: number[]) => void;
  invoiceFollowupAction?: InvoiceFollowupAction;
  selectAll?: boolean;
  totalRecords?: number;
  isCustomerLevelInvoice?: boolean;
}

const mapInvoiceNoPredicate = (invoice: InvoiceDropdownInterfaceProps | InvoiceBasicDetails) =>
  invoice.invoice_no;
/**
 * @returns Select Component or the Text Component based on the scenario
 */
export function PreviewHeader(props: PreviewHeaderProps) {
  const { mode, customerList = [], invoiceList = [], invoiceFollowupAction } = props;
  const name = head(customerList)?.name;
  const customerNumber = head(customerList)?.customer_no;
  const { getCustomerNameFormatter } = useGetCustomerNameFormatter();
  const customerNameText = getCustomerNameFormatter({
    isUnnamedCustomer: true,
    customerName: name,
    customerNumber,
  });
  function handleCustomerSelect(customerId: number) {
    props.onCustomerSelect(customerId);
  }
  function handleInvoicesSelect(customerId: number, invoiceIds: number[]) {
    props.onInvoicesSelect(customerId, invoiceIds);
  }

  if (mode === ActionableEntity.CUSTOMER) {
    // multiple customers
    if (customerList.length > 1) {
      return (
        <CustomerSelect
          customerList={customerList}
          onChange={handleCustomerSelect}
          selectAll={props.selectAll}
          totalRecords={props.totalRecords}
        />
      );
    }

    // single customer
    return name ? (
      <GrowTypography.Text fs="16" strong style={{ width: '800px' }}>
        {customerNameText}
      </GrowTypography.Text>
    ) : null;
  }

  if (mode === ActionableEntity.INVOICE) {
    // multiple invoices

    if (invoiceList.length > 1) {
      const selectedValues = invoiceList.map(mapInvoiceNoPredicate);
      const SelectedInvoices = (
        <EllipsisList
          totalRecords={
            props.selectAll || props.isCustomerLevelInvoice
              ? props.totalRecords
              : selectedValues.length
          }
          list={selectedValues}
          isEmailPreview
          showScrollablePopover
        />
      );
      // invoices belong to the same customer
      if (isSingleCustomer(invoiceList)) {
        const firstCustomer = head(invoiceList as InvoiceBasicDetails[]);
        const customerName =
          invoiceList &&
          getCustomerNameFormatter({
            isUnnamedCustomer: true,
            customerName: firstCustomer?.customer_name,
            customerNumber: firstCustomer?.customer_no,
          });
        return (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
            <Texto size="16" weight="semibold" style={{ whiteSpace: 'nowrap' }} truncate="120px">
              {customerName ? `${customerName}: ` : '--'}
            </Texto>
            {SelectedInvoices}
          </div>
        );
      }

      // invoices belong to multiple customers
      return props.selectAll || props.isCustomerLevelInvoice ? (
        SelectedInvoices
      ) : (
        <GroupedInvoicesSelect
          invoiceList={invoiceList}
          onChange={handleInvoicesSelect}
          invoiceFollowupAction={invoiceFollowupAction}
        />
      );
    }

    // single invoice
    if (invoiceList.length === 1) {
      return (
        <Texto size="16" weight="semibold">
          {invoiceList[0].invoice_no}
        </Texto>
      );
    }

    // no invoice, fallback to displaying customer mode (if there)
    if (customerList.length) {
      return (
        <Texto size="16" weight="semibold">
          {customerNameText}
        </Texto>
      );
    }
  }

  return null;
}
