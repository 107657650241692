import {
  CustomerNameCustomerIdFormatterProps,
  customerNameAndNumberFormatter,
} from '@sinecycle/growcomponents';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { accountConfigSelector } from 'store/authentication/authentication';

export function useGetCustomerNameFormatter() {
  const accountsConfigList = useSelector(accountConfigSelector);
  function getCustomerNameFormatter(options: CustomerNameCustomerIdFormatterProps): string {
    const format = options.format || accountsConfigList?.external_id_config?.format;
    const isFeatureEnabled = accountsConfigList?.external_id_config?.enabled;

    return customerNameAndNumberFormatter({
      format,
      isFeatureEnabled,
      ...options,
    });
  }
  const formatter = useCallback(getCustomerNameFormatter, [
    accountsConfigList?.external_id_config?.enabled,
    accountsConfigList?.external_id_config?.format,
  ]);
  return { getCustomerNameFormatter: formatter };
}
