import { GrowTypography } from '@sinecycle/growcomponents';
import { head } from 'lodash';
import { User } from 'types/entities/user';

interface AssignedToProps {
  user?: User;
}

function AssignedTo(props: AssignedToProps) {
  const { user } = props;
  const role = head(user?.roles);
  return (
    <div className="tw-flex tw-flex-col tw-px-12 tw-bg-gray-1 tw-py-8 tw-border-gray-4 tw-border tw-rounded tw-border-solid">
      <GrowTypography.Text ellipsis={{ tooltip: true }} style={{ maxWidth: '180px' }}>{`${
        user?.first_name ?? ''
      }${user?.last_name ?? ''}`}</GrowTypography.Text>
      <GrowTypography.Text
        fs="12"
        style={{ color: 'var(--gray-7)', maxWidth: '180px' }}
        ellipsis={{ tooltip: true }}
      >
        {role?.display_name}
      </GrowTypography.Text>
      <GrowTypography.Text
        fs="12"
        style={{ color: 'var(--gray-8)', maxWidth: '180px' }}
        ellipsis={{ tooltip: true }}
      >
        {user?.email}
      </GrowTypography.Text>
    </div>
  );
}

export { AssignedTo };
