import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GrowTypography } from '@sinecycle/growcomponents';
import { Divider, List, Skeleton } from 'antd';
import { DateText } from 'components/BaseComponents/Date/DateText';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { GrowText } from 'components/BaseComponents/Typography';
import { VIEW_TYPE } from 'components/Common/hooks/useEntitySearchParams';
import InvoiceDetailsList from 'components/HigherOrderComponent/InvoiceList';
import { EActivityQueryParams } from 'components/HigherOrderComponent/KeyActivitesContainer/type';
import { useGetCustomerNameFormatter } from 'hooks/useGetCustomerNameFormatter';
import { PageLink } from 'lib/router';
import { DashboardComponentProps } from 'pages/Dashboard/types';
import { usePendingIssuesQuery } from 'queries/admin-dashboard';
import React from 'react';
import { ELastContactActivity } from 'store/activity-feed/type';
import { LongPendingIssue } from 'types/api/admin-dashboard';
import { StatementViews } from 'types/entities/invoice';
import CustomerNameTag from '../Common/CustomerNameTag';
import { dashboardTexts } from '../texts';

const componentTexts = dashboardTexts.pendingIssues;
const SinceText = (
  <GrowTypography.Text type="secondary">{componentTexts.since}</GrowTypography.Text>
);
function PendingIssues({ filterParams }: DashboardComponentProps) {
  const { data: longPendingIssues, isLoading: loading } = usePendingIssuesQuery({
    params: filterParams,
  });

  if (loading) return <Skeleton />;
  if (!longPendingIssues) return null;

  return (
    <>
      <GrowTypography.Text fs="20" strong>
        {componentTexts.title}
      </GrowTypography.Text>
      <Divider style={{ color: 'var(--gray-8)', margin: '16px 0 12px' }} />
      <List
        itemLayout="horizontal"
        dataSource={longPendingIssues}
        renderItem={(item) => <PendingIssue issue={item} />}
      />
    </>
  );
}

function PendingIssue({ issue }: { issue: LongPendingIssue }) {
  const DateSinceText = `${Math.round(
    (new Date().getTime() - new Date(issue.created_at).getTime()) / (1000 * 60 * 60 * 24)
  )} days old`;
  const { getCustomerNameFormatter } = useGetCustomerNameFormatter();
  let icon: IconProp = ['far', 'comment-times'];
  const customerNameText = getCustomerNameFormatter({
    isUnnamedCustomer: true,
    customerName: issue.customer.name,
    customerNumber: issue.customer.customer_no,
  });
  switch (issue.type) {
    case ELastContactActivity.ESCALATION:
      icon = ['far', 'exclamation-circle'];
      break;
    case ELastContactActivity.CALL_LOG:
      break;
    case ELastContactActivity.EMAIL:
      icon = ['far', 'comment-times'];
      break;
  }

  const CreatedDate = (
    <DateText
      value={issue.created_at}
      textProps={{
        style: { color: 'var(--gray-8)' },
      }}
    />
  );
  const customerDetailsPageUrlSearchParams = new URLSearchParams({
    [EActivityQueryParams.CURRENT_ACTIVITY_TAB]: issue.type,
    [EActivityQueryParams.ACTIVITY_ID]: String(issue.id),
    [EActivityQueryParams.CURRENT_SECTION]: StatementViews.ACTIVITIES,
    [EActivityQueryParams.ACTIVITY_TYPE]: issue.type,
    [EActivityQueryParams.VIEW]: VIEW_TYPE.MODAL,
    [EActivityQueryParams.CUSTOMER]: issue.customer.name,
  });
  function constructParam() {
    if (issue.customer.id) {
      customerDetailsPageUrlSearchParams.set(
        EActivityQueryParams.CUSTOMER_ID,
        String(issue.customer.id)
      );
    }
    if (issue.customer.customer_no) {
      customerDetailsPageUrlSearchParams.set(
        EActivityQueryParams.CUSTOMER_NO,
        issue.customer.customer_no
      );
    }
    return customerDetailsPageUrlSearchParams;
  }
  const urlSearchParam = constructParam().toString();
  const DaySince = (
    <GrowTypography.Text style={{ color: 'var(--volcano-7)' }}>{DateSinceText}</GrowTypography.Text>
  );
  const Issue = (
    <Flex direction="column" gap="var(--space-4)">
      <Flex gap="var(--space-8)">
        {DaySince}
        {SinceText}
        {CreatedDate}
      </Flex>
      <GrowTypography.Text
        fs="16"
        strong
        style={{ maxWidth: '400px' }}
        ellipsis={{ tooltip: issue.title }}
      >
        {issue.title}
      </GrowTypography.Text>
    </Flex>
  );
  const ViewThread = (
    <PageLink
      key={1}
      appPage="CUSTOMER_DETAILS"
      pathParams={{ id: issue.customer.id }}
      searchParams={urlSearchParam}
      state={{ addPrimaryPathAsChildPath: true }}
    >
      {componentTexts.viewThread}
    </PageLink>
  );
  const IssueTitle = (
    <Flex
      justify={'space-between'}
      style={{ marginBottom: 'var(--space-4)', fontWeight: 'normal' }}
    >
      {Issue}
      {ViewThread}
    </Flex>
  );

  const CustomerNameLink = (
    <PageLink
      appPage="CUSTOMER_DETAILS"
      pathParams={{ id: issue.customer.id }}
      state={{ addPrimaryPathAsChildPath: true, customer_id: issue.id }}
      searchParams={urlSearchParam}
    >
      <CustomerNameTag customerNameText={customerNameText} width="120px" />
    </PageLink>
  );

  const ActorName = (
    <Flex align="center" gap="var(--space-4)">
      <GrowText>{componentTexts.by}</GrowText>
      <GrowTypography.Text
        style={{ maxWidth: '120px' }}
        ellipsis={{ tooltip: issue.created_by.first_name }}
        strong
      >
        {issue.created_by.first_name}
      </GrowTypography.Text>
    </Flex>
  );

  const InvoiceName = (
    <Flex align="center" gap={'var(--space-4)'}>
      <GrowTypography.Text style={{ color: 'var(--gray-8)' }}>
        {componentTexts.invoiceCountLabel}
      </GrowTypography.Text>
      <InvoiceDetailsList
        invoiceList={issue.invoices}
        openNewPage={true}
        titleWidth={'120px'}
        rowCount={1}
      />
    </Flex>
  );

  const Content = (
    <Flex gap="var(--space-12)" align="baseline">
      {CustomerNameLink}
      {ActorName}
      {InvoiceName}
    </Flex>
  );

  const IssueDescription = (
    <Flex direction="column" gap="var(--space-12)">
      {Content}
      <GrowTypography.Paragraph
        ellipsis={{ tooltip: issue.description }}
        style={{ marginBottom: 0, maxWidth: '480px' }}
      >
        {issue.description}
      </GrowTypography.Paragraph>
    </Flex>
  );

  return (
    <List.Item style={{ marginBottom: 'var(--space-24)', alignItems: 'start' }}>
      <List.Item.Meta
        avatar={<FontAwesomeIcon icon={icon} color="var(--red-7)" size="lg" />}
        title={IssueTitle}
        description={IssueDescription}
      />
    </List.Item>
  );
}

export default React.memo(PendingIssues);
