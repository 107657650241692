import { useMutation } from '@tanstack/react-query';
import { useParams } from 'lib/router';
import { getAttachmentUrl } from 'services/attachments';
import { getAttachmentAnonUrl } from './Approvals/approval';

export function useFilePreviewQuery(isAnonymous?: boolean) {
  return useMutation({
    mutationKey: ['file-preview'],
    mutationFn: ({ id, version }: { id: string | number; version?: string }) =>
      getAttachmentUrl(id, version),
    onSuccess(data) {},
    onError() {},
  });
}
export function useAnonFilePreviewQuery() {
  const { hash } = useParams();
  return useMutation({
    mutationKey: ['file-preview'],
    mutationFn: (id: string | number) => getAttachmentAnonUrl(id, hash),
  });
}
