import { ErrorBoundary } from '@sentry/react';
import { Outlet } from 'lib/router';
import { Suspense } from 'react';
import { PageSuspenseFallback } from 'router/PageContainer';

function UnauthenticatedLayout() {
  return (
    <ErrorBoundary>
      <Suspense fallback={<PageSuspenseFallback />}>
        <Outlet />
      </Suspense>
    </ErrorBoundary>
  );
}

export { UnauthenticatedLayout };
