import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { GrowText } from 'components/BaseComponents/Typography';
import { EntityInvoiceList } from 'components/CollectionActivities/Common/Details/BodyBlocks';
import HideWrapper from 'components/Common/Util/HideWrapper';
import { useGetCustomerNameFormatter } from 'hooks/useGetCustomerNameFormatter';
import { ActivityType } from 'types/activities/activity-types';
import { ActivityDetail } from 'types/api/inbox/activity';

interface CustomerAndInvoiceProps {
  customer?: ActivityDetail<ActivityType>['customer'];
  invoices?: ActivityDetail<ActivityType>['invoices'];
  activitiesMode?: boolean;
}

const CustomerIcon = (
  <FontAwesomeIcon icon={['far', 'briefcase']} fontSize={12} color="var(--gray-7)" />
);

const InvoiceIcon = (
  <FontAwesomeIcon icon={['far', 'file-invoice']} fontSize={12} color="var(--gray-7)" />
);

function CustomerAndInvoice(props: CustomerAndInvoiceProps) {
  const { customer, invoices, activitiesMode } = props;
  const { getCustomerNameFormatter } = useGetCustomerNameFormatter();
  const customerNameText = getCustomerNameFormatter({
    customerName: customer?.name,
    customerNumber: customer?.customer_no,
  });
  const Customer = (
    <>
      {customer ? (
        <Flex align="center" gap="var(--space-4)">
          {CustomerIcon}
          <GrowText ellipsis color="var(--gray-8)">
            {customerNameText}
          </GrowText>
        </Flex>
      ) : null}
    </>
  );

  const Invoice = (
    <Flex align="center" gap="var(--space-4)">
      {InvoiceIcon}
      <div onClick={(e) => e.stopPropagation()}>
        {invoices?.length ? <EntityInvoiceList invoices={invoices} /> : null}
      </div>
    </Flex>
  );

  return (
    <Row gutter={[8, 8]} justify="space-between">
      <HideWrapper hide={Boolean(activitiesMode)}>
        <Col span={24}>{Customer}</Col>
      </HideWrapper>
      <HideWrapper hide={!(activitiesMode && invoices?.length)}>
        <Col span={24}>{Invoice}</Col>
      </HideWrapper>
    </Row>
  );
}

export { CustomerAndInvoice };
