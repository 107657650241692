import { CustomerNameCustomerIdFormatterProps } from '@sinecycle/growcomponents';
import { notify } from 'components/BaseComponents/Notifications';
import {
  previewEmailForCustomer,
  previewEmailForInvoice,
  previewEmailForInvoicesList,
} from 'services/email';
import { InvoiceDropdownInterfaceProps } from 'store/invoice/type';
import { Emailable } from 'types/activities/email';
import { InvoiceBasicDetails } from 'types/entities/invoice';
import { t } from './text';

interface BasePreviewArgs {
  subject?: string;
  body?: string;
  from: Emailable;
}

interface CustomerPreviewArgs extends BasePreviewArgs {
  id: number;
}

export async function getSubjectAndBodyForPreviewCustomer(previewArgs: CustomerPreviewArgs) {
  const { id, subject = '', body = '', from } = previewArgs;

  try {
    const data = await previewEmailForCustomer(id, subject, body, from);
    return {
      subject: data.subject,
      body: data.body,
    };
  } catch (e) {
    notify.error(t.previewError.error, {
      description: t.previewError.description,
    });
  }
}

interface InvoicePreviewArgs extends BasePreviewArgs {
  invoiceIds: number[];
  mode?: 'single_invoice' | 'bulk_invoice';
}
export async function getSubjectAndBodyForPreviewInvoices(previewArgs: InvoicePreviewArgs) {
  const { invoiceIds, subject = '', body = '', from, mode = 'single_invoice' } = previewArgs;

  if (!invoiceIds.length) return;

  try {
    if (invoiceIds.length === 1 && mode === 'single_invoice') {
      const data = await previewEmailForInvoice(invoiceIds[0], subject, body, from);
      return {
        subject: data.subject,
        body: data.body,
      };
    } else {
      const data = await previewEmailForInvoicesList(invoiceIds, subject, body, from);
      return {
        subject: data.subject,
        body: data.body,
      };
    }
  } catch (e) {
    notify.error(t.previewError.error, {
      description: t.previewError.description,
    });
  }
}

export function isSingleCustomer(
  invoiceList: InvoiceDropdownInterfaceProps[] | InvoiceBasicDetails[]
) {
  if (!invoiceList.length) {
    return false;
  }

  const firstInvoiceCustomerId = invoiceList[0].customer_id;

  for (const invoice of invoiceList) {
    if (invoice.customer_id !== firstInvoiceCustomerId) {
      return false;
    }
  }

  return true;
}

export function groupInvoicesByCustomer(
  invoiceList: InvoiceDropdownInterfaceProps[] | InvoiceBasicDetails[],
  getCustomerNameFormatter: (options: CustomerNameCustomerIdFormatterProps) => string
) {
  const uniqueCustomers = [
    ...new Set(
      invoiceList.map((invoice) => ({
        id: invoice.customer_id,
        name: getCustomerNameFormatter({
          isUnnamedCustomer: true,
          customerName: invoice.customer_name,
          customerNumber: invoice.customer_no,
        }),
      }))
    ),
  ];

  const invoicesByCustomers: Record<
    string,
    {
      id: number;
      name?: string;
      invoices: { invoice_no: string; invoice_id: number }[];
    }
  > = {};

  uniqueCustomers.forEach((customer) => {
    invoicesByCustomers[customer.id] = {
      id: customer.id,
      name: customer?.name,
      invoices: [],
    };
  });

  invoiceList.forEach((invoice) => {
    invoicesByCustomers[invoice.customer_id].invoices.push({
      invoice_no: invoice.invoice_no,
      invoice_id: invoice.id,
    });
  });

  return Object.values(invoicesByCustomers);
}
