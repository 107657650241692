import { Modal, ModalFuncProps, Popconfirm, PopconfirmProps } from 'antd';
import { TooltipPlacement } from 'antd/es/tooltip';
import React from 'react';

type ClickHander = (e?: React.MouseEvent<HTMLElement, MouseEvent> | undefined) => void;

interface ButtonWithConfirmationProps {
  onConfirm: ClickHander;
  onCancel?: ClickHander;
  children: React.ReactNode;
  popConfirmOverrides?: Partial<PopconfirmProps>;
  title?: string;
  message?: string;
  subtext?: string;
  popupContent?: React.ReactNode;
  disabled?: boolean;
  placement?: TooltipPlacement;
  showArrow?: boolean;
  hideWarningIcon?: boolean;
}

function getTitleMessage(title: string, message: string, subtext?: string) {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
      {title && <b className="text-14">{title}</b>}
      <span>{message}</span>
      {subtext && (
        <span className="text-12" style={{ color: '#333' }}>
          {subtext}
        </span>
      )}
    </div>
  );
}

interface CtaWithConfirmationProps extends ButtonWithConfirmationProps {}
export function CtaWithConfirmation(props: CtaWithConfirmationProps) {
  const {
    onConfirm,
    onCancel,
    children,
    disabled,
    popConfirmOverrides,
    placement = 'topLeft',
    showArrow = true,
  } = props;

  const Title =
    props.popupContent ?? getTitleMessage(props.title ?? '', props.message ?? '', props.subtext);

  return (
    <Popconfirm
      disabled={disabled}
      title={Title}
      overlayStyle={{ maxWidth: '18vw' }}
      placement={placement}
      okText="Yes"
      cancelText="Cancel"
      cancelButtonProps={{ type: 'text' }}
      onConfirm={onConfirm}
      onCancel={onCancel}
      showArrow={showArrow}
      icon={props.hideWarningIcon && null}
      overlayClassName={props.hideWarningIcon ? 'ant-popover-title-no-padding' : undefined}
      {...popConfirmOverrides}
    >
      {children}
    </Popconfirm>
  );
}

interface CtaWithConfirmationModalProps {
  onConfirm: (close?: Function) => void;
  onCancel?: (close?: Function) => void;
  okText?: string;
  cancelText?: string;
  title?: string;
  message?: string;
  subtext?: string;
  modalContent?: React.ReactNode;
  overrides?: ModalFuncProps;
}
const { confirm } = Modal;
export function openConfirmModal(props: CtaWithConfirmationModalProps) {
  confirm({
    title: props.title,
    content: props.modalContent ?? getTitleMessage('', props.message ?? '', props.subtext),
    onOk: props.onConfirm,
    onCancel: props.onCancel,
    okText: props.okText ?? 'Yes',
    okButtonProps: { style: { backgroundColor: 'var(--primary-7)' } },
    mask: true,
    maskClosable: true,
    cancelButtonProps: { type: 'text' },
    cancelText: props.cancelText ?? 'Cancel',
    ...props.overrides,
  });
}
