import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavigatableProps } from 'lib/router';
import { CSSProperties, ReactElement } from 'react';
import { PageDeet } from 'router/constants/page-info';
import { CustomerSearchResults, InvoiceSearchResults } from 'store/search/types';
import { StatementViews } from 'types/entities/invoice';
import { EActivityQueryParams } from '../KeyActivitesContainer/type';

const iconStyle: CSSProperties = {
  width: 'var(--space-16)',
  textAlign: 'center',
};
export interface SpotlightResultItem {
  title: string;
  type: 'CUSTOMER' | 'INVOICE' | 'ROUTE';
  link: NavigatableProps;
  id: string;
  icon: ReactElement;
}

export function fromCustomer(
  customer: CustomerSearchResults['customers'][number],
  customerName: string
): SpotlightResultItem {
  return {
    title: customerName,
    type: 'CUSTOMER',
    id: '' + customer.content.id,
    link: {
      appPage: 'CUSTOMER_DETAILS',
      pathParams: {
        id: customer.content.id,
      },
      searchParams: {
        [EActivityQueryParams.CURRENT_SECTION]: StatementViews.INVOICES,
        [EActivityQueryParams.CURRENT_VIEW]: StatementViews.OPEN_INVOICES,
      } as unknown as URLSearchParams,
    },
    icon: <FontAwesomeIcon style={iconStyle} icon={['far', 'briefcase']} />,
  };
}

export function fromInvoice(
  invoice: InvoiceSearchResults['invoices'][number]
): SpotlightResultItem {
  return {
    title: invoice.content.invoice_no,
    type: 'INVOICE',
    id: '' + invoice.content.id,
    link: {
      appPage: 'INVOICE_DETAILS',
      pathParams: {
        id: invoice.content.id,
      },
      searchParams: {
        [EActivityQueryParams.CURRENT_SECTION]: StatementViews.ACTIVITIES,
        [EActivityQueryParams.CURRENT_ACTIVITY_TAB]: StatementViews.ALL,
      } as unknown as URLSearchParams,
    },
    icon: <FontAwesomeIcon style={iconStyle} icon={['far', 'file-lines']} />,
  };
}

export function fromRoute(route: PageDeet): SpotlightResultItem {
  return {
    title: route.title,
    type: 'ROUTE',
    id: route.path,
    link: {
      navigationType: 'original',
      TO_PROPS: {
        to: route.path,
      },
    },
    icon: <FontAwesomeIcon style={iconStyle} icon={['far', 'window']} />,
  };
}
