import React from 'react';
import { Helmet as ReactHelmet } from 'react-helmet';
import { IS_DEMO_ZUROA } from 'util/http-utils';

/* Helmet wraps elements inside it and places it
on the <head> tag of the document */
const PRODUCT_TITLE = IS_DEMO_ZUROA ? '' : 'Growfin';

function titleFormatter(tags: string | string[]): string {
  const list = Array.isArray(tags) ? tags : [tags];
  return IS_DEMO_ZUROA ? [...list].join('') : [...list, PRODUCT_TITLE].join(' - ');
}

interface HelmetProps {
  children?: React.ReactNode;
  title?: string[] | string;
}

export default function Helmet(props: HelmetProps) {
  return (
    <ReactHelmet>
      <title>{titleFormatter(props.title ?? '')}</title>
      {props.children}
    </ReactHelmet>
  );
}
