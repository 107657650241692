import { GrowTypography } from '@sinecycle/growcomponents';
import { PageNavLink } from 'lib/router';
import { CSSProperties } from 'react';
import styled from 'styled-components';

export const sidebarComponentStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '0 var(--space-12)',
};

export const navStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 'var(--space-24)',
  marginTop: 'var(--space-16)',
  paddingInline: 'var(--space-4)',
};

export const expandedIconStyle: CSSProperties = {
  position: 'absolute',
  top: '14px',
  right: '-10px',
};

export const StyledNavLink = styled(PageNavLink)`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: var(--space-4);
  width: 42px;
  height: 42px;

  &.text {
    color: var(--gray-1);
  }

  & > .label {
    color: var(--gray-1);
  }
  & > .icon {
    border: 1px solid transparent;
    padding: var(--space-8);
    border-radius: var(--br-1);
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--gray-1);
  }
  &.active {
    & > .icon {
      background-color: var(--side-bar-active-icon);
    }
    & > .label {
      color: var(--primary-7);
    }
    &.text {
      color: var(--primary-4);
    }
  }
  &:hover:not(.active) > .icon {
    border-color: var(--side-bar-active-icon);
  }
`;

export const StyledGroupedNavLink = styled(StyledNavLink)`
  color: var(--gray-1);
  cursor: pointer;
  position: relative;
  width: 42px;
  height: 42px;
`;

export const PopOverLink = styled(PageNavLink)`
  padding: var(--space-8) var(--space-16);
  border-radius: var(--br-1);
  text-decoration: none;
  color: var(--primary-10);

  &:hover {
    color: var(--primary-10);
  }

  &.active {
    color: var(--gray-1);
    background-color: var(--side-bar-active);
  }

  &:hover:not(.active) {
    background-color: var(--gray-3);
  }
`;

export const PopOverLinkCollapse = styled(PageNavLink)`
  text-decoration: none;
  color: var(--primary-10);

  &:hover {
    color: var(--primary-10);
  }
`;
export const PopOverTitle = styled(GrowTypography.Text)`
  margin-bottom: var(--space-4);
  font-size: var(--fs-12);
  line-height: var(--space-20);
`;
