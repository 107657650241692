import { amountFormats, GrowTypography, StatusTag } from '@sinecycle/growcomponents';
import { Descriptions, DescriptionsProps } from 'antd';
import {
  CustomerBlock,
  DateBlock,
} from 'components/CollectionActivities/Common/Details/BodyBlocks';
import { getInvoiceStatusConfig } from 'components/Common/InvoiceStatus/predicate';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ApprovalDocuments } from 'components/Approvals/Attachments/ApprovalDocuments';
import { EActivityQueryParams } from 'components/HigherOrderComponent/KeyActivitesContainer/type';
import { ActionTypeIconContainer } from 'components/Settings/WorkFlowAutomation/CustomAutomations/Action/style';
import { PageLink } from 'lib/router';
import { get } from 'lodash';
import { ApprovalRequestDVDTO } from 'pages/Approvals/type';
import { EventContext } from 'types/common/events';
import { StatementViews } from 'types/entities/invoice';
import { SnapShotContent } from '../SnapShotContent';

interface InvoiceSnapshotProps {
  value: EventContext;
  dateFormat?: string;
  locale?: string;
  baseCurrency?: string;
  isAnonymous?: boolean;
  attachments?: ApprovalRequestDVDTO['attachments'];
  hash?: string;
}
function InvoiceSnapshot(props: InvoiceSnapshotProps) {
  const { value, dateFormat, locale, isAnonymous, attachments, baseCurrency, hash } = props;

  const invoiceContext = get(value, 'invoice');
  const Icon = (
    <ActionTypeIconContainer backgroundColor={'var(--geekblue-1)'}>
      <FontAwesomeIcon icon={['far', 'file-lines']} color="var(--geekblue-5)" />
    </ActionTypeIconContainer>
  );
  const invoiceDetails: DescriptionsProps['items'] = [
    {
      key: 'CUSTOMER_NAME',
      label: 'Customer Name',
      children: <CustomerBlock id={value.customer.id} name={value.customer.name} />,
    },
    {
      key: 'AMOUNT',
      label: 'Amount Due',
      children: (
        <GrowTypography.Text>
          {amountFormats.currency(invoiceContext.balance_due_amount_base_currency, {
            currency: baseCurrency ?? 'USD',
            locale,
          })}
        </GrowTypography.Text>
      ),
    },
    {
      key: 'ISSUE_DATE',
      label: 'Issue date',
      children: <DateBlock dateString={invoiceContext.issue_date} format={dateFormat} dateOnly />,
    },
    {
      key: 'DUE_DATE',
      label: 'Due date',
      children: <DateBlock dateString={invoiceContext.due_date} format={dateFormat} dateOnly />,
    },
    {
      key: 'STATUS',
      label: 'Status',
      span: 2,
      children: (
        <StatusTag
          text={getInvoiceStatusConfig(invoiceContext.status).label}
          style={{
            borderColor: '',
            backgroundColor: getInvoiceStatusConfig(invoiceContext.status).style.backgroundColor,
            textColor: getInvoiceStatusConfig(invoiceContext.status).style.textColor,
          }}
        />
      ),
    },
    {
      key: 'DOCUMENTS',
      label: 'Documents',
      children: (
        <ApprovalDocuments isAnonymous={isAnonymous} attachments={attachments} hash={hash} />
      ),
    },
  ];

  const Details = (
    <Descriptions
      items={invoiceDetails}
      column={2}
      colon={false}
      layout="vertical"
      contentStyle={{
        paddingBottom: 'var(--space-12)',
      }}
    />
  );

  const Label = (
    <PageLink
      appPage="INVOICE_DETAILS"
      pathParams={{ id: invoiceContext.id }}
      style={{ color: 'var(--primary-7)', width: 'fit-content' }}
      className="tw-flex tw-items-center"
      target="_blank"
      searchParams={
        {
          [EActivityQueryParams.CURRENT_SECTION]: StatementViews.INVOICES,
          [EActivityQueryParams.CURRENT_VIEW]: StatementViews.OPEN_INVOICES,
        } as unknown as URLSearchParams
      }
    >
      <div className="tw-flex tw-items-center tw-gap-8" style={{ height: '100%' }}>
        <GrowTypography.Text fs="16" style={{ color: 'var(--primary-7)' }} strong>
          {invoiceContext.invoice_no}
        </GrowTypography.Text>
        <FontAwesomeIcon icon={['far', 'arrow-up-right-from-square']} color="var(--primary-7)" />
      </div>
    </PageLink>
  );

  return (
    <SnapShotContent
      defaultActiveKey={['DETAILS']}
      collapsible="disabled"
      expandIcon={() => {
        return Icon;
      }}
      items={[
        {
          key: 'DETAILS',
          label: Label,
          children: Details,
        },
      ]}
    />
  );
}

export { InvoiceSnapshot };
