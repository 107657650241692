import { useSelector } from 'react-redux';
import { accountConfigSelector } from 'store/authentication/authentication';
import { AccountConfigKey } from 'types/entities/account';
import { CustomDetailConfig } from 'types/entities/custom-detail';

type configs = AccountConfigKey[] | AccountConfigKey;

interface ConfigCompProps {
  children: JSX.Element;
  configs: configs;
}

export function useConfig(configName: configs): boolean {
  const configNames = Array.isArray(configName) ? configName : [configName];

  const configList = useSelector(accountConfigSelector);

  return configNames.every((config) => configList?.[config] ?? false);
}

export function useCustomDetailConfig() {
  const configList = useSelector(accountConfigSelector);
  const detailConfig = configList?.custom_customer_details_config as CustomDetailConfig[];
  return detailConfig && Boolean(detailConfig.length) ? detailConfig[0] : undefined;
}
