import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Drawer } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { Texto } from 'components/BaseComponents/Typography/Texto';
import EmailPreview from 'components/HigherOrderComponent/EmailPreview';
import { EmailPreviewItem } from 'components/HigherOrderComponent/EmailPreview/types';
import { useEffect, useState } from 'react';
import { InvoiceDropdownInterfaceProps } from 'store/invoice/type';
import styled from 'styled-components';
import { Emailable } from 'types/activities/email';
import { BaseCustomer } from 'types/entities/customer';
import { InvoiceBasicDetails } from 'types/entities/invoice';
import { ActionableEntity, InvoiceFollowupAction } from '../Email';
import { PreviewHeader } from './PreviewHeader';
import { t } from './text';
import { useEmailPreview } from './useEmailPreview';
import { getSubjectAndBodyForPreviewCustomer, getSubjectAndBodyForPreviewInvoices } from './utils';

interface IPreviewProps {
  previewDrawerVisible: boolean;
  onPreviewDrawerClose: () => void;
  invoiceList?: InvoiceDropdownInterfaceProps[] | InvoiceBasicDetails[];
  emailFor?: ActionableEntity;
  customerList?: BaseCustomer[];
  subject?: string;
  body?: string;
  to: Emailable[];
  from: Emailable;
  invoiceFollowupAction?: InvoiceFollowupAction;
  selectAll?: boolean;
  totalRecords?: number;
  isCustomerLevelInvoice?: boolean;
}

const StyledButton = styled(Button)`
  border: 0;
  background: none;
  box-shadow: none;
`;

const PreviewDrawer = (props: IPreviewProps) => {
  const { invoiceList, customerList } = props;
  const { from, to, subject, body } = props;
  const { previewDrawerVisible, onPreviewDrawerClose } = props;
  const { emailFor = customerList ? ActionableEntity.CUSTOMER : ActionableEntity.INVOICE } = props;
  const [previewContent, setPreviewContent] = useState<EmailPreviewItem>();

  const { customer, invoices, setCustomer, setInvoices } = useEmailPreview({
    invoiceList,
    customerList,
    emailFor,
  });

  useEffect(() => {
    function handlePreviewFetch(data?: { subject: string; body: string }) {
      if (data) {
        setPreviewContent({
          to: to ?? [],
          from: from ?? {},
          subject: data.subject,
          body: data.body,
        });
      }
    }

    if (previewDrawerVisible) {
      if (invoices.length) {
        const mode =
          invoiceList?.length && invoiceList?.length > 1 ? 'bulk_invoice' : 'single_invoice';

        getSubjectAndBodyForPreviewInvoices({ invoiceIds: invoices, subject, body, from, mode })
          .then(handlePreviewFetch)
          .catch((e) => console.log(e));
      } else if (customer) {
        getSubjectAndBodyForPreviewCustomer({ id: customer, subject, body, from })
          .then(handlePreviewFetch)
          .catch((e) => console.log(e));
      }
    }
  }, [
    emailFor,
    previewDrawerVisible,
    invoiceList?.length,
    customer,
    invoices,
    from,
    subject,
    to,
    body,
  ]);

  function onPreviewClose() {
    setPreviewContent(undefined);
    setCustomer(undefined);
    setInvoices([]);
    onPreviewDrawerClose();
  }

  const ExtraHeader = (
    <Flex gap="var(--space-16)" align="center" justify="flex-end">
      <Flex style={{ width: '400px', textAlign: 'right' }} justify="flex-end" align="center">
        <PreviewHeader
          customerList={customerList}
          invoiceList={invoiceList}
          mode={emailFor}
          onCustomerSelect={(customerId) => setCustomer(customerId)}
          onInvoicesSelect={(customerId, invoiceIds) => {
            setCustomer(customerId);
            setInvoices(invoiceIds);
          }}
          invoiceFollowupAction={props.invoiceFollowupAction}
          selectAll={props.selectAll}
          totalRecords={props.totalRecords}
          isCustomerLevelInvoice={props.isCustomerLevelInvoice}
        />
      </Flex>
      <StyledButton
        icon={<FontAwesomeIcon icon={['fal', 'times']} color="var(--purple-6)" />}
        onClick={onPreviewClose}
      />
    </Flex>
  );

  const DrawerTitle = (
    <Flex justify="space-between" align="center">
      <Texto weight="semibold" size="16">
        {t.emailPreviewTitle}
      </Texto>
      {ExtraHeader}
    </Flex>
  );

  return (
    <Drawer
      title={DrawerTitle}
      width={'var(--compose-email-width)'}
      closable={false}
      onClose={onPreviewClose}
      open={previewDrawerVisible}
      styles={{ body: { overflowX: 'hidden' } }}
    >
      <EmailPreview
        emailPreview={previewContent as EmailPreviewItem}
        users={[]}
        internalContacts={[]}
        action={undefined}
      />
    </Drawer>
  );
};

export default PreviewDrawer;
