import { App as AntdApp, ConfigProvider } from 'antd';
import { ZoroLogoFav } from 'assets/image-urls';
import useTheme from 'hooks/use-theme';
import { Suspense } from 'react';
import { Helmet } from 'react-helmet';
import { useAppInit } from 'router/hooks/useAppInit';
import { PageSuspenseFallback } from 'router/PageContainer';
import { AppRoutes } from 'router/Router';
import { IS_DEMO_ZUROA } from 'util/http-utils';

function App() {
  useAppInit();
  const { config } = useTheme();

  return (
    <ConfigProvider theme={config}>
      <Suspense fallback={<PageSuspenseFallback />}>
        <AntdApp>
          {IS_DEMO_ZUROA ? (
            <Helmet>
              <link rel="icon" type="image/svg+xml" href={ZoroLogoFav} />
            </Helmet>
          ) : null}
          <AppRoutes />
        </AntdApp>
      </Suspense>
    </ConfigProvider>
  );
}

export default App;
