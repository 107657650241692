import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Popover, Tooltip } from 'antd';
import { TooltipPlacement } from 'antd/es/tooltip';
import { GrowText } from 'components/BaseComponents/Typography';
import { Insert, InsertProps } from 'components/Insert';
import { useState } from 'react';
import styled from 'styled-components';
import { EmailTemplates } from 'types/entities/email-templates';

const t = {
  insert: 'Insert',
  insertTooltip: 'Insert Placeholder',
};

const OverlayInnerStyles: React.CSSProperties = {
  boxShadow: 'var(--shadow-3)',
  borderRadius: 'var(--br-3)',
  padding: 0,
};

const StyledInsertButton = styled(Button)`
  padding: var(--space-0) var(--space-4);

  .ant-typography {
    color: var(--primary-7);
  }
  &:hover {
    background: none;
  }
`;

export type InsertArea = 'EMAIL' | 'TEMPLATE';
export type PopoverAlign = React.ComponentProps<typeof Popover>['align'];
interface EmailPlaceholdersProps extends Omit<InsertProps, 'onInsert' | 'cancel'> {
  onPlaceholderInsert: (placeholder: string) => void;
  alignConfig?: PopoverAlign;
  placement?: TooltipPlacement;
  area?: InsertArea;
}

export function useEmailPlaceholders({
  onTemplateSelect,
  validFolders,
  folderType,
  placeHoldersWrapperOverrides,
  onPlaceholderInsert,
  alignConfig,
  placement,
  area = 'EMAIL',
}: EmailPlaceholdersProps) {
  const [placeholderVisible, setPlaceholderVisible] = useState(false);

  function togglePlaceholderVisibility(open: boolean) {
    setPlaceholderVisible(open);
  }

  function onTemplateSelectCallback(selectedTemplate: EmailTemplates) {
    onTemplateSelect && onTemplateSelect(selectedTemplate);
  }

  const PlaceholderButton = (
    <Tooltip align={{ targetOffset: [0, -10] }} title={t.insertTooltip}>
      <StyledInsertButton
        size="small"
        type="text"
        onClick={() => togglePlaceholderVisibility(true)}
      >
        <FontAwesomeIcon icon={['far', 'plus']} color="var(--primary-7)" />
        <GrowText>{t.insert}</GrowText>
      </StyledInsertButton>
    </Tooltip>
  );

  const PlaceholdersGallery = (
    <Insert
      cancel={() => togglePlaceholderVisibility(false)}
      folderType={folderType}
      onInsert={onPlaceholderInsert}
      onTemplateSelect={onTemplateSelectCallback}
      validFolders={validFolders}
      placeHoldersWrapperOverrides={placeHoldersWrapperOverrides}
    />
  );

  const PlaceholderButtonJSX = (
    <Popover
      open={placeholderVisible}
      overlayClassName="ant-popover-body-no-padding-rich insert-placeholder-popover"
      content={PlaceholdersGallery}
      trigger={'click'}
      onOpenChange={togglePlaceholderVisibility}
      overlayInnerStyle={OverlayInnerStyles}
    >
      {PlaceholderButton}
    </Popover>
  );

  return [PlaceholderButtonJSX, placeholderVisible];
}
