import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GrowTypography } from '@sinecycle/growcomponents';
import { Image, message, Spin, Tooltip, Typography } from 'antd';
import { StyledIconButton } from 'components/Common/Styles/Styles';
import { DocPreview, getDocTypeIcon } from 'components/HigherOrderComponent/DocViewer';
import { getAttachmentAnonUrl } from 'queries/Approvals/approval';
import React, { useCallback, useEffect, useState } from 'react';
import { getAttachmentUrl } from 'services/attachments';
import styled from 'styled-components';
import {
  Attachment,
  FileType,
  MimeType,
  UploadedFiles,
  ValidFileType,
} from 'types/common/attachments';
import { bytesToSize } from 'util/file-size';
import { removeFileExtension } from 'util/remove-file-extension';
import { Button } from '../Buttons/Button';
import { CloseButton } from '../Buttons/CloseButton';
import { Flex } from '../Layout/Flex';
import { Texto } from '../Typography/Texto';
import { getFileExtensionFromName, getFileTypeFromExtension } from './utils';

const FileItem = styled(Flex)`
  padding: var(--space-8) var(--space-12);
  border: 1px solid var(--gray-4);
  background-color: var(--gray-2);
  border-radius: var(--br-2);
`;

export const nonDrawerTypes = [FileType.XLSX, FileType.XLS, FileType.CSV];
export const imageFileTypes = [FileType.PNG, FileType.JPG];
interface UploadFileProps<T> {
  file?: T;
  fileName?: string;
  fileSize?: number;
  previewUrl?: string;
  onRemove?: (file?: T) => void;
  onPreview?: Function;
  previewIcon?: React.ReactNode;
  removeIcon?: React.ReactNode;
  showLoading?: Boolean;
}

const imagePreviewStyle = { display: 'none ' };
const DocPreviewCloseButton = (
  <CloseButton
    style={{ position: 'absolute', top: 5, right: 10, height: 25, width: 25 }}
    size="small"
  />
);

const StyledWrapper = styled.div`
  .ant-image {
    display: none;
  }
`;
export function FileUploadPreview<T extends UploadedFiles>(props: UploadFileProps<T>) {
  const { file, fileName, fileSize, onRemove, previewIcon, removeIcon } = props;

  const [previewUrl, setPreviewUrl] = useState('');
  const [showPreview, setShowPreview] = useState(false);

  const fileExtension = getFileExtensionFromName(fileName ?? file?.name);
  const fileType: ValidFileType = fileExtension
    ? getFileTypeFromExtension(fileExtension)
    : FileType.PDF; // default

  useEffect(() => {
    if (!props.previewUrl && !previewUrl && file) {
      setPreviewUrl(URL.createObjectURL(file));
    }
    if (props.previewUrl && !file) {
      setPreviewUrl(props.previewUrl);
    }
    return () => URL.revokeObjectURL(previewUrl);
  }, [file, previewUrl, props.previewUrl]);

  function handlePreview() {
    setShowPreview(true);
    if (props.onPreview) props.onPreview();
  }

  const removeButtonStyles = {
    width: '12px',
    height: '12px',
    display: 'flex',
    marginLeft: 'var(--space-4)',
  };

  const RemoveButton = (
    <Tooltip placement="top" title="Remove" mouseEnterDelay={0.5}>
      <StyledIconButton
        onClick={() => onRemove && onRemove(file)}
        icon={
          removeIcon ? (
            removeIcon
          ) : (
            <FontAwesomeIcon icon={['far', 'times']} color="var(--gray-7)" />
          )
        }
        size="small"
        style={removeButtonStyles}
      />
    </Tooltip>
  );

  const Loader = <Spin size="small" />;

  const FileIcon = previewIcon ? previewIcon : getDocTypeIcon(fileType);
  const FileName = removeFileExtension(fileName ?? file?.name ?? '');
  const FileSize = bytesToSize(fileSize ?? props?.file?.size);
  const isImageDoc = imageFileTypes.includes(fileType);
  const previewCondition = isImageDoc ? false : showPreview;
  const PreviewSection = (
    <DocPreview
      docType={fileType}
      docUrl={props.previewUrl ?? previewUrl}
      onClose={() => setShowPreview(false)}
      open={previewCondition}
      closeIcon={DocPreviewCloseButton}
    />
  );
  const ImagePreviewSection = (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Image
        src={previewUrl}
        preview={{
          visible: isImageDoc && showPreview,
          src: previewUrl,
          onVisibleChange: (value) => {
            setShowPreview(value);
          },
        }}
        style={imagePreviewStyle}
      />
    </div>
  );

  return (
    <StyledWrapper>
      <FileItem justify="space-between" align="center" gap="--space-16">
        <div className="tw-flex tw-items-center tw-gap-8">
          {FileIcon}
          <Typography.Link onClick={handlePreview}>
            <Texto color="var(--purple-7)" truncate="120px">
              {FileName}
            </Texto>
          </Typography.Link>
          <GrowTypography.Text
            style={{ color: 'var(--gray-7)', maxWidth: '120px' }}
            fs="12"
            ellipsis
          >
            {FileSize}
          </GrowTypography.Text>
          {props.showLoading ? Loader : onRemove && RemoveButton}
        </div>
      </FileItem>
      {isImageDoc && ImagePreviewSection}
      {PreviewSection}
    </StyledWrapper>
  );
}

export type PreviewMode = 'BUTTON' | 'UPLOADED_FILE' | 'HIDE';
interface PreviewFileProps {
  fileName: string;
  fileId: number | string;
  fileType?: FileType | string;
  previewIcon?: React.ReactNode;
  mode?: PreviewMode;
  onRemove?: (id: number) => void;
  hidePreview?: Boolean;
  preview?: boolean;
  handleOpenChange?: (open: boolean) => void;
  fileSize?: number;
  previewId?: number | string;
  isAnonymous?: boolean;
  showMessage?: boolean;
  hash?: string;
  version?: string;
}

export function FilePreview(props: PreviewFileProps) {
  const {
    fileName,
    fileId,
    previewIcon,
    fileType: file_type,
    mode = 'UPLOADED_FILE',
    onRemove,
    preview = false,
    handleOpenChange,
    fileSize,
    previewId,
    isAnonymous,
    showMessage,
    hash,
    version,
  } = props;

  const [previewUrl, setPreviewUrl] = useState('');
  const [showPreview, setShowPreview] = useState(false);
  const [loading, setLoading] = useState(false);
  const fileExtension = getFileExtensionFromName(fileName);
  const fileType = fileExtension
    ? getFileTypeFromExtension(fileExtension)
    : file_type
    ? file_type
    : FileType.PDF; // default
  const isImageDoc = fileType === FileType.JPG || fileType === FileType.PNG;
  const FileIcon = previewIcon ? previewIcon : getDocTypeIcon(fileType as FileType);
  const FileName = removeFileExtension(fileName ?? '');
  const FileSize = bytesToSize(fileSize);
  const previewCondition = isImageDoc ? false : showPreview;
  const removeButtonStyles = {
    width: '12px',
    height: '12px',
    display: 'flex',
    marginLeft: 'var(--space-4)',
  };
  async function handlePreview() {
    if (props.hidePreview) return;
    try {
      if (showMessage) {
        message.loading({
          type: 'loading',
          content: 'Action in progress..',
          duration: 2,
        });
      }

      setLoading(true);

      const url = isAnonymous
        ? await getAttachmentAnonUrl(previewId ? previewId : fileId, hash)
        : await getAttachmentUrl(previewId ? previewId : fileId, version);
      setPreviewUrl(url);
      setShowPreview(true);
      setLoading(false);
      if (showMessage) {
        message.destroy();
      }
    } catch (e) {
      setLoading(false);
      if (showMessage) {
        message.error({ type: 'error', content: 'Failed to preview', duration: 2 }).then(() => {});
      }
    }
  }

  const previewCallBack = useCallback(handlePreview, [
    fileId,
    hash,
    isAnonymous,
    previewId,
    props.hidePreview,
    showMessage,
    version,
  ]);

  useEffect(() => {
    if (preview) {
      previewCallBack();
    }
  }, [preview, previewCallBack]);

  const RemoveButton = (
    <Tooltip placement="top" title="Remove" mouseEnterDelay={0.5}>
      <StyledIconButton
        onClick={() => onRemove?.(fileId as number)}
        icon={<FontAwesomeIcon icon={['far', 'times']} color="var(--gray-7)" />}
        size="small"
        style={removeButtonStyles}
      />
    </Tooltip>
  );
  const PreviewSection = (
    <DocPreview
      docType={fileType as ValidFileType}
      docUrl={previewUrl}
      onClose={() => {
        setShowPreview(false);
        handleOpenChange?.(false);
      }}
      open={previewCondition}
    />
  );
  const ImagePreview = (
    <Image
      style={imagePreviewStyle}
      src={previewUrl}
      preview={{
        visible: isImageDoc && showPreview,
        src: previewUrl,
        onVisibleChange: (value) => {
          setShowPreview(value);
          if (!value) {
            handleOpenChange?.(value);
          }
        },
      }}
    />
  );

  const Uploaded = (
    <FileItem justify="space-between" align="center" gap="--space-16">
      <div className="tw-flex tw-gap-8 tw-items-center">
        {FileIcon}
        <Typography.Link onClick={handlePreview}>
          <Texto truncate="120px" color={fileSize ? 'var(--primary-7)' : 'var(--primary-10)'}>
            {FileName}
          </Texto>
        </Typography.Link>
      </div>
      <GrowTypography.Text style={{ color: 'var(--gray-7)', maxWidth: '120px' }} fs="12" ellipsis>
        {fileSize ? FileSize : ''}
      </GrowTypography.Text>
      {onRemove && RemoveButton}
      {loading && <Spin size="small" />}
    </FileItem>
  );
  const PreviewButton = (
    <Button
      buttonProps={{
        icon: previewIcon,
        style: {
          borderColor: 'var(--gray-5)',
        },
        onClick: handlePreview,
        loading,
      }}
      toolTipProps={{
        title: 'Preview',
      }}
    />
  );
  return (
    <StyledWrapper>
      {mode === 'BUTTON'
        ? PreviewButton
        : mode === 'UPLOADED_FILE'
        ? Uploaded
        : mode === 'HIDE'
        ? null
        : Uploaded}
      {isImageDoc && ImagePreview}
      {PreviewSection}
    </StyledWrapper>
  );
}

interface FilePreviewListProps {
  files?: Attachment[];
  onRemove?: (id: number) => void;
}
export function FilePreviewList({ files, onRemove }: FilePreviewListProps) {
  return (
    <>
      {files?.map((file, key) => {
        return (
          <FilePreview
            key={key}
            fileName={file.file_name}
            fileId={file.id}
            fileType={MimeType[file.file_type]}
            onRemove={onRemove}
            version={file?.version_id}
          />
        );
      })}
    </>
  );
}
